import { connect } from 'react-redux';
import { authenticate } from 'store/actions/creators/auth';
import completeUserProfileAction from 'store/actions/creators/completeUserProfile';

const mapStateToProps = (state) => {
	return {
		isAuthenticated: state?.auth?.isAuthenticated,
		userData: state.completeUserProfile.userData,
		loading: state.completeUserProfile.pending,
		error: state.completeUserProfile.error,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		sentCompleteUserProfile: (userData) =>
			dispatch(completeUserProfileAction(userData)),
		auth: (token, expiresAt) => dispatch(authenticate(token, expiresAt)),
		// logOut:(withoutRouting)=>dispatch(logUserOutAction(withoutRouting))
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
