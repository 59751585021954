import TeamMemberModel from 'models/TeamMemberModel';
import {
	GET_ALL_TEAM_MEMBERS,
	GET_TEAM_MEMBER,
} from 'store/actions/types/team-members';

const INIT_STATE = {
	teamMembers: [],
	teamMember: {},
};

export default (state = INIT_STATE, { type, payload }) => {
	switch (type) {
		case GET_ALL_TEAM_MEMBERS: {
			let teamMembers = [];
			const teamMembersPayload = payload;
			teamMembersPayload.forEach((teamMember) => {
				teamMembers = [
					...teamMembers,
					new TeamMemberModel(
						teamMember.id,
						teamMember.name,
						teamMember.email,
						teamMember.phone,
						teamMember.source,
						teamMember.status,
						teamMember.assignTo
					),
				];
			});
			return { ...state, teamMemebers: [...teamMembers] };
		}

		case GET_TEAM_MEMBER: {
			const teamMemberModel = new TeamMemberModel(
				payload.id,
				payload.name,
				payload.avatar
			);
			return {
				...state,
				teamMember: { ...teamMemberModel },
			};
		}

		default:
			return state;
	}
};
