import { authPath } from 'constants/apis';
import toTimestamp from 'helpers/to-timestamp';

import axios from 'helpers/axios';

import navigateUser from 'helpers/navigate-user';

import setAuthToken from 'helpers/set-auth-token';
import {
	SEND_RESET_PASSWORD_INSTRUCTIONS_SUCCESS,
	SEND_RESET_PASSWORD_INSTRUCTIONS_FAIL,
	RESET_PASSWORD,
	GET_CURRENT_USER,
	AUTHENTICATE,
	REGISTER_USER_FAILED,
	REGISTER_USER_SUCCESS,
	LOG_USER_IN_FAILED,
	SAVE_USER_PERMISSION_TOKEN_SUCCESS,
	SAVE_USER_PERMISSION_TOKEN_FAILED,
	EDIT_CURRENT_USER_PROFILE_SUCCESS,
	EDIT_CURRENT_USER_PROFILE_FAIL,
	EDIT_PASSWORD_FAIL,
	EDIT_PASSWORD_SUCCESS,
	DELETE_USER_PERMISSION_TOKEN_SUCCESS,
	DELETE_USER_PERMISSION_TOKEN_FAILED,
	SEND_TENANT_NAME_REMINDER_EMAIL_SUCCESS,
	SEND_TENANT_NAME_REMINDER_EMAIL_FAIL,
	CLEAR_IS_AUTHENTICATED,
	SEND_RESET_PASSWORD_FAIL,
	RESEND_CONFIRMATION_SIGNUP_SUCCESS,
	RESEND_CONFIRMATION_SIGNUP_FAIL,
	SEND_CONFIRM_USER_SUCCESS,
	SEND_CONFIRM_USER_FAIL,
	UPLOAD_AVATAR_FAIL,
	UPLOAD_AVATAR_SUCCESS,
} from 'store/actions/types/auth';

import apiRequest from 'helpers/apiRequest';

let timer;
/**
 *
 * @param token
 * @param expiresAt
 */
const saveDataToStorage = (token, expiresAt) => {
	localStorage.setItem(
		'tokenData',
		JSON.stringify({
			token,
			expiresAt,
		})
	);
};

const clearLogoutTimer = () => {
	if (timer) {
		clearTimeout(timer);
	}
};

export const deleteUserPermissionTokenAction = (tokenConfig) => {
	return async (dispatch) => {
		try {
			await axios.delete('/api/tokens', { params: tokenConfig });
			dispatch({ type: DELETE_USER_PERMISSION_TOKEN_SUCCESS });
		} catch (e) {
			dispatch({ type: DELETE_USER_PERMISSION_TOKEN_FAILED });
		}
	};
};

/**
 *
 * @returns {{type: string}}
 */
export const logUserOutAction = () => {
	localStorage.removeItem('tokenData');
	localStorage.removeItem('firebase_token');
	localStorage.removeItem('persist:root');
	clearLogoutTimer();
	window.location.assign('/login');
};

export const logUserOutWithoutRouteAction = () => {
	localStorage.removeItem('tokenData');
	localStorage.removeItem('persist:root');
	clearLogoutTimer();
};

/**
 *
 * @param expirationTime
 * @returns {(function(*): void)|*}
 */
// const setLogoutTimer = (expirationTime) => {
// 	return (dispatch) => {
// 		const expiresIn = new Date(expirationTime) - new Date();
// 		timer = setTimeout(() => {
// 			dispatch(logUserOutAction());
// 		}, expiresIn);
// 	};
// };
const setLogoutTimer = (expirationTime) => {
	return (dispatch) => {
		const expiresIn =
			new Date(expirationTime) - new Date() > 0
				? new Date(expirationTime) - new Date()
				: 0;

		timer = setTimeout(() => {
			dispatch(logUserOutAction());
		}, expiresIn);
	};
};

/**
 *
 * @returns {(function(*): Promise<void>)|*}
 */

export const getCurrentUser = () => {
	return async (dispatch) => {
		try {
			const response = await axios.get(`${authPath}`);
			const responseData = response.data;
			const { success, data } = responseData;
			if (success) {
				const { attributes } = data;
				dispatch({
					type: GET_CURRENT_USER,
					payload: { ...attributes },
				});
			}
		} catch (e) {
			// handle error
		}
	};
};

/**
 *
 * @param token
 * @param expiresAt
 * @returns {(function(*): Promise<void>)|*}
 */
export const authenticate = (token, expiresAt) => {
	return async (dispatch) => {
		setAuthToken(token);
		const expiryTime = toTimestamp(expiresAt);
		saveDataToStorage(token, expiryTime);
		dispatch(setLogoutTimer(expiryTime));
		dispatch({ type: AUTHENTICATE });
		await dispatch(getCurrentUser());
	};
};
export const clearAuthenticateAction = () => {
	return (dispatch) => {
		dispatch({ type: CLEAR_IS_AUTHENTICATED });
	};
};

/**
 *
 * @param userData
 * @param history
 * @returns {(function(*, *): Promise<void>)|*}
 */

export const logUserInAction = (userData, history, setUiLoadingError) => {
	return async (dispatch, getState) => {
		const onSuccess = async (responseData) => {
			const { token } = responseData.data;
			const { expires_at: expiresAt } = responseData.data;
			await dispatch(authenticate(token, expiresAt));
			const { auth } = getState();
			const { user } = auth;
			history.push('/talkybots');
			navigateUser(user, history, window.location);
		};
		const onError = (e) => {
			dispatch({
				type: LOG_USER_IN_FAILED,
			});
		};

		apiRequest.post({
			url: `${authPath}/sign_in`,
			bodyData: userData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param confirmationToken
 * @param history
 * @returns {(function(*): Promise<void>)|*}
 */

export const confirmUserAction = (confirmationToken, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: SEND_CONFIRM_USER_SUCCESS,
			});
		};
		const onError = (e) => {
			dispatch({
				type: SEND_CONFIRM_USER_FAIL,
			});
		};

		apiRequest.get({
			url: `${authPath}/confirmation?confirmation_token=${confirmationToken}`,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param payload
 * @returns {(function(*): Promise<void>)|*}
 */
export const sendResetPasswordInstructionsAction = (
	data,
	history,
	setUiLoadingError
) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: SEND_RESET_PASSWORD_INSTRUCTIONS_SUCCESS });
		};
		const onError = (e) => {
			dispatch({
				type: SEND_RESET_PASSWORD_INSTRUCTIONS_FAIL,
			});
		};

		apiRequest.post({
			url: `${authPath}/password`,
			bodyData: data,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param payload
 * @param history
 * @returns {(function(*): Promise<void>)|*}
 */

export const resetPasswordAction = (data, history, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: RESET_PASSWORD });
			history.push('/login');
		};
		const onError = (e) => {
			dispatch({
				type: SEND_RESET_PASSWORD_FAIL,
			});
		};

		apiRequest.put({
			url: `${authPath}/password`,
			bodyData: data,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};
/**
 *
 * @param payload
 * @returns {(function(*): Promise<void>)|*}
 */

export const sendTenantNameReminderEmailAction = (data, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: SEND_TENANT_NAME_REMINDER_EMAIL_SUCCESS });
		};
		const onError = (e) => {
			dispatch({
				type: SEND_TENANT_NAME_REMINDER_EMAIL_FAIL,
			});
		};

		apiRequest.post({
			url: `/api/reminders`,
			bodyData: data,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param userData
 * @param history
 * @returns {(function(*): Promise<void>)|*}
 */

export const registerUserAction = (userData, history, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: REGISTER_USER_SUCCESS,
			});
			// const { token } = responseData.data;
			// const { expires_at: expiresAt } = responseData.data;
			// await dispatch(authenticate(token, expiresAt));
			history.push(`/confirmation-signup/${responseData?.data?.id}`);
		};
		const onError = (e) => {
			dispatch({
				type: REGISTER_USER_FAILED,
			});
		};
		apiRequest.post({
			url: `${authPath}`,
			bodyData: userData,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

/**
 *
 * @param userId
 * @returns {(function(*): Promise<void>)|*}
 */

export const resendConfirmSignUpAction = (userId, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({ type: RESEND_CONFIRMATION_SIGNUP_SUCCESS });
		};
		const onError = (e) => {
			dispatch({
				type: RESEND_CONFIRMATION_SIGNUP_FAIL,
			});
		};
		apiRequest.post({
			url: `${authPath}/confirmation?id=${userId}`,
			bodyData: '',
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

export const saveUserPermissionTokenAction = (tokenConfig) => {
	return async (dispatch) => {
		try {
			await axios.post(`/api/tokens`, tokenConfig);
			dispatch({ type: SAVE_USER_PERMISSION_TOKEN_SUCCESS });
		} catch (e) {
			dispatch({ type: SAVE_USER_PERMISSION_TOKEN_FAILED });
		}
	};
};

export const editUserProfile = (name, setUiLoadingError) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: EDIT_CURRENT_USER_PROFILE_SUCCESS,
				payload: responseData.data.attributes,
			});
		};
		const onError = (e) => {
			dispatch({
				type: EDIT_CURRENT_USER_PROFILE_FAIL,
			});
		};
		apiRequest.put({
			url: `${authPath}/update_profile`,
			bodyData: { name },
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

export const editPassword = (data, setUiLoadingError) => {
	const Data = {
		old_password: data.oldpassword,
		new_password: data.newpassword,
		password_confirmation: data.confirmpassword,
	};

	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: EDIT_PASSWORD_SUCCESS,
				payload: responseData.data.attributes,
			});
		};
		const onError = (e) => {
			dispatch({
				type: EDIT_PASSWORD_FAIL,
			});
		};
		apiRequest.put({
			url: `${authPath}/update_password`,
			bodyData: Data,
			onSuccess,
			onError,
			setUiLoadingError,
		});
	};
};

export const editUserProfileImage = (formData, setUiLoadingError, config) => {
	return async (dispatch) => {
		const onSuccess = async (responseData) => {
			dispatch({
				type: UPLOAD_AVATAR_SUCCESS,
				payload: responseData.data.attributes,
			});
		};
		const onError = (e) => {
			dispatch({
				type: UPLOAD_AVATAR_FAIL,
			});
		};

		apiRequest.put({
			url: `${authPath}/update_profile_image`,
			bodyData: formData,
			onSuccess,
			onError,
			setUiLoadingError,
			config,
		});
	};
};
