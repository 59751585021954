import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CustomSpinner from './components/common/CustomSpinner/Component';
import UserAuth from './hoc/UserAuth';
import TalkybotProviders from './pages/TalkybotProviders';
import PrivateRoute from './components/common/PrivateRoute';
import LandingPage_Page from './pages/LandingPage_Page';
import NotFoundPage from './components/404/Component';

const MainLayout = lazy(() => import('./layouts/MainLayout'));
const Login = lazy(() => import('./pages/Login'));
const ForgetPasswordPage = lazy(() => import('./pages/ForgetPasswordPage'));
const ResetPasswordPage = lazy(() => import('./pages/ResetPasswordPage'));
const TenantNameReminderPage = lazy(() =>
	import('./pages/TenantNameReminderPage')
);
const Register = lazy(() => import('./pages/Register'));
const PathBuilder = lazy(() => import('./pages/PathBuilder'));
const BotBuilderTopicFlows = lazy(() => import('./pages/BotBuilder'));
const Talkybots = lazy(() => import('./pages/Talkybots'));
const ConfirmationSignupPage = lazy(() => import('./pages/ConfirmationSignup'));
const ConfirmationResetPasswordPage = lazy(() =>
	import('./pages/ConfirmationResetPassword')
);
const Crm = lazy(() => import('./pages/Crm'));
const Store = lazy(() => import('./pages/Store'));
const PreviewApp = lazy(() => import('./pages/PreviewApp'));
const PersonProfilePage = lazy(() => import('./pages/PersonProfilePage'));
const Conversations = lazy(() => import('./pages/Conversations'));
const MembersPage = lazy(() => import('./pages/Members'));
const InviteMemberPage = lazy(() => import('./pages/InviteMember'));
const FAQs = lazy(() => import('./pages/FAQs'));
const FacebookRedirectionHandlerPage = lazy(() =>
	import('./pages/FacebookRedirectionHandlePage')
);
const CreateFaqs = lazy(() => import('./pages/CreateFaqs'));
const SingleMemberPage = lazy(() => import('./pages/SingleMember'));
const NotificationsPage = lazy(() => import('./pages/NotificationsPage'));
const BotGatesPage = lazy(() => import('./pages/BotGates'));

const BotBuilderStopWords = lazy(() => import('./pages/BotBuilderStopWords'));

// settings page
const SettingsPage = lazy(() => import('./pages/Settings'));
const CrmSettingsPage = lazy(() => import('./pages/CrmSettings'));
const ConversationsSettingsPage = lazy(() =>
	import('./pages/ConversationsSettings')
);

// payment

const PaymentSuccess = lazy(() => import('./pages/PaymentSuccess'));

// dashboard
import Dash from './pages/dashboard';
const UserProfilePage = lazy(() => import('./pages/UserProfilePage'));
import CompleteUserProfilePage from './pages/CompleteUserProfile';
import UsagePage from './pages/UsagePage';

const Routes = () => {
	return (
		<Suspense
			fallback={
				<div className="LOADING_SCREEN_GLOBAL">
					<CustomSpinner size="large" />
				</div>
			}
		>
			<Switch>
				<Route path="/home" component={LandingPage_Page} />

				<Route
					exact
					path="/"
					component={() => <Redirect push to="/home" />}
				/>
				<Route
					exact
					path="/completeUserProfile"
					component={() => <CompleteUserProfilePage />}
				/>
				{/* confirmation when redirect from confirmation email  */}
				<Route
					exact
					path="/login/:confirmation_token?"
					component={Login}
				/>
				<Route
					exact
					path="/facebook-redirection-handler"
					component={({ ...props }) => (
						<FacebookRedirectionHandlerPage {...props} />
					)}
				/>
				<Route
					exact
					path="/forget-password"
					component={ForgetPasswordPage}
				/>
				<Route
					exact
					path="/reset-password"
					component={ResetPasswordPage}
				/>
				<Route
					exact
					path="/tenant-name-reminder"
					component={TenantNameReminderPage}
				/>
				<Route exact path="/register" component={Register} />
				<PrivateRoute
					exact
					path="/talkybots"
					component={() => (
						<MainLayout>
							<Talkybots />
						</MainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/notifications"
					component={() => (
						<MainLayout>
							<NotificationsPage />
						</MainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/usage"
					component={() => (
						<MainLayout>
							<UsagePage />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/dashboard"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot>
							<Dash {...props} />
						</MainLayout>
					)}
				/>

				<Route
					exact
					path="/talkybots/:talkybotId/conversations/:personId?"
					component={UserAuth(Conversations, false)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/crm/:person_id"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot>
							<PersonProfilePage {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/crm"
					component={() => (
						<MainLayout isCollapsed withTalkybot>
							<Crm />
						</MainLayout>
					)}
				/>
				<Route
					exact
					path="/talkybots/:talkybotId/store"
					component={UserAuth(Store, false)}
				/>
				<Route
					exact
					path="/talkybots/:talkybotId/store/:appName/preview"
					component={UserAuth(PreviewApp, false)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder"
					component={() => (
						<MainLayout isCollapsed withTalkybot>
							<BotBuilderTopicFlows />
						</MainLayout>
					)}
				/>

				<Route
					exact
					path="/talkybots/:talkybotId/bot-builder/stop-words"
					component={() => (
						<MainLayout isCollapsed withTalkybot>
							<BotBuilderStopWords />
						</MainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder/topic-flows/:flowId"
					component={() => (
						<MainLayout withTalkybot isCollapsed>
							<PathBuilder />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder/faqs"
					component={() => (
						<MainLayout withTalkybot>
							<FAQs />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/connect"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot={false}>
							<TalkybotProviders />
						</MainLayout>
					)}
				/>

				<Route
					path="/confirmation-signup/:userID"
					component={(props) => <ConfirmationSignupPage {...props} />}
				/>
				<Route
					path="/confirmation-reset-password"
					component={(props) => (
						<ConfirmationResetPasswordPage {...props} />
					)}
				/>

				<PrivateRoute
					exact
					path="/team-members"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot={false}>
							<MembersPage {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/team-members/invite"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot={false}>
							<InviteMemberPage {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/team-members/:userId"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot={false}>
							<SingleMemberPage {...props} />
						</MainLayout>
					)}
				/>

				<Route path="/payment-success" component={PaymentSuccess} />

				<Route
					exact
					path="/users/:id/:tab?"
					component={(props) => (
						<MainLayout isCollapsed>
							<UserProfilePage {...props} />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/bot-builder/:intentId/faqs/create"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot>
							<CreateFaqs />
						</MainLayout>
					)}
				/>

				{/* settings routes  */}

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/settings"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot>
							<SettingsPage />
						</MainLayout>
					)}
				/>

				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/settings/crm"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot>
							<CrmSettingsPage />
						</MainLayout>
					)}
				/>
				<PrivateRoute
					exact
					path="/talkybots/:talkybotId/settings/conversations"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot>
							<ConversationsSettingsPage />
						</MainLayout>
					)}
				/>
				{/* bot gates */}
				<Route
					path="/talkybots/:talkybotId/bot-gates"
					component={(props) => (
						<MainLayout isCollapsed withTalkybot>
							<BotGatesPage {...props} />
						</MainLayout>
					)}
				/>
				<Route
					path="/noInternetConnection/:previousPath?"
					render={() => (
						<MainLayout withTalkybot={false} isCollapsed>
							<NoInternetConnection />
						</MainLayout>
					)}
				/>
				<Route
					path="*"
					component={() => (
						<MainLayout withTalkybot={false} isCollapsed>
							<NotFoundPage />
						</MainLayout>
					)}
				/>
			</Switch>
		</Suspense>
	);
};

export default Routes;
