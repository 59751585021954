import * as actionType from 'store/actions/types/usage';

const initialState = {
	loading: false,
	currentUsage: null,
	error: null,
};
export default (state = initialState, { type, payload }) => {
	switch (type) {
		case actionType.GET_CURRENT_USAGE_REQUEST: {
			return { ...state, loading: true, error: null };
		}
		case actionType.GET_CURRENT_USAGE_SUCCESS: {
			return {
				...state,
				loading: false,
				error: null,
				currentUsage: payload,
			};
		}
		case actionType.GET_CURRENT_USAGE_FAIL: {
			return { ...state, loading: false, error: payload };
		}
		default:
			return state;
	}
};
