import { connect } from 'react-redux';

import { activateTalkybotAction } from 'store/actions/creators/talkybots';
import {
	getAllCategoriesAction,
	resetActiveIntentAction,
	resetFaqsStateAction,
} from 'store/actions/creators/faqs';

const mapStateToProps = (state) => {
	return {
		talkybots: state.talkybots.bots
			? Object.values(state.talkybots.bots)
			: [],
		activeTalkybot: state?.activeTalkybot,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		activateTalkybot: (botData) =>
			dispatch(activateTalkybotAction(botData)),
		getAllCategories: ({ botBuilderId }) =>
			dispatch(getAllCategoriesAction({ botBuilderAppId: botBuilderId })),
		resetFaqsState: () => dispatch(resetFaqsStateAction()),
		resetActiveIntent: () => dispatch(resetActiveIntentAction()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
