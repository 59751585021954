import { Container, Grid, Typography, Paper, Hidden } from '@material-ui/core';
import benefitesCreateBotImg from 'assets/images/landingImgs/benefitsCreateBot.png';
import benefitesCreateBotImg2x from 'assets/images/landingImgs/benefitsCreateBot@2x.png';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		marginTop: '6.25rem',
		textAlign: 'center',
	},
	headerSm: {
		...theme.typography.subtitle5,
		color: theme.palette.text.primary,
	},
	headerLg: {
		...theme.typography.subtitle4,
		color: theme.palette.text.primary,
	},
	headerDesc: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
	},
	sectionImg: {
		// backgroundImage: "url(" + benefitesCreateBotImg + ")",
		// backgroundSize: "68% 68%",
		// backgroundRepeat: "no-repeat",
		// backgroundPosition: "center 55%",
		// width: "100%",
		// [theme.breakpoints.down('sm')]:{
		//   backgroundSize: "100% 60%",
		//   backgroundPosition: "center 25%",
		// },
		// padding:"0px 50px"
	},
	contentHeader: {
		position: 'relative',
		margin: 'auto',
		textAlign: 'center',
		[theme.breakpoints.down('sm')]: {
			padding: '0px 3.3125rem',
			width: '70%',
		},
		width: '80%',
	},
	sectionContent: {
		[theme.breakpoints.down('sm')]: {
			marginTop: '7.5rem',
		},
	},
	contentToInner: {
		// width: "50%",
		margin: 'auto',
		textAlign: 'center',
	},
	subHeaderFrame: {
		margin: 'auto',
		textAlign: 'center',
	},
	subHeader: {
		textTransform: 'capitalize',
		textAlign: 'center',
		...theme.typography.subtitle4,
		color: theme.palette.text.primary,
	},
	subHeaderDesc: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
		[theme.breakpoints.up('md')]: {
			margin: '0px 0px 6.25rem 0px',
		},
	},
	devider: {
		[theme.breakpoints.up('md')]: {
			margin: '0px 0px 4.375rem 0px',
		},
		// margin:"0px 0px 2000px 0px",
	},
	benefitesCreateBotImg: {
		[theme.breakpoints.down('sm')]: {
			width: '105%',
			top: '50%',
			left: '-8px',
		},
		width: '68%',
		//  margin:"10% 50% 0px 8%",
		position: 'absolute',
		top: '30%',
		left: '17%',
		backgroundColor: 'transparent',
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));
const BenefitesCreateBot = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0} className={classes.sectionStyle}>
			<Container className={classes.containerStyle} maxWidth={false}>
				<Paper
					elevation={0}
					align="center"
					className={classes.sectionImg}
				>
					<Paper elevation={0} className={classes.contentHeader}>
						<Typography className={classes.headerSm}>
							Benefits
						</Typography>
						<Typography className={classes.headerLg}>
							Create Bot builder <br /> Without Coding
						</Typography>
						<Typography className={classes.headerDesc}>
							Building bots can be intimidating with
							<br /> code, but using a no-code bot builder
							<br /> can make it look so easy and simple <br />
							for anyone.
						</Typography>
						<Paper
							elevation={0}
							className={classes.benefitesCreateBotImg}
						>
							<img
								width="100%"
								height="100%"
								src={benefitesCreateBotImg}
								alt=""
							/>
						</Paper>
					</Paper>

					<Paper
						elevation={0}
						className={classes.sectionContent}
						evalu
					>
						<Grid
							container
							spacing={1}
							alignItems="center"
							justify="space-between"
						>
							<Grid item xs={6} sm={3} md={3}>
								<Paper
									elevation={0}
									className={classes.subHeaderFrame}
								>
									<Typography className={classes.subHeader}>
										Curated Templates
									</Typography>
									<Typography
										className={classes.subHeaderDesc}
									>
										Get inspiration using our curated
										templates to jump-start your bot
										building.
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6} sm={3} md={3}>
								<Paper
									elevation={0}
									className={classes.subHeaderFrame}
								>
									<Typography className={classes.subHeader}>
										Connect
									</Typography>
									<Typography
										className={classes.subHeaderDesc}
									>
										Connect your chatbot to different
										channels. Integrate with various tools.
										Test it live, duplicate it, or share it.
									</Typography>
								</Paper>
							</Grid>

							<Grid
								className={classes.devider}
								item
								xs={12}
							></Grid>
							<Hidden mdDown>
								<Grid item md={1}></Grid>
							</Hidden>
							<Grid item xs={6} sm={5} md={3}>
								<Paper
									elevation={0}
									className={classes.contentToInner}
								>
									<Typography className={classes.subHeader}>
										Utility Tools
									</Typography>
									<Typography
										className={classes.subHeaderDesc}
									>
										Make use of tools like version control,
										undo-redo, auto layout to make you more
										productive.
									</Typography>
								</Paper>
							</Grid>
							<Grid item xs={6} sm={5} md={3}>
								<Paper
									elevation={0}
									className={classes.contentToInner}
								>
									<Typography className={classes.subHeader}>
										Manage your bots
									</Typography>
									<Typography
										className={classes.subHeaderDesc}
									>
										As you grow you'll have more and more
										bots to manage. Manage them easily with
										our interface.
									</Typography>
								</Paper>
							</Grid>
							<Grid xs={12} item md={1}></Grid>
						</Grid>
					</Paper>
				</Paper>
			</Container>
		</Paper>
	);
};

export default BenefitesCreateBot;
