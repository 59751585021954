import React, { useState, useEffect } from 'react';
import {
	makeStyles,
	Container,
	useTheme,
	Typography,
	Avatar,
	Tooltip,
} from '@material-ui/core';

import { getAllBotsAction } from 'store/actions/creators/talkybots';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import getCurrentUsageAction from 'store/actions/creators/usage';

import Grid from '@material-ui/core/Grid';
import { Link, useParams, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, connect } from 'react-redux';

import { Offline, Online, Detector } from 'react-detect-offline';
import NoInternetConnection from 'components/NoInternetConnection/Component';
import { ReactComponent as UsersIcon } from 'assets/icons/team-member-icon11.svg';
import { ReactComponent as LogOutIcon } from 'assets/icons/logoutIcon.svg';
import NotificationIcon from 'assets/icons/notificationIcon.svg';
import NotificationIconActive from 'assets/icons/notificationIconActive.svg';
import TalkybotLogo from 'assets/images/Mask Group 1@2x.png';
import GridContainer from 'components/common/Grid/GridContainer';
import SideNav from 'components/common/SideNav/Component';
import BotNamePanel from 'components/common/BotnamePanel';
import 'components/common/SideNav/Component.scss';
import CustomButton from 'components/common/CustomButton/Component';

import PiechartActive from 'assets/icons/pie-chartActive.svg';
import PiechartNotActive from 'assets/icons/pie-chartNotActive.svg';
import { ReactComponent as RobotIcon } from 'assets/icons/botIcon.svg';

import GridItem from 'components/common/Grid/GridItem';
import CustomAvatar from 'components/common/CustomAvatar/Component';
import AudioSound from 'assets/audio/notification.mp3';
import {
	deleteUserPermissionTokenAction,
	logUserOutAction,
} from 'store/actions/creators/auth';
import { getToken, onMessageListener } from 'config/firebase';
import { CAN_PUBLISH_BOT, CAN_VIEW_USAGE } from 'constants/permissions';
import userHasPermission from 'helpers/user-has-permission';
import { getContainerSize } from 'config/theme';
import { DEVICE_NOTIFICATION_PERMISSION } from 'constants/tokensTypes';
import UserHasPermission from 'hoc/UserHasPermission';
import CircularProgressWithLabel from 'components/common/CircularProgressWithLabel/CircularProgressWithLabel';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
	fixedColumn: {
		width: '4.875rem',
		height: '100vh',
		backgroundColor: '#F4F7F9',
		borderRight: '1px solid #cdcdcd',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		zIndex: 1,
		position: 'fixed',
	},
	toggleColumn: {},
	fixedLogo: {
		height: theme.spacing(78),
		width: theme.spacing(63),
		marginTop: '1.125rem',
	},
	root: {
		backgroundColor: '#F4F7F9',
		display: 'flex',
		minHeight: '100vh',
	},
	item: {
		minHeight: '100vh',
	},
	item2: { minHeight: '150vh' },
	notificationIcon: {
		background: '#8F2483',
		position: 'absolute',
		height: '1rem',
		width: '1rem',
		top: '0rem',
		right: '.1rem',
		borderRadius: '50%',
	},
	usageIcon: {
		width: theme.spacing(32),
		height: theme.spacing(29),
	},
	logoutContainer: {
		marginTop: theme.spacing(35),
		marginBottom: theme.spacing(35),
	},
	logoutText: {
		...theme.typography.buttonLink,
		color: theme.palette.secondary.main,
	},
	tooltip: {
		...theme.typography.subtitle6,
		padding: '.5rem 1rem',
		backgroundColor: theme.palette.primary.light,
	},
	tooltipArrow: {
		// fontSize: '1rem',
		color: theme.palette.primary.light,
	},
	tooltipNotActive: {
		...theme.typography.subtitle6,
		padding: '.5rem 1rem',
		// backgroundColor: theme.palette.primary.light,
	},
	tooltipArrowNotActive: {
		// fontSize: '1rem',
		// color: theme.palette.primary.light,
	},
}));

const MainLayout = ({
	children,
	withTalkybot,
	withoutBotPanel,
	tenantId,
	getCurrentUsage,
	getAllBots,
	talkybots,
	meterInfo,
	isCollapsed = true,
}) => {
	const teamMemberPath = '/team-members';
	const talkybotsPath = '/talkybots';
	const landingPagePath = '/home';

	const notificationsPath = '/notifications';
	const usagePath = '/usage';
	const theme = useTheme();
	// [TODO:] iscollape menu : make it dynamic from route file
	const [collapseMenu, setCollapseMenu] = useState(false);
	const handleCollapse = () => {
		setCollapseMenu((prev) => !prev);
	};
	const classes = useStyles();
	const { talkybotId } = useParams();
	const location = useLocation();
	const user = useSelector((state) => state.auth.user);
	const activeTalkybot = useSelector((state) => state.activeTalkybot);
	const userData = user?.user_profile;

	// notification
	const audio = new Audio(AudioSound);
	const dispatch = useDispatch();

	useEffect(() => {
		getToken(dispatch);
	}, []);

	const [notification, setNotification] = useState({
		new: false,
		data: null,
	});
	const { enqueueSnackbar } = useSnackbar();

	const NotificationCard = ({ bot, person, sender }) => {
		return (
			<Grid container alignItems="center">
				<Avatar src={sender?.image} />
				<Typography style={{ marginLeft: '1rem' }}>
					{' '}
					New Notification From {sender?.name}
				</Typography>
			</Grid>
		);
	};
	onMessageListener().then((payload) => {
		audio.play();
		enqueueSnackbar(
			<NotificationCard
				bot={JSON.parse(payload?.data?.bot)}
				person={JSON.parse(payload?.data?.person)}
				sender={JSON.parse(payload?.data?.sender)}
			/>,
			{
				variant: 'info',
				autoHideDuration: 4000,
				anchorOrigin: {
					vertical: 'top',
					horizontal: 'left',
				},
			}
		);
		setNotification({
			new: true,
			data: payload,
		});
	});
	const clearNotification = () => {
		setNotification({ ...notification, new: false });
	};

	const [isLoading, successData, backendError, setUiLoadingError] =
		useSetUiLoadingError();

	useEffect(() => {
		getCurrentUsage(tenantId);
	}, []);

	const MAU = meterInfo?.find(
		(meter) => meter.nameOfMeter === 'Re-Engagements'
	);

	console.log(MAU, 'MAU from main layout');

	const normalise = (value) => (value * 100) / MAU?.totalNumOfMeter;

	const usagePercentage = normalise(MAU?.currentNumOfMeter);

	console.log(usagePercentage, 'usage percentage from main layout');

	const logoutWithDestroyFirebaseToken = () => {
		const currentToken = localStorage.getItem('firebase_token');
		const tokenConfig = {
			token_type: DEVICE_NOTIFICATION_PERMISSION,
			token_value: currentToken,
		};
		dispatch(deleteUserPermissionTokenAction(tokenConfig));
		dispatch(logUserOutAction);
	};

	return (
		<GridContainer className={`${classes.root} main_layout`}>
			<GridItem className={classes.fixedColumn}>
				<GridItem
					xs={10}
					style={{
						textAlign: 'center',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<Link to={landingPagePath}>
						<img
							className={classes.fixedLogo}
							src={TalkybotLogo}
							alt="Talkybot Logo"
						/>
					</Link>
					<Link to={talkybotsPath}>
						<Tooltip
							classes={{
								tooltip: location.pathname.includes(
									'/talkybots'
								)
									? classes.tooltip
									: classes.tooltipNotActive,
								arrow: location.pathname.includes('/talkybots')
									? classes.tooltipArrow
									: classes.tooltipArrowNotActive,
							}}
							title="My Bots"
							placement="right"
							arrow
						>
							<RobotIcon
								fill={
									location.pathname.includes('/talkybots')
										? '#6418C3'
										: '#909090'
								}
								width={theme.spacing(30)}
								height={theme.spacing(27)}
								style={{
									marginTop: '2rem',
								}}
							/>
						</Tooltip>
					</Link>

					<Link to={teamMemberPath}>
						<Tooltip
							classes={{
								tooltip: location.pathname.includes(
									'/team-members'
								)
									? classes.tooltip
									: classes.tooltipNotActive,
								arrow: location.pathname.includes(
									'/team-members'
								)
									? classes.tooltipArrow
									: classes.tooltipArrowNotActive,
							}}
							title="Team Members"
							placement="right"
							arrow
						>
							<UsersIcon
								fill={
									location.pathname.includes('/team-members')
										? '#6418C3'
										: '#909090'
								}
								width={theme.spacing(30)}
								height={theme.spacing(27)}
								style={{ marginTop: '2rem' }}
							/>
						</Tooltip>
					</Link>
					<UserHasPermission action={CAN_VIEW_USAGE}>
						<Link className={classes.usageIcon} to={usagePath}>
							<Tooltip
								classes={{
									tooltip:
										location.pathname === usagePath
											? classes.tooltip
											: classes.tooltipNotActive,
									arrow:
										location.pathname === usagePath
											? classes.tooltipArrow
											: classes.tooltipArrowNotActive,
								}}
								title="Usage"
								placement="right"
								arrow
							>
								{usagePercentage > 5 ? (
									<CircularProgressWithLabel
										minExpectedValue={0}
										maxExpectedValue={MAU?.totalNumOfMeter}
										valueOfProgress={MAU?.currentNumOfMeter}
										size="1.8rem"
										thickness={4}
										color={
											usagePercentage > 75
												? 'red'
												: 'green'
										}
										mainLayoutStyle
										usagePercentage={usagePercentage}
									/>
								) : (
									<CircularProgressWithLabel
										minExpectedValue={0}
										maxExpectedValue={0}
										valueOfProgress={100}
										size="1.8rem"
										thickness={4}
										color="grey"
										mainLayoutStyle
										usagePercentage={usagePercentage}
									/>
								)}

								{/* <img
									width="100%"
									height="100%"
									style={{ marginTop: '2rem' }}
									src={
										location.pathname === usagePath
											? PiechartActive
											: PiechartNotActive
									}
									alt="usage"
								/> */}
							</Tooltip>
						</Link>
					</UserHasPermission>
				</GridItem>
				<GridItem
					xs={2}
					style={{
						display: 'flex',
						textAlign: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					{/* <SearchIcon fill="#909090" />
					<EarthIcon fill="#909090" style={{ marginTop: '1.5rem' }} />
					<CalendarIcon
						fill="#909090"
						style={{ marginTop: '1.5rem' }}
					/> */}

					<Link
						to={notificationsPath}
						style={{ position: 'relative' }}
						onClick={clearNotification}
					>
						<Tooltip
							title="Notification"
							placement="right"
							arrow
							disableHoverListener={!collapseMenu}
							classes={{
								tooltip:
									location.pathname === notificationsPath
										? classes.tooltip
										: classes.tooltipNotActive,
								arrow:
									location.pathname === notificationsPath
										? classes.tooltipArrow
										: classes.tooltipArrowNotActive,
							}}
						>
							<img
								width={theme.spacing(450)}
								src={
									notification.new
										? NotificationIcon
										: NotificationIconActive
									// location.pathname !== notificationsPath
									// 	? NotificationIcon
									// 	: NotificationIconActive
								}
								alt="notification"
							/>
						</Tooltip>
					</Link>

					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							// marginTop: '3rem',
							textAlign: 'center',
						}}
					>
						<Link to={`/users/${userData?.id}`}>
							<div style={{ marginTop: '.5rem' }}>
								{user?.attachment_url ? (
									<Avatar
										alt="user profile"
										src={user?.attachment_url}
										// className={classes.large}
									/>
								) : (
									<CustomAvatar name={userData?.name} />
								)}
							</div>
						</Link>

						{/* <InformationIcon
							fill="#909090"
							style={{ marginTop: '1.5rem' }}
						/> */}
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginTop: '1.5rem',
								marginBottom: '0.5rem',
							}}
						>
							{/* <span style={{ fontFamily: 'Cairo-Bold' }}>
								Dark
							</span>
							<CustomSwitch style={{ margin: '0' }} /> */}
						</div>
						<div
							style={{
								borderBottom: '1px solid #ddd',
								width: '6.875rem',
							}}
						/>
						<GridItem className={classes.logoutContainer}>
							<LogOutIcon
								fill="#909090"
								width={theme.spacing(20)}
								height={theme.spacing(23)}
								title="logout"
								onClick={logoutWithDestroyFirebaseToken}
							/>
							<Typography className={classes.logoutText}>
								Logout
							</Typography>
						</GridItem>
					</div>
				</GridItem>
			</GridItem>
			{withTalkybot && (
				<GridItem
					style={{
						position: 'fixed',
						left: '6.875rem',
						width: collapseMenu ? '6.25rem' : '16.375rem',
					}}
				>
					<div
						style={{
							left: '6.875rem',
							width: collapseMenu ? '6.25rem' : '16.375rem',
							position: 'fixed',
							zIndex: 1,
						}}
					>
						<SideNav
							handleCollapse={handleCollapse}
							collapseMenu={collapseMenu}
							width={collapseMenu ? '0.25rem' : '16.375rem'}
							style={{
								left: '4.875rem',
								width: collapseMenu ? '5.5rem' : '16.375rem',
								position: 'fixed',
								zIndex: 1,
							}}
						/>
					</div>
				</GridItem>
			)}
			<GridItem
				style={{
					marginLeft: withTalkybot
						? !collapseMenu
							? '21.5rem'
							: // 130 /** fixed */ + 300
							  '11.5rem' /** fixed collapse menu , column */
						: '7.5rem',
					// overflow: 'hidden',
					// height: '100vh',

					// minHeight: '100vh',
					// width: withTalkybot
					// 	? !collapseMenu
					// 		? window.innerWidth - (100 + 350)
					// 		: window.innerWidth - 250
					// 	: window.innerWidth - 120,
					// window.innerWidth - 320
					// 		: window.innerWidth - 160
					width: withTalkybot
						? !collapseMenu
							? window.innerWidth > 1500
								? window.innerWidth - 400
								: window.innerWidth - 300
							: window.innerWidth > 1500
							? window.innerWidth - 230
							: window.innerWidth - 173
						: window.innerWidth - 142,
				}}
			>
				<Container maxWidth={getContainerSize()}>
					{withTalkybot ? (
						<div>{children}</div>
					) : (
						<>
							<Online polling={false}>{children}</Online>
							<Offline polling={false}>
								<NoInternetConnection />
							</Offline>
						</>
					)}
				</Container>
			</GridItem>
		</GridContainer>
	);
};

const mapStateToProps = (state) => ({
	tenantId: state?.auth?.user?.tenant.id,
	talkybots: state.talkybots.bots ? Object.values(state.talkybots.bots) : [],
	meterInfo: state.usage?.currentUsage?.meterInfo,
});

const mapDispatchToProps = (dispatch) => ({
	getAllBots: (setUiLoadingError) =>
		dispatch(getAllBotsAction(setUiLoadingError)),
	getCurrentUsage: (tenantId) => dispatch(getCurrentUsageAction(tenantId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
