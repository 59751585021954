import { Box, Grid, Paper, makeStyles } from '@material-ui/core';
import React from 'react';
import LineChart from './lineChart';

const useStyles = makeStyles((theme) => ({}));
const BotBuilder = () => {
	const classes = useStyles();
	return <LineChart />;
};

export default BotBuilder;
