export const LOG_USER_IN_SUCCESS = '[auth] login user success';
export const LOG_USER_IN_FAILED = '[auth] login user failed';

export const LOG_USER_OUT = 'LOG_USER_OUT';

export const REGISTER_USER_SUCCESS = '[auth] register user success';
export const REGISTER_USER_FAILED = '[auth] register user failed';

export const RESEND_CONFIRMATION_SIGNUP_SUCCESS =
	'[auth] resend confirmation sign up success';
export const RESEND_CONFIRMATION_SIGNUP_FAIL =
	'[auth] resend confirmation sign up fail';

export const SEND_CONFIRM_USER_SUCCESS = '[auth] send confirm user success';
export const SEND_CONFIRM_USER_FAIL = '[auth] send confirm user fail';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SEND_RESET_PASSWORD_FAIL = '[auth] send reset password fail';

export const SEND_RESET_PASSWORD_INSTRUCTIONS_SUCCESS =
	'[auth] reset password instructions success';
export const SEND_RESET_PASSWORD_INSTRUCTIONS_FAIL =
	'[auth] reset password instructions failed';

export const SEND_TENANT_NAME_REMINDER_EMAIL_SUCCESS =
	'SEND_TENANT_NAME_REMINDER_EMAIL_SUCCESS';
export const SEND_TENANT_NAME_REMINDER_EMAIL_FAIL =
	'SEND_TENANT_NAME_REMINDER_EMAIL_FAIL';

export const GET_CURRENT_USER = 'GET_CURRENT_USER';
export const AUTHENTICATE = 'AUTHENTICATE';
export const CLEAR_IS_AUTHENTICATED = 'CLEAR_IS_AUTHENTICATED';
export const SAVE_USER_PERMISSION_TOKEN_SUCCESS =
	'SAVE_USER_PERMISSION_TOKEN_SUCCESS';
export const SAVE_USER_PERMISSION_TOKEN_FAILED =
	'SAVE_USER_PERMISSION_TOKEN_FAILED';
export const DELETE_USER_PERMISSION_TOKEN_SUCCESS =
	'DELETE_USER_PERMISSION_TOKEN_SUCCESS';
export const DELETE_USER_PERMISSION_TOKEN_FAILED =
	'DELETE_USER_PERMISSION_TOKEN_FAILED';
export const EDIT_CURRENT_USER_PROFILE_SUCCESS =
	'[auth] edit current user profile success';
export const EDIT_CURRENT_USER_PROFILE_FAIL = '[auth] edit current user fail';
export const EDIT_PASSWORD_SUCCESS = '[auth] edit password success';
export const EDIT_PASSWORD_FAIL = '[auth] edit password fail';
export const UPLOAD_AVATAR_SUCCESS = '[auth] upload avatar success';
export const UPLOAD_AVATAR_FAIL = '[auth] upload avatar fail';
