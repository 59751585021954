import {
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	CardMedia,
} from '@material-ui/core';

import React from 'react';
import Bot from 'assets/images/bot_builder_logo_large.png';

const BotsTable = ({ talkybots }) => {
	const classes = useStyles();

	return (
		<TableContainer className={classes.root} CrmTable={Paper}>
			<Table className={classes.table} aria-label="simple table">
				{/* table header */}
				<TableHead>
					<TableRow>
						<TableCell className={classes.tableHeader} align="left">
							Bot Name
						</TableCell>
						<TableCell className={classes.tableHeader} align="left">
							Custom Fields
						</TableCell>
						<TableCell className={classes.tableHeader} align="left">
							Engagements Number
						</TableCell>
						<TableCell className={classes.tableHeader} align="left">
							Accessed Users
						</TableCell>
					</TableRow>
				</TableHead>
				{/* body */}
				<TableBody>
					{talkybots?.map((bot) => (
						<TableRow
							key={bot.id}
							className={classes.customTableRow}
						>
							<TableCell
								align="left"
								className={classes.tableRow}
								style={{
									display: 'flex',
									direction: 'row',
								}}
							>
								<CardMedia
									image={bot?.image ? bot?.image : Bot}
									alt={bot.name}
									style={{
										width: '2.5rem',
										height: '2.5rem',
										borderRadius: '50%',
										marginRight: '1rem',
									}}
								/>
								{bot.name}
							</TableCell>
							<TableCell
								align="left"
								className={classes.tableRow}
							>
								{bot.variations_number}
							</TableCell>
							<TableCell
								align="left"
								className={classes.tableRow}
							>
								{bot.engagements_number}
							</TableCell>
							<TableCell
								align="left"
								className={classes.tableRow}
							>
								{bot?.users?.length}
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

export default BotsTable;

const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: '1rem',
		background: 'white',
		marginTop: '0.5rem',
		boxShadow: 'none',
		overflowX: 'auto ',
		minHeight: '25rem',
	},
	table: {
		// minWidth: '40.625rem',
		boxShadow: 'none',
	},
	tableHeader: {
		padding: '1.5rem',
		font: 'normal normal bold 13px/22px Poppins',
	},
	customTableRow: {
		'&:hover': {
			boxShadow: '0px 1px 28px #0000001f',
		},
	},
	tableRow: {
		fontFamily: 'Poppins',
		fontSize: '1.1875rem',
		padding: '1.5rem',
	},

	label: {
		fontFamily: 'Poppins',
		width: '100%',
		fontSize: '1.1875rem ',
	},
	list: {
		fontFamily: 'Poppins',
	},
	statue: {
		borderRadius: '10px',
		textTransform: 'capitalize',
		padding: '2px 1.25rem',
	},
	noneStatue: {
		background: '#DEDADA',
	},
	leadStatue: {
		background: '#B7DBFF',
	},
	contactStatue: {
		background: '#B7FFE1',
	},
	client: {
		display: 'flex',
		alignItems: 'center',
	},
	image: {
		width: '2.5rem',
		borderRadius: '50%',
		height: '2.5rem',
		objectFit: 'cover',
	},
	iconButton: {
		display: 'flex',
		alignItems: 'center',
		fontSize: '0.9375rem',
		cursor: 'pointer',
		marginRight: '1rem',
		'&:hover': {
			color: '#6418c3',
		},
	},

	fieldValue: {
		fontSize: '1.25rem',
		fontFamily: 'Poppins',
		color: 'black',
	},
	formKeyContainer: {
		width: '25%',
	},
	inputField: {
		fontSize: '1.125rem',
		fontFamily: 'Poppins',
		color: 'black',
	},
}));
