import {
	Box,
	Grid,
	makeStyles,
	Paper,
	Divider,
	useTheme,
	Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import BotsTable from './BotsTable';
import MeterCard from './MeterCard/component';

const MeterInfo = ({ meterInfo, talkybots }) => {
	const theme = useTheme();
	const classes = useStyles();
	return (
		<Paper elevation={0} className={classes.meterContainer}>
			<Grid container direction="row" alignItems="stretch" spacing={3}>
				<Grid
					item
					// md={8}
					container
					justifyContent="space-between"
					spacing={10}
				>
					{meterInfo?.map((bot, i) => (
						<Grid key={i} item xs={3}>
							<MeterCard
								totalNumOfMeter={bot.totalNumOfMeter}
								currentNumOfMeter={bot.currentNumOfMeter}
								nameOfMeter={bot.nameOfMeter}
							/>
						</Grid>
					))}
				</Grid>
				{/* <Grid item xs={10} md={4} align="center">
					<Box className={classes.activeUsersWrapper}>
						Monthly Active Users
					</Box>
				</Grid> */}
				<Divider className={classes.divider} />
				<Typography className={classes.botUsage}>Bot Usage</Typography>
				<Grid item xs={12}>
					<Paper elevation={0} className={classes.botsTableContainer}>
						<BotsTable talkybots={talkybots} />
					</Paper>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default MeterInfo;

const useStyles = makeStyles((theme) => ({
	meterContainer: {
		backgroundColor: 'transparent',
	},
	activeUsersWrapper: {
		background: '#37295E 0% 0% no-repeat padding-box',
		boxShadow: '0px 9px 28px #37295E4D',
		borderRadius: '0.5rem',
		height: '100%',
		color: '#fff',
		paddingTop: '6.5rem',
	},
	botsTableContainer: {
		marginRight: '1rem',
		height: '60%',
		overflow: 'scroll',
		'&::-webkit-scrollbar': {
			width: theme.spacing(6),
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: '0px 3px 40px #00000029',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
		},
		'&::-webkit-scrollbar-thumb': {
			borderRadius: '3rem',
			backgroundColor: theme.palette.primary.main,
		},
	},
	divider: {
		width: '100%',
		marginTop: '2rem',
		marginBottom: '3rem',
	},
	botUsage: {
		fontSize: '1.125rem',
		fontFamily: 'Poppins',
		fontWeight: 'bold',
		color: '#000000',
		textTransform: 'capitalize',
		padding: '1.425rem 0rem',
	},
}));
