import {
	GET_ALL_PATHS_FLOWS,
	CREATE_PATH_FLOW,
	DELETE_PATH_FLOW,
	TOGGLE_PATH_FLOW,
	EDIT_PATH_FLOW,
} from 'store/actions/types/pathsflows';

export default (state = [], { type, payload }) => {
	switch (type) {
		case GET_ALL_PATHS_FLOWS: {
			const { flows } = payload;
			return {
				...flows,
			};
		}

		case CREATE_PATH_FLOW: {
			const { flow } = payload;
			return {
				...state,
				[flow.id]: {
					...flow,
				},
			};
		}
		case EDIT_PATH_FLOW: {
			const { pathFlowId, updatedFlow } = payload;
			const newState = { ...state };
			const newFlow = { ...newState[pathFlowId], ...updatedFlow };
			newState[pathFlowId] = newFlow;
			return newState;
		}

		case DELETE_PATH_FLOW: {
			const { pathFlowId } = payload;
			const newState = { ...state };
			delete newState[pathFlowId];
			return newState;
		}

		case TOGGLE_PATH_FLOW: {
			const { pathFlowId } = payload;
			const newState = { ...state };
			const previousFlowStatue = newState[pathFlowId].status;
			newState[pathFlowId].status = !previousFlowStatue;
			return newState;
		}

		default:
			return state;
	}
};
