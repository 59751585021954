import {
	GET_ALL_NOTIFICATIONS,
	NOTIFY_USERS_TO_TAKE_OVER_CONVERSATION,
} from 'store/actions/types/notifications';

export default (state = null, { type, payload }) => {
	switch (type) {
		case GET_ALL_NOTIFICATIONS: {
			const { notifications } = payload;
			return {
				...notifications,
			};
		}
		case NOTIFY_USERS_TO_TAKE_OVER_CONVERSATION: {
			const { notification } = payload;
			return {
				notifications: [...state.notifications, notification],
			};
		}
		default:
			return state;
	}
};
