import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {
	CircularProgress,
	Grid,
	makeStyles,
	Paper,
	Typography,
} from '@material-ui/core';
import completeUserProfileBG from 'assets/images/completeUserProfileBG.png';
import completeUserProfileImg from 'assets/images/completeUserProfileImg.png';
import GridContainer from 'components/common/Grid/GridContainer';
import GridItem from 'components/common/Grid/GridItem';

import useQuery from 'hooks/useQuery';
import setAuthToken from 'helpers/set-auth-token';

const Component = ({
	// userEmail,
	userData,
	sentCompleteUserProfile,
	loading,
	error,
	auth,
	isAuthenticated,
}) => {
	const query = useQuery();
	const token = query.get('token');
	const email = query.get('email');
	const expiresAt = query.get('expiresAt');
	const history = useHistory();

	const classes = useStyles();
	const [firstPage, setFirstPage] = useState(true);
	const {
		formState: { errors },
		handleSubmit,
		register,
		watch,
		trigger,
	} = useForm();
	const handleNextBtn = async () => {
		await trigger();
		if (Object.keys(errors).length === 0) {
			setFirstPage(false);
		}
	};
	const onSubmit = (data) => {
		// password & confirm_pass are equal?
		if (
			Object.keys(errors).length === 0 &&
			watch('password') === watch('password_confirmation')
		) {
			// set header
			setAuthToken(token);
			// dispatch data
			sentCompleteUserProfile(data);
			// ;
		}
	};
	// save token & go to system
	useEffect(() => {
		const navigateToTalkybot = async () => {
			// when api return success
			if (!error && !loading && userData?.success) {
				await auth(token, expiresAt);
				history.push('/talkybots');
			}
		};
		navigateToTalkybot();
	}, [loading]);

	// if login go to system
	useEffect(() => {
		if (isAuthenticated) {
			history.push('/talkybots');
		}
		if (!email || !token || !expiresAt) {
			history.push('/login');
		}
	}, []);
	// alert(query.email)
	return (
		<div>
			<Grid
				className={classes.container}
				container
				direction="row"
				justify="center"
				alignItems="center"
			>
				<Grid item xs={8}>
					<Paper className={classes.profileContentBox} elevation={0}>
						<GridContainer justifyContent="center">
							<GridItem sm={12} md={6}>
								<form
									style={{ width: '80%' }}
									onSubmit={handleSubmit(onSubmit)}
								>
									<Typography className={classes.formHeader}>
										complete your profile
									</Typography>
									{/** ************ first page*********** */}
									{firstPage && (
										<div>
											{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
											<label
												className={classes.labelStyle}
											>
												Full Name:
											</label>
											<input
												type="text"
												className={classes.inputStyle}
												{...register('full_name', {
													required:
														'This filed is required',
													pattern: /^[(A-Za-z),\s]+$/,
													minLength: {
														value: 10,
														message: 'Too Short',
													},
													maxLength: {
														value: 30,
														message: 'Too long',
													},
												})}
											/>
											{(errors.full_name &&
												errors.full_name.type ===
													'pattern' && (
													<p>
														input must be only char
													</p>
												)) ||
												(errors.full_name && (
													<p>
														{
															errors.full_name
																.message
														}
													</p>
												))}
											{/* email */}
											{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
											<label
												className={classes.labelStyle}
											>
												Email:
											</label>

											<input
												disabled
												value={email}
												className={`${classes.inputDisableStyle}  ${classes.inputStyle}`}
											/>
										</div>
									)}
									{/** ************ second page*********** */}
									{!firstPage && (
										<div>
											{/* password */}
											{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
											<label
												className={classes.labelStyle}
											>
												password
											</label>
											<input
												type="password"
												className={classes.inputStyle}
												{...register('password', {
													required:
														'This filed is required',
													minLength: {
														value: 8,
														message:
															'Must be at least 8 characters',
													},
												})}
											/>
											{errors.password && (
												<p>{errors.password.message}</p>
											)}
											{/* confirm pass */}
											{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
											<label
												className={classes.labelStyle}
											>
												confirm password
											</label>
											<input
												type="password"
												className={classes.inputStyle}
												{...register(
													'password_confirmation',
													{
														required:
															'This filed is required',
													}
												)}
											/>
											{(errors.password_confirmation && (
												<p>
													{
														errors
															.password_confirmation
															.message
													}
												</p>
											)) ||
												(watch('password') !==
													watch(
														'password_confirmation'
													) && (
													<p>
														Does Not match the
														actual password !
													</p>
												))}
										</div>
									)}

									<div className={classes.dotsContainer}>
										<span
											className={
												firstPage
													? classes.activeDot
													: classes.notActiveDot
											}
										>
											{' '}
										</span>

										<span
											className={
												!firstPage
													? classes.activeDot
													: classes.notActiveDot
											}
										>
											{' '}
										</span>
									</div>
									<div className={classes.footerBtn}>
										{!firstPage && (
											<button
												className={classes.backBtn}
												onClick={() =>
													setFirstPage(true)
												}
											>
												<i
													className={`${classes.nextIcon}  fas fa-angle-left`}
												>
													{' '}
												</i>
												Back
											</button>
										)}
										{firstPage && (
											<button
												className={classes.nextBtn}
												onClick={handleNextBtn}
											>
												Next{' '}
												<i
													className={`${classes.nextIcon}  fas fa-angle-right`}
												>
													{' '}
												</i>
											</button>
										)}
										{!firstPage && (
											<button
												type="submit"
												className={classes.nextBtn}
												disabled={loading}
											>
												{loading ? (
													<CircularProgress
														color="white"
														size={15}
													/>
												) : (
													'Finish'
												)}
											</button>
										)}
									</div>
									{error ? (
										<div className={classes.errorStyle}>
											{error}
										</div>
									) : null}
								</form>
							</GridItem>

							<GridItem align="center" md={6}>
								<img
									className={classes.profileContentBoxImg}
									src={completeUserProfileImg}
									alt="complete User Profile"
								/>
								<Typography className={classes.footerDesc}>
									join your organization team
								</Typography>
							</GridItem>
						</GridContainer>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

export default Component;

const useStyles = makeStyles(() => ({
	container: {
		backgroundImage: `url(${completeUserProfileBG})`,
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 100%',
		height: '100vh',
		padding: '1px',
	},
	formHeader: {
		fontFamily: 'Poppins !important',
		fontSize: '1.875rem !important',
		fontWeight: 600,
		color: '#202020',
		textTransform: 'capitalize',
	},
	footerDesc: {
		fontFamily: 'Poppins !important',
		fontSize: '2rem  !important',
		fontWeight: 800,
		color: '#000000',
		textTransform: 'capitalize',
		width: '90%',
	},
	profileContentBox: {
		// margin: '0rem auto 0rem auto',
		// width: '65rem',
		minHeight: '35rem',
		boxShadow: '0px 4px 14px #0000000A',
		borderRadius: '0.625rem',
		padding: '3rem 2.5rem',
	},
	profileContentBoxImg: {
		width: '100%',
		marginBottom: '2rem',
	},
	labelStyle: {
		display: 'block',
		marginTop: '2rem',
		fontFamily: 'Poppins !important',
		fontSize: '1.0625rem !important',
		fontWeight: 500,
		color: '#212121',
		textTransform: 'capitalize',
	},
	inputStyle: {
		fontFamily: 'Poppins !important',
		fontSize: '1.25rem !important',
		fontWeight: 500,
		color: '#000000',
		textTransform: 'capitalize',

		width: '100%',
		height: '3rem',
		margin: '1rem 0px 0.2rem 0px',
		display: 'block',
		border: '1px solid #D9D9D9',
		borderRadius: '0.625rem',
		padding: '0px 1rem',
	},
	inputDisableStyle: {
		background: '#ECEEF0 0% 0% no-repeat padding-box',
	},
	dotsContainer: {
		textAlign: 'center',
		margin: '2rem',
	},
	activeDot: {
		background: '#6418C3 0% 0% no-repeat padding-box',
		borderRadius: '1.75rem',
		display: 'inline-block',
		width: '1.25rem',
		height: '0.625rem',
		margin: '0px 0.3125rem',
	},
	notActiveDot: {
		display: 'inline-block',
		width: '0.625rem',
		height: '0.625rem',
		background: '#C4C4C4 0% 0% no-repeat padding-box',
		borderRadius: '50%',
	},
	nextBtn: {
		fontFamily: 'Poppins !important',
		fontSize: '1.0625rem !important',
		fontWeight: 600,
		textTransform: 'capitalize',
		letterSpacing: '0.87px',
		textAlign: 'center',

		float: 'right',
		// padding: '.5rem 1rem',
		minWidth: '6rem',
		lineHeight: '2.5rem',
		minHeight: '2.5rem',
		borderRadius: '.5rem',
		color: '#FFFFFF',
		background: '#6418C3 0% 0% no-repeat padding-box',
		boxShadow: ' 0px 4px 4px #0000000A',
		cursor: 'pointer',
	},
	backBtn: {
		fontFamily: 'Poppins !important',
		fontSize: '1.0625rem !important',
		fontWeight: 600,
		textTransform: 'capitalize',
		letterSpacing: '0.87px',
		color: '#000000',
		cursor: 'pointer',
	},
	nextIcon: {
		fontSize: '1.4rem !important',
		// fontWeight:500
		transform: 'translateY(13%)',
		// marginRight: '.5rem',
	},
	errorStyle: {
		color: '#D8000C',
		marginTop: '.5rem',
	},
	footerBtn: {
		marginTop: '4rem',
	},
}));
