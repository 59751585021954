export const GET_SOME_CONVERSATIONS = 'GET_SOME_CONVERSATIONS';
export const GET_CONVERSATION_MESSAGES = 'GET_CONVERSATION_MESSAGES';
export const ADD_NEW_CONVERSATION = 'ADD_NEW_CONVERSATION';
export const MARK_CONVERSATIONS_AS_DONE = 'MARK_CONVERSATIONS_AS_DONE';
export const ACTIVATE_CONVERSATION = 'ACTIVATE_CONVERSATION';
export const ADD_NEW_MESSAGE_TO_LIVE_CHAT = 'ADD_NEW_MESSAGE_TO_LIVE_CHAT';
export const ADD_UPCOMING_MESSAGE_TO_CONVERSATION =
	'ADD_UPCOMING_MESSAGE_TO_CONVERSATION';
export const TAKE_OVER = 'TAKE_OVER';
export const UN_TAKE_OVER = 'UN_TAKE_OVER';
export const TAKE_OVER_FAILED = 'TAKE_OVER_FAILED';
export const PAUSE_BOT_ANSWERING_ON_CONVERSATION =
	'PAUSE_TALKYBOT_ON_CONVERSATION';
export const RESUME_BOT_ANSWERING_ON_CONVERSATION =
	'RESUME_BOT_ANSWERING_ON_CONVERSATION';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const ADD_NOTE_TO_PERSON_FROM_CONVERSATION =
	'ADD_NOTE_TO_PERSON_FROM_CONVERSATION';
export const MARK_LAST_MESSAGE_AS_OLD = 'MARK_LAST_MESSAGE_AS_OLD';
export const GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_SUCCESS =
	'GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_SUCCESS';
export const GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_FAILED =
	'GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_FAILED';
export const DEACTIVATE_CONVERSATION = 'DEACTIVATE_CONVERSATION';

export const GET_CONVERSATIONS_BY_CRM_STATUS_REQUEST =
	'GET_CONVERSATIONS_BY_CRM_STATUS_REQUEST';
export const GET_CONVERSATIONS_BY_CRM_STATUS_SUCCESS =
	'GET_CONVERSATIONS_BY_CRM_STATUS_SUCCESS';
export const GET_CONVERSATIONS_BY_CRM_STATUS_FAIL =
	'GET_CONVERSATIONS_BY_CRM_STATUS_FAIL';

export const MARK_AS_DONE = 'MARK_AS_DONE';
export const MARK_AS_OPEN = 'MARK_AS_OPEN';

export const FETCH_MORE_MESSAGES = 'FETCH_MORE_MESSAGES';
