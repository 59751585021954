import React, { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'store/actions/creators/auth';
import navigateUser from 'helpers/navigate-user';

const EntryPoint = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);
	const location = useLocation();
	const { path, url } = useRouteMatch();

	useEffect(() => {
		const tryLogin = async () => {
			const tokenData = localStorage.getItem('tokenData');
			if (!tokenData) {
				// clear auth
				dispatch(authActions.clearAuthenticateAction());
				// User goes to public routes
				if (location.pathname === '/') {
					history.push({ url });
				} else history.push(location.pathname + location.search);
				return;
			}
			const transformedData = JSON.parse(tokenData);
			const { token, expiresAt } = transformedData;
			const expirationDate = new Date(expiresAt);
			// check expiration time also

			if (!token) {
				// TODO is to logout
				dispatch(authActions.logUserOutAction());
				history.push('/login');
				return;
			}

			// TODO is to fire AUTHENTICATE
			dispatch(authActions.authenticate(token, expirationDate));
			const { user } = auth;

			if (user) {
				navigateUser(user, history, location);
			}
		};
		tryLogin();
	}, []);

	return <div />;
};

export default EntryPoint;
