import firebase from 'firebase';
import { DEVICE_NOTIFICATION_PERMISSION } from 'constants/tokensTypes';
import {
	deleteUserPermissionTokenAction,
	saveUserPermissionTokenAction,
} from 'store/actions/creators/auth';

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FCM_APP_API_KEY,
	authDomain: process.env.REACT_APP_FCM_APP_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FCM_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FCM_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FCM_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FCM_APP_APP_ID,
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();

export const onMessageListener = () =>
	new Promise((resolve) => {
		messaging.onMessage((payload) => {
			resolve(payload);
		});
	});

export const getToken = (dispatch) => {
	return messaging
		.getToken({
			vapidKey: process.env.REACT_APP_FCM_APP_SERVER_KEY,
		})
		.then((currentToken) => {
			if (currentToken) {
				localStorage.setItem('firebase_token', currentToken);
				const tokenConfig = {
					token_type: DEVICE_NOTIFICATION_PERMISSION,
					token_value: currentToken,
				};
				dispatch(saveUserPermissionTokenAction(tokenConfig));
			} else {
				// shows on the UI that permission is required
			}
		})
		.catch((err) => {
			// ;
		});
};
