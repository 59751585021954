import { connect } from 'react-redux';
import { getAllBotsAction } from 'store/actions/creators/talkybots';
import getCurrentUsageAction from 'store/actions/creators/usage';

const mapStateToProps = (state) => {
	return {
		meterInfo: state.usage?.currentUsage?.meterInfo,
		planDetails: state.usage?.currentUsage?.details?.attributes,
		talkybots: state.talkybots.bots
			? Object.values(state.talkybots.bots)
			: [],
		tenantId: state.auth.user.tenant.id,
		loading: state.usage?.loading,
		error: state.usage?.error,
		userID: state.auth.user.user_profile.id,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCurrentUsage: (tenantId) =>
			dispatch(getCurrentUsageAction(tenantId)),
		getAllBots: (setUiLoadingError) =>
			dispatch(getAllBotsAction(setUiLoadingError)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
