import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import AudioSound from 'assets/audio/notification.mp3';
import {
	ThemeProvider,
	CssBaseline,
	Grid,
	Avatar,
	Typography,
	makeStyles,
} from '@material-ui/core';
import { PersistGate } from 'redux-persist/integration/react';
import { getToken, onMessageListener } from 'config/firebase';
import './App.css';
import { SnackbarProvider, useSnackbar } from 'notistack';
import theme from './theme';
import Routes from './routes';
import EntryPoint from './pages/EntryPoint';
import storeConfig from './store/config';

function App() {
	const [notification, setNotification] = useState({
		new: false,
		data: null,
	});
	const classes = useStyles();
	const audio = new Audio(AudioSound);
	// const { enqueueSnackbar } = useSnackbar();

	// const NotificationCard = ({ bot, person, sender }) => {
	// 	return (
	// 		<Grid container alignItems="center">
	// 			<Avatar src={sender?.image} />
	// 			<Typography style={{ marginLeft: '1rem' }}>
	// 				{' '}
	// 				New Notification From {sender?.name}
	// 			</Typography>
	// 		</Grid>
	// 	);
	// };
	// onMessageListener().then((payload) => {
	// 	audio.play();
	// 	enqueueSnackbar(
	// 		<NotificationCard
	// 			bot={JSON.parse(payload?.data?.bot)}
	// 			person={JSON.parse(payload?.data?.person)}
	// 			sender={JSON.parse(payload?.data?.sender)}
	// 		/>,
	// 		{
	// 			variant: 'info',
	// 			autoHideDuration: 3000,
	// 			anchorOrigin: {
	// 				vertical: 'top',
	// 				horizontal: 'left',
	// 			},
	// 		}
	// 	);

	// setNotification({
	// 	new: true,
	// 	data: payload,
	// });
	// });
	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<div className="App">
				<BrowserRouter
					getUserConfirmation={(message, callback) => {
						// this is the default behavior
						const allowTransition = window.confirm(message);
						callback(allowTransition);
					}}
				>
					<Provider store={storeConfig().store}>
						<PersistGate persistor={storeConfig().persistor}>
							<EntryPoint />
							<Routes />
						</PersistGate>
					</Provider>
				</BrowserRouter>
			</div>
		</ThemeProvider>
	);
}

export default App;
const useStyles = makeStyles(() => ({
	snackbarRoot: {},
}));
