import { Container, Grid, Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TalkyBotToolsImg from 'assets/images/landingImgs/homeTalkyBotTools.png';
import UnionTop from 'assets/images/landingImgs/unionHomeTools.png';
import UnionBottom from 'assets/images/landingImgs/unionHomeToolsBottom.png';
const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		backgroundColor: theme.palette.background.primaryOpacity,
		marginTop: '6.25rem',
	},
	UnionTop: {
		textAlign: 'end',
		transform: 'translatex(-3%)',
		backgroundColor: 'transparent',
	},
	UnionBottom: {
		textAlign: 'end',
		transform: 'translate(-4% , -70%)',
		backgroundColor: 'transparent',
	},
	sectionContent: {
		// padding:"0px 0px 100px 0px"
	},
	sectionContentPrgrph: {
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
			marginTop: '1.25rem',
		},
		textAlign: 'start',
		padding: '0px 16% ',
		backgroundColor: 'transparent',
	},
	headerSm: {
		...theme.typography.subtitle5,
		color: theme.palette.text.primary,
	},
	headerLg: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
	},
	headerDesc: {
		...theme.typography.body3,
		color: theme.palette.text.dark,
		margin: '1.25rem 0px',
	},
	readMoreBtn: {
		color: '#FFFFFF',
		backgroundColor: '#43348B ',
		boxShadow: 'none',
		padding: '0.75rem',
		fontSize: '0.9375ewm',
		// fontWeight:"bold",
		textTransform: 'capitalize',
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));
const TalkyBotTools = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0} className={classes.sectionStyle}>
			<Paper elevation={0} className={classes.UnionTop}>
				<img width="15%" height="15%" src={UnionTop} alt="" />
			</Paper>
			<Container className={classes.containerStyle} maxWidth={false}>
				<Grid
					className={classes.sectionContent}
					container
					justify="space-between"
					alignItems="center"
				>
					<Grid item md={6}>
						<img width="75%" src={TalkyBotToolsImg} alt="" />
					</Grid>
					<Grid container item md={6}>
						<Paper
							elevation={0}
							className={classes.sectionContentPrgrph}
						>
							<Typography className={classes.headerSm}>
								Talkybot tools
							</Typography>
							<Typography className={classes.headerLg}>
								Connect Anytime, Anywhere
							</Typography>
							<Typography className={classes.headerDesc}>
								Talkybot allows you to create chatbots that
								integrate with Facebook messenger, WhatsApp and
								even your own website. You can connect to one of
								these platforms or all of them. The choice is
								yours.
							</Typography>
							<Button
								variant="contained"
								classes={{ root: classes.readMoreBtn }}
								color="white"
							>
								Read More
							</Button>
						</Paper>
					</Grid>
				</Grid>
			</Container>
			<Paper elevation={0} className={classes.UnionBottom}>
				<img width="7%" height="7%" src={UnionBottom} alt="" />
			</Paper>
		</Paper>
	);
};

export default TalkyBotTools;
