import React from 'react';
import { Grid, Box, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	BoxFrame: {
		backgroundColor: '#fff',
		border: '1px #F2F2F2',
		borderRadius: '1.25rem',
		padding: '0.9375rem 2.875rem 0.625rem 1rem',
		marginTop: '0.9375rem',
	},
	DBconversationBox1Img: {},
	BoxDesc: {
		fontFamily: 'Poppins, sans-serif !important',
		color: '#170A38',
		textTransform: 'capitalize',
		marginLeft: '1.9375rem',
		fontSize: '1.2rem !important',
	},
	BoxMessage: {
		fontFamily: 'Poppins, sans-serif !important',
		color: '#F41515',
		textTransform: 'capitalize',
		fontSize: '1.1rem !important',
	},
}));
const TopicBox = ({ img, desc, numMessage }) => {
	const classes = useStyles();
	console.log('TopicBox', img, desc, numMessage);
	return (
		<Box className={classes.BoxFrame}>
			<Grid container justify="space-around" alignItems="center">
				<Grid item xs={1}>
					<img
						className={classes.DBconversationBox1Img}
						src={img}
						alt=""
					/>
				</Grid>
				<Grid align="left" item xs={7}>
					<Typography className={classes.BoxDesc}>{desc}</Typography>
				</Grid>
				<Grid align="right" item xs={4}>
					<Typography className={classes.BoxMessage}>
						{numMessage} Times
					</Typography>
				</Grid>
			</Grid>
		</Box>
	);
};

export default TopicBox;
