import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import {
	CAN_CREATE_BOT,
	CAN_PUBLISH_BOT,
	CAN_VIEW_BOT_BUILDER,
	CAN_VIEW_CONVERSATION,
	CAN_VIEW_CRM,
	CAN_VIEW_NOTIFICATIONS,
	CAN_VIEW_SETTINGS,
	CAN_VIEW_STORE,
	CAN_VIEW_TALKYBOTS,
	CAN_VIEW_TENANT_MEMBERS,
	CAN_EDIT_PERSON_PROFILE,
	CAN_VIEW_DASHBOARD,
	CAN_VIEW_USAGE,
} from 'constants/permissions';
import Apps from 'constants/applications';
import UserHasPermission from 'hoc/UserHasPermission';
import MainLayout from 'layouts/MainLayout';
import NotFoundComponent from 'components/404/Component';

function ErrorFallback({ error }) {
	return (
		<div role="alert">
			<p>Something went wrong:</p>
			<pre>{error.message}</pre>
		</div>
	);
}

const PrivateRoute = ({ component: Component, ...rest }) => {
	const auth = useSelector((state) => state.auth);
	// eslint-disable-next-line consistent-return
	const getActionAndAppForRoute = (route) => {
		const editRegex = /talkybots\/[0-9]*/g;
		const createBotRegex = /talkybots\/create/g;
		if (!route) throw new Error('no route has found !');

		if (route.includes('conversations')) {
			return {
				action: CAN_VIEW_CONVERSATION,
				app: Apps.Conversation.applicationType,
			};
		}
		if (route === '/talkybots/:talkybotId/dashboard')
			return { action: CAN_VIEW_DASHBOARD };
		if (route === '/talkybots/:talkybotId/crm/:person_id') {
			return {
				action: CAN_EDIT_PERSON_PROFILE,
				app: Apps.CRM.applicationType,
			};
		}
		if (route.includes('crm'))
			return { action: CAN_VIEW_CRM, app: Apps.CRM.applicationType };
		if (route.includes('bot-builder'))
			return {
				action: CAN_VIEW_BOT_BUILDER,
				app: Apps.BotBuilder.applicationType,
			};

		if (route.includes('talkybots')) return { action: CAN_VIEW_TALKYBOTS };
		// if (teamMemberRegex.test(route)) return { action: CAN_EDIT_PERMISSION };
		if (route.includes('team-members'))
			return { action: CAN_VIEW_TENANT_MEMBERS };
		if (route.includes('notifications'))
			return { action: CAN_VIEW_NOTIFICATIONS };

		if (route.includes('usage')) return { action: CAN_VIEW_USAGE };

		if (route.includes('store')) return { action: CAN_VIEW_STORE };
		if (route.includes('settings')) return { action: CAN_VIEW_SETTINGS };
		if (route.includes('connect')) return { action: CAN_PUBLISH_BOT };
		if (createBotRegex.test(route)) return { action: CAN_CREATE_BOT };
		if (editRegex.test(route)) return { action: CAN_CREATE_BOT };
		return { action: '' };
	};

	const userHasPermissionProps = getActionAndAppForRoute(rest.path);

	return (
		<Route
			{...rest}
			render={(props) =>
				auth.isAuthenticated === true ? (
					<UserHasPermission
						action={userHasPermissionProps?.action}
						app={userHasPermissionProps?.app}
						FallbackComponent={() => (
							<MainLayout>
								<NotFoundComponent />
							</MainLayout>
						)}
					>
						<ErrorBoundary FallbackComponent={ErrorFallback}>
							<Component {...props} />
						</ErrorBoundary>
					</UserHasPermission>
				) : (
					<Redirect
						to={{
							pathname: '/login',
							state: { from: props?.location },
						}}
					/>
				)
			}
		/>
	);
};

export default PrivateRoute;
