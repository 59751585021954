export default (user, history, location) => {
	const isConfirmed = user ? user['confirmed?'] : null;
	const confirmationToken =
		user && !isConfirmed ? user['confirmation_token'] : null;

	if (isConfirmed === null) {
		// TODO is to navigate user to landing page
		history.push('/login');
		return;
	}

	if (!isConfirmed) {
		history.push(
			`/confirmation-signup?confirmation_token=${confirmationToken}`
		);
	}

	let userCurrentPath;
	userCurrentPath = location?.search
		? location?.pathname + location.search
		: location.pathname;

	history.push(userCurrentPath);
};
