import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
	root: {
		minWidth: '17.1875rem',
		height: '5rem',
		fontFamily: 'Cairo-Bold',
		borderRadius: '0.625rem',
		boxShadow: 'none',
	},
	bullet: {
		display: 'inline-block',
		margin: '0 0.125rem',
		transform: 'scale(0.8)',
	},
	title: {
		fontSize: '0.9375rem',
	},
	pos: {
		marginBottom: '0.75rem',
	},
	select: {
		'&:before': {
			borderColor: '#C7C7C7',
		},
		'&:after': {
			borderColor: '#C7C7C7',
		},
	},
	formControl: {
		border: 'none',
		minWidth: '15.625rem',
		height: '4rem',
	},
	container: {
		flexGrow: 1,
	},
	list: {
		fontFamily: 'Cairo-Bold',
	},
}));

/**
 *
 * @param children
 * @param panelSize
 * @param anotherGrid
 * @param talkybots
 * @param activateTalkybot
 * @param activeTalkybot
 * @param getAllCategories
 * @param resetFaqsState
 * @returns {JSX.Element}
 * @constructor
 */
const BotNameComponent = ({
	children,
	panelSize,
	anotherGrid,
	talkybots,
	activateTalkybot,
	activeTalkybot,
	getAllCategories,
	resetFaqsState,
	resetActiveIntent,
}) => {
	const classes = useStyles();
	const [botId, setBotId] = useState('');

	const history = useHistory();
	const handleChange = (event) => {
		if (activeTalkybot?.id !== event.target.value) {
			history.push('/talkybots');
		}
		setBotId(event.target.value);
	};

	return (
		<Card
			className={classes.root}
			style={{ width: panelSize === 'sm' ? '85%' : '100%' }}
		>
			<CardContent style={{ padding: '0.5rem 1.25rem 0rem 1.25rem' }}>
				<Grid
					container
					className={classes.container}
					direction="row"
					alignItems="center"
				>
					<Grid item xs={anotherGrid || 10}>
						<FormControl className={classes.formControl}>
							<InputLabel id="demo-simple-select-label">
								{activeTalkybot
									? activeTalkybot?.name
									: 'Bot Name'}
							</InputLabel>
							<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={botId}
								onChange={handleChange}
								className={classes.select}
							>
								{talkybots?.map((talkybot, i) => (
									<MenuItem
										key={i}
										className={classes.list}
										value={talkybot?.id}
									>
										{talkybot?.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>

					{children}
				</Grid>
			</CardContent>
		</Card>
	);
};

export default BotNameComponent;
