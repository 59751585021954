import { handleActionError } from 'helpers/reducerError';
import axios from 'helpers/axios';
import { accountsPath } from 'constants/apis';
import {
	LIST_ALL_PAGES_FOR_ACCOUNT,
	LIST_ALL_ACCOUNTS,
	CONNECT_TO_PAGE,
	CLEAN_PAGES,
	REMOVE_PAGE,
	DISCONNECT_FROM_PAGE,
	DISCONNECT_PAGE_CHANNEL,
	REMOVE_FACEBOOK_CHANNEL,
	DISCONNECT_AND_CONNECT_PAGE_CHANNEL,
} from 'store/actions/types/tenants';
import { getAllAccountsAction } from './accounts';

export const listAccountsAction = () => {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { success, data },
			} = await axios.get(`${accountsPath}`);
			if (status && success) {
				const actionData = {
					type: LIST_ALL_ACCOUNTS,
					payload: {
						accounts: data,
					},
				};

				dispatch(actionData);
			}
		} catch (e) {
			// handle error
		}
	};
};

export const listAllPagesForAccountAction = (accountId) => {
	return async (dispatch) => {
		try {
			const {
				status,
				data: { success, data },
			} = await axios.get(`${accountsPath}/${accountId}/channels`);
			if (status && success) {
				const actionData = {
					type: LIST_ALL_PAGES_FOR_ACCOUNT,
					payload: {
						pages: data,
					},
				};
				dispatch(actionData);
			}
		} catch (e) {
			// handle error
		}
	};
};

// ... Action to Connect to facebook
// Publish bot to facebook page
export const publishToThisPageAction = (
	talkybotId,
	accountId,
	pageId,
	enqueueSnackbar
) => {
	return async (dispatch) => {
		try {
			const { status, data } = await axios.post(
				`${accountsPath}/${accountId}/channels/${pageId}/connect`,
				{
					talkybot_id: Number(talkybotId),
				}
			);
			console.log('res from publish to page ', data, status);
			if (status && data.success) {
				const actionData = {
					type: CONNECT_TO_PAGE,
					payload: {
						pageId,
						accountId,
					},
				};
				dispatch(getAllAccountsAction());
				enqueueSnackbar(`Connect Bot successfully`, {
					variant: 'success',
					autoHideDuration: 3000,
				});
				dispatch(actionData);
			}
		} catch (e) {
			const error = handleActionError(
				e,
				"Can't connect bot now , please try again"
			);
			enqueueSnackbar(error, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};
};

/**
 *
 * @param talkybotId
 * @param accountId
 * @param pageId
 * @returns {(function(*): Promise<void>)|*}
 */

export const disconnectPageAction = (
	talkybotId,
	accountId,
	pageId,
	enqueueSnackbar
) => {
	return async (dispatch) => {
		try {
			const { status, data } = await axios.post(
				`${accountsPath}/${accountId}/channels/${pageId}/disconnect`,
				{
					talkybot_id: Number(talkybotId),
				}
			);
			console.log('res from disconnect page ', data, status);
			if (status && data.success) {
				const actionData = {
					type: DISCONNECT_FROM_PAGE,
					payload: {
						pageId,
					},
				};
				enqueueSnackbar(`Disconnect Bot successfully`, {
					variant: 'success',
					autoHideDuration: 3000,
				});
				dispatch(actionData);
			}
		} catch (e) {
			const error = handleActionError(
				e,
				"Can't Disconnect bot now , please try again"
			);
			enqueueSnackbar(error, {
				variant: 'error',
				autoHideDuration: 3000,
			});
		}
	};
};

/**
 *
 * @param pageId
 * @returns {(function(*): Promise<void>)|*}
 */

export const removePageAction = (pageId, accountId) => {
	return async (dispatch) => {
		try {
			const response = await axios.delete(
				`${accountsPath}/${accountId}/channels/${pageId}`
			);

			dispatch({ type: REMOVE_PAGE });
		} catch (e) {
			// handle error
		}
	};
};

export const disconnectPageChannel = (
	accountId,
	channelId,
	enqueueSnackbar,
	talkybotId,
	handleCloseModal
) => {
	return async (dispatch, getState) => {
		try {
			const { accounts } = getState();
			console.log('🧔🧔🧔🧔', accounts);
			const res = await axios.post(
				`${accountsPath}/${accountId}/channels/${channelId}/disconnect`,
				{
					talkybot_id: Number(talkybotId),
				}
			);

			console.log('res from disconnect page channel🌐🌐🌐', res);

			if (res.data.success) {
				handleCloseModal();
				dispatch({
					type: DISCONNECT_PAGE_CHANNEL,
					payload: {
						accountId,
						channelId,
						talkybotId,
						accounts,
					},
				});
				dispatch(getAllAccountsAction());
				enqueueSnackbar(`Disconnect Bot successfully`, {
					variant: 'success',
					autoHideDuration: 3000,
				});
			} else {
				enqueueSnackbar(res.data.error, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			}
		} catch (e) {
			// handle error
			enqueueSnackbar('Error in disconnect Bot', {
				variant: 'error',
				autoHideDuration: 2500,
			});
		}
	};
};

// CLEAN_PAGES

/**
 *
 * @returns {{type: string}}
 */

export const resetPagesAction = () => {
	return { type: CLEAN_PAGES };
};

export const removeFacebookChannelAction = (accountId, channelId) => {
	return async (dispatch, getState) => {
		const { accounts } = getState();
		try {
			const response = await axios.delete(
				`${accountsPath}/${accountId}/channels/${channelId}`
			);
			const accountIndex = accounts.findIndex(
				(account) => account.id === accountId
			);

			if (accountIndex !== -1) {
				const account = accounts[accountIndex];
				const channelIndex = account.channels.findIndex(
					(channel) => channel.id === channelId
				);
				if (channelIndex !== -1) {
					account.channels.splice(channelIndex, 1);
				}
				accounts[accountIndex] = account;
			}

			dispatch({
				type: REMOVE_FACEBOOK_CHANNEL,
				payload: accounts,
			});
		} catch (e) {
			// handle error
		}
	};
};

export const disconnectAndConnectPageChannel = (
	talkyBotId,
	oldAccountId,
	oldChannelId,
	newAccountId,
	newChannelId,
	enqueueSnackbar,
	handleFn
) => {
	return async (dispatch) => {
		try {
			const response = await axios.post(
				`${accountsPath}/${oldAccountId}/channels/${oldChannelId}/disconnect_and_connect`,
				{
					new_channel_id: String(newChannelId),
					new_account_id: String(newAccountId),
					talkybot_id: String(talkyBotId),
				}
			);
			console.log('res from disconnect page ', response);
			if (response.data.error) {
				enqueueSnackbar(response.data.error, {
					variant: 'error',
					autoHideDuration: 3000,
				});
			} else {
				enqueueSnackbar(`Disconnect Bot successfully`, {
					variant: 'success',
					autoHideDuration: 3000,
				});
				dispatch({ type: DISCONNECT_AND_CONNECT_PAGE_CHANNEL });
				dispatch(getAllAccountsAction());
			}

			handleFn();
		} catch (e) {
			// handle error
			enqueueSnackbar('Error in disconnect and connect Channel', {
				variant: 'error',
				autoHideDuration: 2500,
			});
		}
	};
};
