import { Paper, Grid, Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { Line } from 'react-chartjs-2';

const data = {
	labels: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	],
	datasets: [
		{
			label: 'topic flow',
			data: [20, 30, 60, 30, 55, 30, 60, 30, 55, 30, 69, 20],
			borderColor: '#6418C3',
			backgroundColor: ['#6418C3'],
		},
		{
			label: 'faqs',
			data: [15, 25, 40, 25, 45, 27, 50, 10, 40, 15, 55, 20],
			borderColor: '#FFBB54 ',
			backgroundColor: ['#FFBB54'],
		},
	],
};

const options = {
	responsive: true,
	maintainAspectRatio: false,
	scales: {
		yAxes: {
			min: 0,
			max: 75,
			beginAtZero: true,
			ticks: {
				stepSize: 25,
			},
		},
		xAxes: [
			{
				type: 'time',
				time: {
					parser: 'MMM-YY',
					unit: 'month',
					displayFormats: {
						month: 'MMM-YY',
					},
				},
			},
		],
	},
	plugins: {
		interaction: {
			mode: 'index',
		},
		legend: {
			display: true,
			// position: 'bottom',
			borderWidth: 0,
			labels: {
				boxWidth: 15,
				boxHeight: 15,
				padding: 40,
				color: '#464255',
			},
		},
		tooltip: {
			intersect: false,
			// position: 'nearest',
			caretPadding: 5,
			caretSize: 10,
			cornerRadius: 12,
			borderColor: ' 0px 15px 16px #21212124',
			borderWidth: 1,
			usePointStyle: false,
			backgroundColor: '#fff',

			bodyFont: {
				size: 20,
			},
			bodyColor: '#202020',
			footerColor: '#202020',
			titleColor: '#202020',
			callbacks: {
				labelPointStyle: function (context) {
					return {
						pointStyle: 'triangle',
						rotation: 0,
					};
				},
			},
		},
	},
	elements: {
		line: {
			borderWidth: 4,
			cubicInterpolationMode: 'monotone',
		},
		point: {
			borderColor: '#fff',
			// borderWidth:10,
			backgroundColor: '#fff',
			radius: 0,
			hoverRadius: 8,
			// hoverBorderWidth: 5,
			// pointStyle:"circle"
		},
	},
};
const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		margin: '2.1875rem 0px',
		padding: '0.625rem 1.875rem',
		borderRadius: '1.25rem',
		border: '1px dashed #F2F2F2',
	},
	contentInfo: {
		borderLeft: '2px solid #F2F2F2',
		height: '100%',
		paddingLeft: '0.9375rem',
	},

	boxColorTopic: {
		display: 'inline-block',
		width: '0.9375rem',
		height: '0.9375rem',
		backgroundColor: '#6418C3',
		borderRadius: '4px',
		marginRight: '0.9375rem',
	},
	boxColorFAQS: {
		display: 'inline-block',
		width: '0.9375rem',
		height: '0.9375rem',
		backgroundColor: '#FFBB54',
		borderRadius: '4px',
		marginRight: '0.9375rem',
	},
	contentInfoLabel: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1rem !important',
		color: '#464255',
		textTransform: 'capitalize',
		fontWeight: 400,
	},

	contentInfoTotal: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.375rem !important',
		color: '#464255',
		textTransform: 'capitalize',
		fontWeight: 500,
		marginLeft: '1.875rem',
	},
	contentInfoLabelNum: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1rem !important',
		color: '#170A38',
		fontWeight: 400,
		marginLeft: '1.875rem',
		marginBottom: '0.9375rem',
	},
	contentInfoTotalNum: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.625rem !important',
		color: '#101010',
		fontWeight: 600,
		marginLeft: '1.875rem',
		marginBottom: '0.9375rem',
	},
}));
const LineChart = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0} className={classes.sectionStyle}>
			<Grid container justify="space-between" alignItems="center">
				<Grid item xs={10}>
					<Line data={data} height={316} options={options} />
				</Grid>
				<Grid item xs={2}>
					<Box className={classes.contentInfo}>
						{/*  */}
						<Typography className={classes.contentInfoLabel}>
							<Box className={classes.boxColorTopic}> </Box>
							topic flow messages
						</Typography>
						<Typography className={classes.contentInfoLabelNum}>
							{data.datasets[0].data.length}
						</Typography>
						{/*  */}
						<Typography className={classes.contentInfoLabel}>
							<Box className={classes.boxColorFAQS}> </Box>FAQS
						</Typography>
						<Typography className={classes.contentInfoLabelNum}>
							{data.datasets[1].data.length}
						</Typography>
						{/*  */}
						<Typography className={classes.contentInfoTotal}>
							total messages
						</Typography>
						<Typography className={classes.contentInfoTotalNum}>
							{data.datasets[1].data.length +
								data.datasets[0].data.length}
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default LineChart;
