import React from 'react';
import {
	Container,
	Grid,
	makeStyles,
	Paper,
	Typography,
} from '@material-ui/core';
import CrmContacts from './CrmClientsSource';
import CrmGender from './CrmAssignedClients';
import CrmStatus from './CrmStatus';

const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		backgroundColor: 'transparent',
	},
}));
const CrmChart = ({ clientSources, assignedClientsState, crmStatus }) => {
	const classes = useStyles();
	return (
		<Paper elevation={0} className={classes.sectionStyle}>
			<Grid container justify="center" alignItems="center" spacing={10}>
				<Grid item md={4}>
					<CrmStatus crmStatus={crmStatus} />
				</Grid>
				<Grid item md={4}>
					<CrmGender assignedClientsState={assignedClientsState} />
				</Grid>
				<Grid item md={4}>
					<CrmContacts clientSources={clientSources} />
				</Grid>
			</Grid>
		</Paper>
	);
};

export default CrmChart;
