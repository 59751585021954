import { Box, Container, Grid, Paper } from '@material-ui/core';
import unionBenefits from 'assets/images/landingImgs/UnionBenefits.png';
import unionUnionNum from 'assets/images/landingImgs/UnionNum.png';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		textAlign: 'center',
		position: 'relative',
		marginTop: '3.125rem',
	},
	unionBenefits: {
		width: '7.5rem',
		height: '7.0625rem',
		backgroundColor: 'transparent',
		// transform: "translateY(-40%)",
		position: 'absolute',
		right: 10,
		animation: '$slideDown 10s linear  infinite alternate-reverse',
	},
	'@keyframes slideDown': {
		from: { top: '0px' },
		to: { top: '-100px' },
	},
	headerSm: {
		...theme.typography.subtitle5,
		color: theme.palette.text.primary,
		textTransform: 'capitalize',
	},
	headerLg: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		margin: '0.9375rem 0.9375rem',
	},
	contentStyle: {
		marginTop: '3.125rem',
	},
	UnionNumImg: {
		backgroundImage: 'url(' + unionUnionNum + ')',
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		display: 'inline-block',
		backgroundPosition: 'center center',
		marginBottom: '1.25rem',
	},
	UnionNum: {
		...theme.typography.title1,
		color: theme.palette.text.primary,
		padding: '1.875rem 1.25rem',
	},
	UnionHeader: {
		...theme.typography.subtitle4,
		color: theme.palette.text.primary,
	},
	UnionDesc: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));
const Benefits = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0} className={classes.sectionStyle}>
			<Container>
				{/* header */}
				<Paper elevation={0} className={classes.unionBenefits}>
					<img
						width="100%"
						height="100%"
						src={unionBenefits}
						alt=""
					/>
				</Paper>
				<Typography className={classes.headerSm}>Benefits</Typography>
				<Typography className={classes.headerLg}>
					Why Your Business Needs Talkybot ?
				</Typography>
				{/*  */}
				<Paper elevation={0} className={classes.contentStyle}>
					<Grid container alignItems="center" justify="" spacing={1}>
						{/*  */}
						<Grid item xs={12} md={4}>
							<Box className={classes.UnionNumImg}>
								<Typography className={classes.UnionNum}>
									30%
								</Typography>
							</Box>
							<Typography className={classes.UnionHeader}>
								Increase Your Sales
							</Typography>
							<Typography className={classes.UnionDesc}>
								Talkybot Increase Sales By 67%
							</Typography>
						</Grid>
						{/*  */}
						<Grid item xs={12} md={4}>
							<Box className={classes.UnionNumImg}>
								<Typography className={classes.UnionNum}>
									30%
								</Typography>
							</Box>
							<Typography className={classes.UnionHeader}>
								Increase Your Sales
							</Typography>
							<Typography className={classes.UnionDesc}>
								Talkybot Increase Sales By 67%
							</Typography>
						</Grid>
						{/*  */}
						<Grid item xs={12} md={4}>
							<Box className={classes.UnionNumImg}>
								<Typography className={classes.UnionNum}>
									30%
								</Typography>
							</Box>
							<Typography className={classes.UnionHeader}>
								Increase Your Sales
							</Typography>
							<Typography className={classes.UnionDesc}>
								Talkybot Increase Sales By 67%
							</Typography>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Paper>
	);
};

export default Benefits;
