import React from 'react';
import { makeStyles } from '@material-ui/core';
import WhatsappLogo from 'assets/images/whatsapp_small.png';
import FacebookLogo from 'assets/images/facebook-logo.png';
import theme from 'theme';

const CustomAvatar = ({ name = '', style, provider = '' }) => {
	const classes = useStyles();
	const [firstName, lastName] = name?.split(' ');
	const getFirstChar = (_name) => {
		return _name && _name[0];
	};

	return (
		<div className={classes.fakeAvatar} style={{ ...style }}>
			{getFirstChar(firstName)}
			{getFirstChar(lastName)}

			{provider === 'whatsapp' && (
				<img
					src={WhatsappLogo}
					alt="whatsappLogo"
					className={classes.socialLogo}
				/>
			)}
			{provider === 'facebook' && (
				<img
					src={FacebookLogo}
					alt="FacebookLogo"
					className={classes.socialLogo}
				/>
			)}
		</div>
	);
};

const useStyles = makeStyles(() => ({
	fakeAvatar: {
		width: '3.125rem',
		height: '3.125rem',
		background: theme.palette.primary.main,
		border: '1px solid #00000000',
		borderRadius: '1.8125rem',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: '#fff',
		fontFamily: 'Cairo-Bold',
		textTransform: 'uppercase',
		position: 'relative',
	},
	socialLogo: {
		position: 'absolute',
		width: '13x',
		height: '13px',
		bottom: '0px',
		right: '0px',
	},
}));

export default CustomAvatar;
