import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import GridContainer from 'components/common/Grid/GridContainer';
import GridItem from 'components/common/Grid/GridItem';
import Providers from 'constants/providers';

import CustomButton from 'components/common/CustomButton/Component';
import CustomLoader from 'components/common/CustomSpinner/Component';
import ModalContainer from 'components/common/ModalContainer/Modal';
import styles from './Component.module.css';

const TalkybotConnection = ({
	listAccounts,
	publishToThisPage,
	resetPages,
	disconnectPage,
	accounts,
	pages,
	listAllPagesForAccount,
}) => {
	const [accountId, setAccountId] = useState();
	const [connectionModal, setConnectionModal] = useState(false);
	const { talkybotId } = useParams();
	useEffect(() => {
		if (connectionModal) {
			listAccounts();
		}
		return () => {
			resetPages();
			setAccountId(null);
		};
	}, [connectionModal]);

	const REDIRECT_URI = `${process.env.REACT_APP_DEVELOPMENT_CLIENT_URL}/facebook-redirection-handler`;

	const facebookLoginLink = `https://www.facebook.com/v10.0/dialog/oauth?client_id=${process.env.REACT_APP_FACEBOOK_APP_CLIENT_ID}&redirect_uri=${REDIRECT_URI}&state=StringWillBePassedAsIsToYourURL&scope=email,public_profile,pages_show_list,pages_manage_metadata,pages_messaging,leads_retrieval`;

	const handleOpenConnectionModal = () => {
		setConnectionModal(true);
	};

	const { enqueueSnackbar } = useSnackbar();

	const handleCloseConnectionModal = () => {
		setConnectionModal(null);
	};

	return (
		<div className={styles.test}>
			<div className={styles.publish__on}>Publish on</div>
			<GridContainer>
				{Providers.map((provider) => (
					<GridItem xs={4}>
						<div
							className={styles.TalkybotConnectionRealEstateCard}
						>
							<div className={styles.Sec_center}>
								<img
									className={styles.connectImage}
									src={provider.src}
									alt={provider.name}
								/>
								{provider.src2 && (
									<img
										className={styles.connectImage}
										style={{ marginLeft: '1rem' }}
										src={provider.src2}
										alt={provider.name}
									/>
								)}
							</div>

							<p className={styles.providerName}>
								{provider.name}
							</p>

							<div className={styles.Sec_center}>
								<p
									className={
										styles.RealEstateCard_Collect_Leads
									}
								>
									{provider.description}
								</p>
							</div>

							<button
								className={styles.linkButton}
								onClick={handleOpenConnectionModal}
							>
								<>
									<span style={{ marginLeft: '5px' }}>
										Publishing
									</span>
								</>
							</button>
						</div>
					</GridItem>
				))}
			</GridContainer>
			<div>
				<ModalContainer
					title="Connect Talkybot"
					type="vt"
					open={connectionModal}
					onUnmount={resetPages}
					onClose={() => handleCloseConnectionModal()}
					component={
						<div>
							{accounts?.length > 0 ? (
								<div>
									<p
										style={{
											display: 'flex',
											alignItems: 'center',
										}}
									>
										1. Please Choose an account{' '}
										<a
											className={styles.linkButton}
											style={{
												margin: '0',
												marginLeft: '1rem',
											}}
											href={facebookLoginLink}
										>
											Add New Account?
										</a>
									</p>
									<select
										style={{
											padding: '0.7rem',
											display: 'flex',
											alignItems: 'center',
											borderRadius: '8px',
											background: '#ECEEF0',
											marginTop: '10px',
											minHeight: '52px',
											minWidth: '100%',
											marginLeft: '5px',
										}}
										onChange={(e) => {
											setAccountId(e.target.value);
											listAllPagesForAccount(
												e.target.value
											);
										}}
									>
										<img
											src={accounts[1]?.attributes.avatar}
											alt="profile"
										/>
										<option value="">-</option>
										{accounts.map((account) => (
											<option value={account.id}>
												{account.attributes.name}
											</option>
										))}
									</select>

									<p style={{ marginTop: '1rem' }}>
										2. Please Choose Your page to publish
										Chatbot on it
									</p>
									<div
										style={{
											border: '1px solid #ddd',
											padding: '0.5rem 1rem',
											borderRadius: '10px',
											marginTop: '1rem',
										}}
									>
										{pages ? (
											pages?.map((page) => (
												<div
													style={{
														display: 'flex',
														justifyContent:
															'space-between',
														alignItems: 'center',
														margin: '1rem',
													}}
												>
													<div
														style={{
															display: 'flex',
															justifyContent:
																'center',
															alignItems:
																'center',
														}}
													>
														<img
															className={
																styles.pageImage
															}
															src={
																page.attributes
																	.avatar
															}
															alt="page"
														/>
														<span>
															{
																page.attributes
																	.name
															}
														</span>
													</div>
													{page.attributes
														?.talkybot_connection
														?.talkybot_id ===
													Number(talkybotId) ? (
														<>
															<CustomButton
																color="red"
																name="disconnect"
																onClick={() => {
																	disconnectPage(
																		talkybotId,
																		accountId,
																		page.id,
																		enqueueSnackbar
																	);
																	handleCloseConnectionModal();
																}}
															/>
														</>
													) : page.attributes
															?.talkybot_connection
															?.talkybot_id ||
													  page.attributes
															?.is_connected ? (
														<CustomButton
															color="disabled"
															name="Can't be connected"
														/>
													) : (
														<CustomButton
															color="primary"
															name="connect"
															onClick={() => {
																publishToThisPage(
																	talkybotId,
																	accountId,
																	page.id,
																	enqueueSnackbar
																);
																handleCloseConnectionModal();
															}}
														/>
													)}
												</div>
											))
										) : accountId ? (
											<div
												style={{
													display: 'flex',
													justifyContent: 'center',
												}}
											>
												<CustomLoader size="small" />
											</div>
										) : (
											<p>No pages yet</p>
										)}
									</div>
								</div>
							) : (
								<div
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									You have no accounts yet{' '}
									<a
										className={styles.linkButton}
										style={{
											margin: '0',
											marginLeft: '1rem',
										}}
										href={facebookLoginLink}
									>
										Add New Account?
									</a>
								</div>
							)}
						</div>
					}
				/>
			</div>
		</div>
	);
};

export default TalkybotConnection;
