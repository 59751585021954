import { connect } from 'react-redux';

import { publishTalkybotOnAction } from 'store/actions/creators/talkybots';

import {
	listAccountsAction,
	listAllPagesForAccountAction,
	disconnectPageAction,
	publishToThisPageAction,
	resetPagesAction,
} from 'store/actions/creators/tenants';

const mapStateToProps = (state) => {
	return {
		accounts: state?.tenants?.accounts,
		pages: state?.tenants?.pages,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		publishTalkybotOn: (provider) =>
			dispatch(publishTalkybotOnAction(provider)),
		listAccounts: () => dispatch(listAccountsAction()),
		listAllPagesForAccount: (accountId) =>
			dispatch(listAllPagesForAccountAction(accountId)),
		publishToThisPage: (talkybotId, accountId, pageId, enqueueSnackbar) =>
			dispatch(
				publishToThisPageAction(
					talkybotId,
					accountId,
					pageId,
					enqueueSnackbar
				)
			),
		disconnectPage: (talkybotId, accountId, pageId, enqueueSnackbar) =>
			dispatch(
				disconnectPageAction(
					talkybotId,
					accountId,
					pageId,
					enqueueSnackbar
				)
			),
		resetPages: () => dispatch(resetPagesAction()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps);
