import React, { useEffect } from 'react';
import NotFoundImage from 'assets/images/404.png';
import GridContainer from 'components/common/Grid/GridContainer';

const NoAccessComponent = () => {
	useEffect(() => {
		document.title = 'Page not found - Talkybot';
	}, []);
	return (
		<GridContainer
			justifyContent="center"
			alignItems="center"
			style={{ height: '100vh', padding: '2rem' }}
		>
			<img height="100%" src={NotFoundImage} alt="404 notfound page" />
		</GridContainer>
	);
};

export default NoAccessComponent;
