import {
	Paper,
	Tab,
	Tabs,
	Typography,
	Box,
	Grid,
	Card,
} from '@material-ui/core';
import axios from 'helpers/axios';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import dashbordBox1 from 'assets/images/dashbordBox1.png';
import dashbordBox2 from 'assets/images/dashbordBox2.png';
import dashboardConversation from 'assets/images/DashboardConversation.svg';

// total card
import totalElementsImg from 'assets/images/totalElements.svg';
import totalClientsImg from 'assets/images/totalClients.svg';
import assignedContactsImg from 'assets/images/assignedContacts.svg';

import calendar from 'assets/images/calendar.svg';
import pieChartImg from 'assets/images/pie-chart 1.svg';

import TotalCard from 'components/dashboard/shared/totalCard';
// import { useEffect } from 'react';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		backgroundColor: 'transparent',
	},
	todayAnalytics: {
		marginLeft: '2rem',
		color: '#6418C3',
		font: 'normal normal bold 16px/25px Poppins',
		marginBottom: '1rem',
	},

	// header
	headerSection: {
		margin: '3.125rem 0px',
	},
	header: {
		font: 'normal normal bold 16px/26px Poppins',
		textTransform: 'capitalize',
	},
	headerDesc: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.125rem !important',
		color: '#A5A5A5',
		textTransform: 'capitalize',
	},
	headerChangePeriodBox: {
		backgroundColor: '#fff',
		padding: '0.625rem 0.9375rem',
		borderRadius: '0.9375rem',
		width: '54%',
	},
	headerDownloadReportBox: {
		backgroundColor: 'transparent',

		padding: '1.25rem 0.9375rem 0px  0.9375rem',
		borderRadius: '0.9375rem',
		boxShadow: ' 0px 4px 4px #0000000A',
		border: '1px solid #000000',
		// display: 'inline-block',
		marginLeft: '1.5625rem',
	},
	pieChartImg: {
		marginRight: '0.9375rem',
	},
	calenderImg: {
		display: 'inline-block',
		marginRight: '0.9375rem',
	},
	headerPeriod: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.125rem !important',
		fontWeight: 'bold !important',
		color: '#202020',
		textTransform: 'capitalize',
		display: 'inline-block',
	},
	headerPeriodDesc: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '0.875rem !important',
		color: '#A5A5A5',
		textTransform: 'capitalize',
	},
	ArrowDropDownIcon: {
		fontSize: '2.1875rem !important',
		color: '#A5A5A5',
	},

	createMoreBotBox: {
		width: '100%',

		borderRadius: '2rem',

		backgroundImage: `url(${dashbordBox1})`,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		padding: '3.5rem  2.375rem',
		[theme.breakpoints.up('lg')]: {
			padding: '3.5rem 12rem 3.5rem 2.375rem',
		},
	},
	manageBotBox: {
		width: '100%',
		borderRadius: '2rem',
		backgroundImage: `url(${dashbordBox2})`,
		backgroundSize: '100% 100%',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		padding: '3.5rem  2.375rem ',
		[theme.breakpoints.up('lg')]: {
			padding: '3.5rem 12rem 3.5rem 2.375rem',
		},
	},
	BoxText: {
		fontFamily: 'Poppins, sans-serif !important',
		fontWeight: 'medium !important',
		fontSize: '1.25rem !important',
		textTransform: 'capitalize',
		color: '#FFFFFF',
		marginBottom: '0.9375rem',
	},
	BoxBtn: {
		color: '#FFFFFF',
	},
	totalBox: {
		padding: '1.25rem',
		borderRadius: '0.625rem',
	},
	totalBoxHeader: {
		marginBottom: '0.9375rem',
	},
	totalBoxSubHeader: {
		width: '100%',
	},
	totalBoxNum: {
		display: 'inline',
	},
	arowBG: {
		display: 'inline-block',
		width: '6.8125rem',
		height: '2.4375rem',
		backgroundColor: '#1AD49212',
		borderRadius: '0.625rem',
		psdding: '1px',
	},
	arrowImg: {
		// display: 'inline-block',
		lineHeight: '2.4375rem',
		margin: '0px 0.625rem 0px 0.625rem',
		transform: 'translateY(30%)',
	},
	arrowNum: {
		fontFamily: 'Poppins, sans-serif !important',
		display: 'inline-block',
		lineHeight: '2.4375rem',
		fontSize: '16px !important',
		fontWeight: 500,
		color: ' #015F3E',
	},
	gridWrapper: {
		marginLeft: '1rem',
		marginRight: '1rem',
	},
}));
const DashboardHeader = ({ talkyBotId }) => {
	const classes = useStyles();
	const [value, setValue] = useState('one');
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	const [newClientsState, setNewClientsState] = useState({
		ratio: 0,
		number_of_today_clients: 0,
		number_of_yesterday_clients: 0,
	});
	const [newConversationsState, setNewConversationsState] = useState({
		ratio: 0,
		number_of_today_engagements: 0,
		number_of_yesterday_engagements: 0,
	});
	const [newTakeoversState, setNewTakeoversState] = useState({
		ratio: 0,
		number_of_today_takeovers: 0,
		number_of_yesterday_takeovers: 0,
	});

	const getNewClients = async (talkyBotID) => {
		const response = await axios.get(
			`/api//statistics/${talkyBotID}/new_clients`
		);
		console.log('newClientsState', response.data);
		setNewClientsState(response?.data?.data);
		return response;
	};

	const getNewConversations = async (talkyBotID) => {
		const response = await axios.get(
			`/api//statistics/${talkyBotID}/new_engagements`
		);
		console.log('newConversationsState', response.data);
		setNewConversationsState(response?.data?.data);
		return response;
	};

	const getNewTakeovers = async (talkyBotID) => {
		const response = await axios.get(
			`/api//statistics/${talkyBotID}/number_of_takeovers`
		);
		console.log('newTakeoversState', response.data);
		setNewTakeoversState(response?.data?.data);
		return response;
	};

	useEffect(() => {
		getNewClients(talkyBotId);
		getNewConversations(talkyBotId);
		getNewTakeovers(talkyBotId);
	}, []);

	return (
		<Paper className={classes.sectionStyle} elevation={0}>
			<Grid
				className={classes.headerSection}
				container
				justify="space-between"
				alignItems="center"
			>
				<Grid item md={3}>
					<Typography className={classes.header}>
						Dashboard
					</Typography>
					{/* <Typography className={classes.headerDesc}>
						Lorem ipsum dolor sit amet
					</Typography> */}
				</Grid>
				<Grid item md={6}>
					<Grid container>
						{/* <Grid
							item
							container
							wrap="nowrap"
							alignItems="center"
							className={classes.headerChangePeriodBox}
						>
							<img
								className={classes.calenderImg}
								src={calendar}
								alt=""
							/>
							<span>
								<Typography className={classes.headerPeriod}>
									Change Periode
								</Typography>
								<Typography
									className={classes.headerPeriodDesc}
								>
									August 28th - October 28th, 2020
								</Typography>
							</span>
							<ArrowDropDownIcon
								className={classes.ArrowDropDownIcon}
							/>
						</Grid>
						<Grid item className={classes.headerDownloadReportBox}>
							<Grid
								container
								alignItems="center"
								justify="space-between"
							>
								<img
									className={classes.pieChartImg}
									src={pieChartImg}
									alt=""
								/>
								<Typography className={classes.headerPeriod}>
									Download Report
								</Typography>
							</Grid>
						</Grid> */}
					</Grid>
				</Grid>
			</Grid>
			{/* <Tabs
				value={value}
				onChange={handleChange}
				indicatorColor="primary"
				textColor="primary"
				centered
			>
				<Tab label="All" />
				<Tab label="bot analytics" />
				<Tab label="crm analytics" />
				<Tab label="live chat analytics" />
				<Tab label="team members analytics" />
			</Tabs> */}
			{/* <Grid
				container
				direction="row"
				justify="space-between"
				alignItems="center"
				spacing={4}
			>
				<Grid item md={5}>
					<Box className={classes.createMoreBotBox}>
						<Typography className={classes.BoxText}>
							create more bots add your team members to handle
							those bots
						</Typography>
						<Link className={classes.BoxBtn} to="/">
							Read More
						</Link>
					</Box>
				</Grid>
				<Grid item md={7}>
					<Box className={classes.manageBotBox}>
						<Typography className={classes.BoxText}>
							manage your bot builder more with create topic flows
							and faqs AND LET YOUR BOT handle your conversationS
							and contacts
						</Typography>
						<Link className={classes.BoxBtn} to="/">
							Read More
						</Link>
					</Box>
				</Grid>
			</Grid> */}
			<Paper
				style={{
					height: '15rem',
					paddingTop: '1.7rem',
				}}
			>
				<Typography className={classes.todayAnalytics}>
					Today Analytics
				</Typography>
				<Box className={classes.gridWrapper}>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="center"
						spacing={10}
					>
						<Grid item md={4}>
							<TotalCard
								cardHeader="Number of New Customers"
								totalElementsImg={totalClientsImg}
								arrowImg={
									newClientsState?.ratio > 0 ? 'up' : 'down'
								}
								arrowNum={newClientsState?.ratio?.toFixed(2)}
								totalNum={
									newClientsState?.number_of_today_clients
								}
								arrowColor={
									newClientsState?.ratio > 0
										? '#015F3E'
										: '#F41515'
								}
								todayNumber={
									newClientsState?.number_of_today_clients
								}
								yesterdayNumber={
									newClientsState?.number_of_yesterday_clients
								}
							/>
						</Grid>
						<Grid item md={4}>
							<TotalCard
								cardHeader="Number of New Conversations"
								totalElementsImg={dashboardConversation}
								arrowImg={
									newConversationsState?.ratio > 0
										? 'up'
										: 'down'
								}
								arrowNum={newConversationsState?.ratio?.toFixed(
									2
								)}
								totalNum={
									newConversationsState?.number_of_today_engagements
								}
								arrowColor={
									newConversationsState?.ratio > 0
										? '#015F3E'
										: '#F41515'
								}
								todayNumber={
									newConversationsState?.number_of_today_engagements
								}
								yesterdayNumber={
									newConversationsState?.number_of_yesterday_engagements
								}
							/>
						</Grid>
						<Grid item md={4}>
							<TotalCard
								cardHeader="Takeovers / Notify to Takeovers"
								totalElementsImg={assignedContactsImg}
								totalNum={
									newTakeoversState?.number_of_today_takeovers
								}
								arrowImg={
									newTakeoversState?.ratio > 0 ? 'up' : 'down'
								}
								arrowNum={newTakeoversState?.ratio?.toFixed(2)}
								arrowColor={
									newTakeoversState?.ratio > 0
										? '#015F3E'
										: '#F41515'
								}
								todayNumber={
									newTakeoversState?.number_of_today_takeovers
								}
								yesterdayNumber={
									newTakeoversState?.number_of_yesterday_takeovers
								}
							/>
						</Grid>
					</Grid>
				</Box>
			</Paper>
			{/* <TabPanel value={value} index={0}>
				ds
			</TabPanel> */}
		</Paper>
	);
};

const mapStateToProps = (state) => ({
	talkyBotId: state?.activeTalkybot?.id,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
