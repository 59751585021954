import talkybotAxios from './axios';

const apiRequest = {
	post: async ({ url, bodyData, onSuccess, onError, setUiLoadingError }) => {
		try {
			setUiLoadingError({ loading: true, error: null });
			const { data } = await talkybotAxios.post(url, bodyData);

			onSuccess(data);
			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message,
				code: e?.response?.data?.error?.code,
			});
			onError(e);
		}
	},
	patch: async ({ url, bodyData, onSuccess, onError, setUiLoadingError }) => {
		try {
			setUiLoadingError({ loading: true, error: null });
			const { data } = await talkybotAxios.patch(url, bodyData);
			onSuccess(data);
			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message,
				code: e?.response?.data?.error?.code,
			});
			onError(e);
		}
	},
	delete: async ({ url, params, onSuccess, onError, setUiLoadingError }) => {
		try {
			setUiLoadingError({ loading: true, error: null });
			const { data } = await talkybotAxios.delete(url, {
				params,
			});
			onSuccess(data);
			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message,
				code: e?.response?.data?.error?.code,
			});
			onError(e);
		}
	},
	put: async ({
		url,
		bodyData,
		onSuccess,
		onError,
		setUiLoadingError,
		config = null,
	}) => {
		try {
			setUiLoadingError({ loading: true, error: null });

			const response = await talkybotAxios.put(url, bodyData, config);
			const data = response.data;
			onSuccess(data);

			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message,
				code: e?.response?.data?.error?.code,
			});
			onError(e);
		}
	},
	get: async ({
		url,
		options = {},
		onSuccess,
		onError,
		setUiLoadingError,
	}) => {
		try {
			setUiLoadingError({ loading: true, error: null });
			const { data } = await talkybotAxios.get(url, options);
			onSuccess(data);
			setUiLoadingError({
				loading: false,
				success: data ? data : true,
				error: null,
			});
		} catch (e) {
			setUiLoadingError({
				loading: false,
				success: null,
				error: e?.response?.data?.error?.message,
				code: e?.response?.data?.error?.code,
			});
			onError(e);
		}
	},
};
export default apiRequest;
