import React from 'react';
import Loading from 'components/common/waitingReqView/loading';
import { Box, Button, makeStyles, Paper, Typography } from '@material-ui/core';
import planDetailsHeaderIcon from 'assets/icons/subscription.svg';
import planDetailsListIcon from 'assets/icons/planDetailsListIcon.svg';
import createPlanIcon from 'assets/icons/createPlanIcon.svg';

function PlanDetails({ planDetails }) {
	const classes = useStyles();
	return (
		<Paper elevation={0} className={classes.planDetails}>
			{/* header */}
			<Box className={classes.planDetailsHeaderContainer} elevation={0}>
				<img src={planDetailsHeaderIcon} alt="" />
				<Typography className={classes.planDetailsHeader}>
					Plan Details
				</Typography>
			</Box>

			{/* cards */}
			<Box className={classes.planCardContainer}>
				<Typography className={classes.planCardHeader}>
					Plan Name
				</Typography>
				<Typography className={classes.planCardSubscriptionData}>
					{planDetails?.title} Subscription
				</Typography>
			</Box>
			{planDetails?.next_date_for_paying && (
				<Box className={classes.planCardContainer}>
					<Typography className={classes.planCardHeader}>
						Next Date For Paying
					</Typography>

					<Typography className={classes.planCardSubscriptionData}>
						{planDetails?.next_date_for_paying}
					</Typography>
				</Box>
			)}
			<Box className={classes.planCardContainer}>
				<Typography className={classes.planCardHeader}>
					Paying Amount
				</Typography>
				<Typography className={classes.planCardPayingAmount}>
					{planDetails?.amount}$/{planDetails?.duration} days
				</Typography>
			</Box>
			{/* details */}
			<Typography className={classes.planFooterDetailsHeader}>
				Details
			</Typography>

			<Box
				elevation={0}
				className={classes.planFooterDetailsDataContainer}
			>
				{planDetails?.number_of_engagements && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has {planDetails?.number_of_engagements} Re-Engagements
					</Typography>
				)}
				{(planDetails?.number_of_custom_attributes === 0
					? true
					: planDetails?.number_of_custom_attributes) && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has {planDetails?.number_of_custom_attributes} Custom
						Variables
					</Typography>
				)}
				{planDetails?.number_of_team_members && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has {planDetails?.number_of_team_members} Team Members
					</Typography>
				)}
				{planDetails?.number_of_bots && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has {planDetails?.number_of_bots} Bots
					</Typography>
				)}
				{planDetails?.has_conversation_features && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has Conversation Features
					</Typography>
				)}
				{planDetails?.has_stores && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has Stores
					</Typography>
				)}
				{planDetails?.has_mobile && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has Mobile Application
					</Typography>
				)}
				{planDetails?.has_bot_builder && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has Botbuilder
					</Typography>
				)}
				{planDetails?.has_conversations && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has Conversations
					</Typography>
				)}
				{planDetails?.has_languages_and_translations && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Has Languages / Translations
					</Typography>
				)}
				{planDetails?.support && (
					<Typography className={classes.planFooterDetailsData}>
						<img src={planDetailsListIcon} alt="" />
						Support Email
					</Typography>
				)}
			</Box>

			{/* plan action */}
			<Box>
				<Button
					variant="contained"
					color="primary"
					className={classes.changePlanBtn}
				>
					Change Plan
				</Button>
				<Button className={classes.cancelPlanBtn}>
					Cancel The Plan
				</Button>
			</Box>
			{/* create own plan */}
			<Box className={classes.CreateOwnPlanCard}>
				<Box>
					<Typography className={classes.CreateOwnPlanHeader}>
						Create Your Own Plan
					</Typography>
					<Button className={classes.CreateOwnPlanBtn}>
						Start Now
					</Button>
				</Box>
				<img width="25%" src={createPlanIcon} alt="" />
			</Box>
		</Paper>
	);
}

export default PlanDetails;

const useStyles = makeStyles(() => ({
	planDetails: {
		padding: '1.6875rem 1.3rem',
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: '0px 6px 26px #E3E3E36E',
		display: 'flex',
		flexDirection: 'column',
		gap: '1.25rem',
	},
	// header
	planDetailsHeaderContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '1.09375rem',
	},
	planDetailsHeader: {
		fontSize: '1.25rem',
		fontWeight: '500',
		fontFamily: 'Poppins',
		color: '#070A28',
		textTransform: 'capitalize',
	},
	// card
	planCardContainer: {
		background: '#F4F7F9 0% 0% no-repeat padding-box',
		borderRadius: '0.625rem',
		padding: '0.625rem 1.3125rem',
	},
	planCardHeader: {
		fontSize: '0.75rem',
		fontWeight: '500',
		fontFamily: 'Poppins',
		color: '#777777',
		textTransform: 'capitalize',
	},
	planCardSubscriptionData: {
		fontSize: '1rem',
		fontWeight: '600',
		fontFamily: 'Poppins',
		color: '#000000',
		textTransform: 'capitalize',
	},
	planCardPayingAmount: {
		fontSize: '1rem',
		fontWeight: '600',
		fontFamily: 'Poppins',
		textTransform: 'capitalize',
		color: '#6418C3',
	},
	// details
	planFooterDetailsHeader: {
		fontSize: '0.9375rem',
		fontWeight: '500',
		fontFamily: 'Poppins',
		color: '#070A28',
		// marginTop: '0.625rem',
	},
	planFooterDetailsDataContainer: {
		background: '#F4F7F9 0% 0% no-repeat padding-box',
		borderRadius: '0.625rem',
		padding: '1.25rem 1rem 1.25rem 1.5rem',
	},
	planFooterDetailsData: {
		marginBottom: '0.9375rem',
		fontSize: '1rem',
		fontWeight: '600',
		fontFamily: 'Poppins',
		color: '#070A28',
		textTransform: 'capitalize',
		display: 'flex',
		justifyItems: 'center',
		gap: '1.125rem',
	},
	// plan Action
	changePlanBtn: {
		background: '#6418C3 0% 0% no-repeat padding-box',
		borderRadius: '0.625rem',
		// text style
		fontSize: '0.9375rem',
		fontWeight: '500',
		fontFamily: 'Poppins',
		textTransform: 'capitalize',
		color: '#FFFFFF',
	},
	cancelPlanBtn: {
		marginLeft: '.5rem',
		fontSize: '0.9375rem',
		fontWeight: '500',
		fontFamily: 'Poppins',
		textTransform: 'capitalize',
		color: '#000000',
	},
	CreateOwnPlanCard: {
		background: '#F4F7F9 0% 0% no-repeat padding-box',
		borderRadius: '0.5rem',
		padding: '1.25rem 0.65625rem ',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginTop: '3rem',
	},
	CreateOwnPlanHeader: {
		fontSize: '1.125rem',
		fontWeight: '500',
		fontFamily: 'Poppins',
		textTransform: 'capitalize',
		color: '#070A28',
		marginBottom: '0.5rem',
	},
	CreateOwnPlanBtn: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		border: '1px solid #F2F5F7',
		borderRadius: '0.625rem',
		padding: '0.6rem  1.6rem',
		// text style
		fontSize: '0.9375rem',
		fontWeight: '500',
		fontFamily: 'Poppins',
		textTransform: 'capitalize',
		color: '#000000',
	},
}));
