import React from 'react';
import {
	Avatar,
	Box,
	Card,
	CardHeader,
	CardMedia,
	IconButton,
	makeStyles,
	Divider,
	Typography,
} from '@material-ui/core';

import { numFormatter } from 'helpers/usage';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgressWithLabel from 'components/common/CircularProgressWithLabel/CircularProgressWithLabel';

import usageBots from 'assets/images/usageBots.svg';
import usageCustomVariables from 'assets/images/usageCustomVariables.svg';
import usageTeamMembers from 'assets/images/usageTeamMembers.svg';
import usageEngagements from 'assets/images/usageEngagements.svg';

const getImageUrl = (nameOfMeter) => {
	switch (nameOfMeter) {
		case 'Bots':
			return usageBots;
		case 'Custom Variables':
			return usageCustomVariables;
		case 'Team Members':
			return usageTeamMembers;
		case 'Re-Engagements':
			return usageEngagements;
		default:
			return '';
	}
};

function MeterCard({ totalNumOfMeter, currentNumOfMeter, nameOfMeter }) {
	const classes = useStyles();

	const percentage = Math.round((currentNumOfMeter / totalNumOfMeter) * 100);
	const progressColor = percentage > 75 ? 'red' : 'green';

	return (
		<Card elevation={0} className={classes.cardStyle}>
			<Typography className={classes.titleStyle}>
				{nameOfMeter === 'Custom Variables'
					? 'Custom Fields'
					: nameOfMeter}
			</Typography>
			<CardMedia
				image={getImageUrl(nameOfMeter)}
				style={{
					width: '1.2rem',
					height: '1.2rem',
					position: 'relative',
					top: '-2rem',
					left: '1rem',
					marginRight: '1rem',
				}}
			/>

			<Divider className={classes.divider} />
			<CardHeader
				classes={{
					title: classes.cardTitle,
					subheader: classes.cardSubHeader,
				}}
				avatar={
					<CircularProgressWithLabel
						minExpectedValue={0}
						maxExpectedValue={totalNumOfMeter}
						valueOfProgress={currentNumOfMeter}
						size="3.5rem"
						thickness={4}
						color={progressColor}
					/>
				}
				title={`${currentNumOfMeter} Of ${numFormatter(
					totalNumOfMeter
				)} ${
					nameOfMeter === 'Custom Variables'
						? 'Custom Fields'
						: nameOfMeter
				} `}
				subheader={`${totalNumOfMeter - currentNumOfMeter} ${
					nameOfMeter === 'Custom Variables'
						? 'Custom Fields'
						: nameOfMeter
				} left`}
			/>
		</Card>
	);
}

export default MeterCard;

const useStyles = makeStyles(() => ({
	cardStyle: {
		background: '#FFFFFF 0% 0% no-repeat padding-box',
		boxShadow: ' 0px 9px 28px #CCCCCC2E',
		borderRadius: '0.5rem',
		height: '10rem',
	},
	cardTitle: {
		fontSize: '.95rem',
		fontFamily: 'Poppins',
		fontWeight: '600',
		color: ' #222222',
		textTransform: 'capitalize',
		marginBottom: '.8rem',
	},
	cardSubHeader: {
		fontSize: '.9rem',
		fontFamily: 'Poppins',
		fontWeight: 'normal',
		color: ' #222222',
		textTransform: 'capitalize',
		opacity: 0.6,
	},
	divider: {
		margin: '0 auto',
		width: '90%',
		marginTop: '-0.5rem',
	},
	titleStyle: {
		paddingLeft: '3rem',
		paddingTop: '0.8rem',
		paddingBottom: '0.8rem',
		color: '#6418c3',
		fontSize: '1rem',
		fontFamily: 'Poppins',
		fontWeight: 'bold',
	},
}));
