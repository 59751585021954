import React from 'react';

import FacebookLogo from 'assets/images/facebook-logo.png';
import MessengerLogo from 'assets/icons/massenger.svg';

import { ReactComponent as FacebookConversationLogo } from 'assets/icons/facebookLogo.svg';
import { ReactComponent as InstagramConversationLogo } from 'assets/icons/InstagramConversationLogo.svg';
import { ReactComponent as InstagramSelectedConversationLogo } from 'assets/icons/InstagramSelectedConversationLogo.svg';
import { ReactComponent as TwitterConversationLogo } from 'assets/icons/TwitterConversationLogo.svg';
import { ReactComponent as WhatsappConversationLogo } from 'assets/icons/WhatsappConversationLogo.svg';

import instagramComingSoon from 'assets/icons/instagramComingSoon.svg';
import twitterComingSoon from 'assets/icons/twitterComingSoon.svg';

import whatsappComingSoon from 'assets/icons/whatsappComingSoon.svg';

// import WhatsappLogo from 'assets/images/Whatsapp_large.png';
// import WebLogo from 'assets/images/MESSENGER 001 (1).png';
// import TwitterLogo from 'assets/images/twitter.png';
// import LinkedinLogo from 'assets/images/linkedin.png';

const FACEBOOK = 'facebook';
const INSTAGRAM = 'instagram';
const TWITTER = 'twitter';
const WHATSAPP = 'whatsapp';

const currentProvidedApps = [FACEBOOK];
const conversationProvider = [
	{
		name: FACEBOOK,
		// eslint-disable-next-line react/jsx-filename-extension
		src: <FacebookConversationLogo fill="inherit" />,
		selectedColor: '#335AA6',
	},

	{
		name: INSTAGRAM,
		src: <InstagramConversationLogo fill="inherit" />,
		selectedColor: '#DD5541',
		// 'transparent linear-gradient(90deg, #f5db60 0%, #dd5541 50%, #ac3aa6 100%)',
		comingSoonSrcImg: instagramComingSoon,
	},
	{
		name: TWITTER,
		src: <TwitterConversationLogo fill="inherit" />,
		selectedColor: '#5A99EC',
		comingSoonSrcImg: twitterComingSoon,
	},
	{
		name: WHATSAPP,
		src: <WhatsappConversationLogo fill="inherit" />,
		selectedColor: '#488C03',
		comingSoonSrcImg: whatsappComingSoon,
	},
];
const providers = [
	{
		src: FacebookLogo,
		src2: MessengerLogo,
		id: 'facebook',
		name: 'Facebook',
		description:
			'Collect leads from your page or group comments and messages',
	},
	// {
	// 	src: WhatsappLogo,
	// 	id: 'whatsapp',
	// 	name: 'Business Whatsapp',
	// 	description:
	// 		'Collect phone numbers and reengage your contacts via text.',
	// },
	// {
	// 	src: WebLogo,
	// 	id: 'LiveWeb',
	// 	name: 'Live Web',
	// 	description: 'Collect leads from your web live chat and messages',
	// },
	// {
	// 	src: TwitterLogo,
	// 	id: 'twitter',
	// 	name: 'Twitter',
	// 	description:
	// 		'Collect phone numbers and reengage your contacts via text.',
	// },
	// {
	// 	src: LinkedinLogo,
	// 	id: 'linkedin',
	// 	name: 'Linkedin',
	// 	description:
	// 		'Collect phone numbers and reengage your contacts via text.',
	// },
];

export default providers;
export { conversationProvider, currentProvidedApps };
