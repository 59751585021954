import { combineReducers } from 'redux';
import auth from './auth';
// import user from './user';
import talkybots from './talkybots';
import activeTalkybot from './activeTalkybot';
import flows from './pathsflows';
import currentFlowState from './flowpathsdata';
import tenants from './tenants';
import crm from './crm';
import teamMembers from './team-members';
import members from './members';
import roles from './roles';
import accountsReducer from './accounts';
import conversations from './conversations';
import faqsReducer from './faqs';
import notifications from './notifications';
import completeUserProfile from './completeUserProfile';
import topicFlowsErrors from './topicFlowsErrors';
import crmFilters from './crm-filters';
import usage from './usage';
import stopWords from './stopWords';

const fakeReducer = (state = {}) => state;
export default combineReducers({
	auth,
	talkybots,
	activeTalkybot,
	flows,
	currentFlowState,
	tenants,
	crm: crm,
	crmFilters: crmFilters,
	teamMembers: teamMembers,
	members: fakeReducer,
	people: fakeReducer,
	currentMembers: members,
	roles,
	accounts: accountsReducer,
	conversations,
	faqs: faqsReducer,
	notifications,
	completeUserProfile,
	topicFlowsErrors,
	usage,
	stopWords,
});
