import React from 'react';
import ReactDOM from 'react-dom';
import { SnackbarProvider } from 'notistack';
import Slide from '@material-ui/core/Slide';
import App from './App';
import { registerServiceWorker } from './serviceWorker';

ReactDOM.render(
	<React.StrictMode>
		<SnackbarProvider
			maxSnack={5}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			TransitionComponent={Slide}
		>
			<App />
		</SnackbarProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
registerServiceWorker();
