import Typography from '@material-ui/core/Typography';
import { Box, Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BotBuilderImg from 'assets/images/landingImgs/BotBuilderImg.png';
import UnionBenefits from 'assets/images/landingImgs/UnionBenefits.png';
import botBuilderBG from 'assets/images/landingImgs/botBuilderBG.png';

import curated from 'assets/images/landingImgs/Curated1.png';
import utilityTools from 'assets/images/landingImgs/UtilityTools2.png';
import manageBots from 'assets/images/landingImgs/ManageBots3.png';

const useStyles = makeStyles((theme) => ({
	content: {
		padding: '3.75rem 0px',
		backgroundColor: theme.palette.background.primaryOpacity,
		backgroundImage: `url(${botBuilderBG})`,
		backgroundSize: '100% 100%',
		backgroundPosition: 'right right',
		backgroundRepeat: 'no-repeat',
		position: 'relative',
	},

	BotBuilderTitleFrame: {
		// width: "30%",
		margin: '3.75rem auto',
		backgroundColor: theme.palette.background.primaryOpacity,
	},
	BotBuilderSubTitle: {
		...theme.typography.subtitle5,
		color: theme.palette.text.primary,
		// lineHeight:0,
	},
	BotBuilderTitle: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		lineHeight: 1.5,
	},

	BotBuilderImg: {
		width: '20.8125rem',
		height: '21.6875rem',
	},
	contentHeader: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		margin: '0.9375rem 0px',
	},
	contentDesc: {
		...theme.typography.body3,
		color: theme.palette.text.primary,
	},
	unionTopImg: {
		width: '7.5rem',
		height: '7.0625rem',
		backgroundColor: 'transparent',
		// transform: "translateY(-40%)",
		position: 'absolute',
		left: '2rem',
		animation: '$slideDown 10s linear  infinite alternate-reverse',
	},
	'@keyframes slideDown': {
		from: { top: '0px' },
		to: { top: '-6.25rem' },
	},
	iconStyle: {
		width: '12.5rem',
		height: '12.5rem',
		border: '2px solid',
		borderColor: theme.palette.primary,
		borderRadius: '50%',
		textAlign: 'center',
		backgroundColor: theme.palette.common.white,
	},
	iconHeader: {
		...theme.typography.subtitle4,
		color: theme.palette.text.primary,
		margin: '1.0625rem 0px',
	},
	iconDesc: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
	iconStyleImg: {
		width: '50%',
		height: '45%',
	},
}));
const BotBuilder = () => {
	const classes = useStyles();
	return (
		<Paper className={classes.content} elevation={0}>
			<img className={classes.unionTopImg} src={UnionBenefits} alt="" />
			<Container className={classes.containerStyle} maxWidth={false}>
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
					spacing={2}
				>
					{/* Desc */}
					<Grid item md={6}>
						<Typography className={classes.contentHeader}>
							2- Bot builder application
						</Typography>
						<Typography className={classes.contentDesc}>
							Creating a chatbot requires learning of multiple
							programming languages. Along with a team that can
							work uniformly to make the things in an operating
							state. In concise it takes a lot of money, time and
							energy! Although you’ve spent hours developing your
							chatbot, there is no guarantee that your bot will
							work as per your specifications. Whereas chatbot
							builder enables its audience to leverage automation
							without coding a single line.
						</Typography>
					</Grid>
					{/* section img */}
					<Grid item md={6} align="end">
						<img
							className={classes.BotBuilderImg}
							src={BotBuilderImg}
							alt=""
						/>
					</Grid>
				</Grid>
				{/********  Bot builder********** */}
				<Paper
					className={classes.BotBuilderTitleFrame}
					align="center"
					elevation={0}
				>
					<Typography className={classes.BotBuilderSubTitle}>
						Benefits
					</Typography>
					<Typography className={classes.BotBuilderTitle}>
						Create Bot builder <br /> Without Coding
					</Typography>
				</Paper>
				{/***************  Icons ************/}
				<Grid
					container
					direction="row"
					justify="space-between"
					alignItems="center"
					spacing={2}
				>
					{/* Curated */}
					<Grid item md={3} align="center">
						<Grid
							container
							alignItems="center"
							justify="center"
							className={classes.iconStyle}
						>
							<img
								className={classes.iconStyleImg}
								src={curated}
								alt=""
							/>
						</Grid>
						<Typography className={classes.iconHeader}>
							Curated Templates
						</Typography>
						<Typography className={classes.iconDesc}>
							Get inspiration using our curated templates to
							jump-start your bot building.
						</Typography>
					</Grid>

					{/* utilityTools */}
					<Grid item md={3} align="center">
						<Grid
							container
							alignItems="center"
							justify="center"
							className={classes.iconStyle}
						>
							<img
								className={classes.iconStyleImg}
								src={utilityTools}
								alt=""
							/>
						</Grid>
						<Typography className={classes.iconHeader}>
							Utility Tools
						</Typography>
						<Typography className={classes.iconDesc}>
							Make use of tools like version control, undo-redo,
							auto layout to make you more productive.
						</Typography>
					</Grid>
					{/* manageBots */}
					<Grid item md={3} align="center">
						<Grid
							container
							alignItems="center"
							justify="center"
							className={classes.iconStyle}
						>
							<img
								className={classes.iconStyleImg}
								src={manageBots}
								alt=""
							/>
						</Grid>
						<Typography className={classes.iconHeader}>
							Manage Your Bots
						</Typography>
						<Typography className={classes.iconDesc}>
							As you grow you'll have more and more bots to
							manage. Manage them easily with our interface.
						</Typography>
					</Grid>
				</Grid>
			</Container>
		</Paper>
	);
};

export default BotBuilder;
