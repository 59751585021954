import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Typography, Button } from '@material-ui/core';
import LandingPage from 'components/landingPage';

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */

const LandingPage_Page = () => {
	return (
		<Box>
			<LandingPage />
		</Box>
	);
};

export default LandingPage_Page;
