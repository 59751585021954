import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
	Container,
	Grid,
	Paper,
	TextField,
	InputLabel,
	FormControl,
	FormControlLabel,
	FormGroup,
	Checkbox,
	Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const useStyles = makeStyles((theme) => ({
	headerSection: {
		margin: '3.125rem 0px',
		paddingBottom: '11.25rem',
	},
	inputLabel: {
		display: 'block',
		color: theme.palette.text.primary,
		...theme.typography.subtitle7,
		textTransform: 'capitalize',
		marginBottom: '0.625rem',
	},
	inputFiled: {
		outline: 'none',
		border: 0,
		font: 'normal normal normal 15px/24px Poppins',
		padding: '0.625rem 0px 0.625rem 0.9375rem',
		margin: 'auto',
		width: '100%',

		backgroundColor: '#F7F7F7 ',
		borderRadius: '5px',
		// color: "#B3B3B3",
	},
	labelAsterisk: {
		color: '#DC0000',
	},
	//   checkBox*********************
	root: {
		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		borderRadius: 3,
		width: 16,
		height: 16,
		boxShadow:
			'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
		backgroundColor: '#f5f8fa',
	},
	checkedIcon: {
		backgroundColor: '#137cbd',
		backgroundImage:
			'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
		'&:before': {
			display: 'block',
			width: 16,
			height: 16,
			backgroundImage:
				"url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
				" fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
				"1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
			content: '""',
		},
	},
	checkboxPlaceholder: {
		label: {},
		...theme.typography.body1,
		color: theme.palette.text.dark,
	},
	textArea: {
		outline: 'none',
		border: 0,
		backgroundColor: '#F7F7F7',
		padding: '20px 0px 0.625rem 1.5625rem',
		margin: 'auto',
		width: '100%',
		height: '8rem',
		resize: 'none',
		borderRadius: '5px',
		...theme.typography.body4,
	},
	buttonStyle: {
		boxShadow: '0px 3px 6px #0000005C',
		borderRadius: '4px',
		width: '50%',
		padding: '0.75rem 0px',
		backgroundColor: theme.palette.background.primary,
		'&:hover': {
			backgroundColor: theme.palette.background.primary,
		},
		color: theme.palette.text.white,
	},
	countryIconStyle: {},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));
const ContactForm = () => {
	const classes = useStyles();
	const handleSubmit = () => {};
	return (
		<Paper className={classes.headerSection} elevation={0}>
			<Container className={classes.containerStyle} maxWidth={false}>
				<form className={classes.root} noValidate autoComplete="off">
					<Grid container justify="space-around" spacing={4}>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								First name{' '}
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<input
								placeholder="First Name"
								required
								type="text"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Last Name{' '}
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<input
								placeholder="Last Name"
								required
								type="text"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Work Email{' '}
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<input
								placeholder="Work Email"
								required
								type="email"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Phone{' '}
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<PhoneInput
								country={'eg'}
								inputStyle={{
									width: '100%',
									border: 0,
									font: 'normal normal normal 15px/24px Poppins',
									padding: '22px 0px 22px 45px',
									backgroundColor: '#F7F7F7 ',
									borderRadius: '5px',
								}}
								buttonClass={classes.countryIconStyle}
							/>
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Company Name
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<input
								placeholder="Company Name"
								required
								type="text"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item xs={10} md={5}>
							<label className={classes.inputLabel}>
								Website URL
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<input
								placeholder="Website URL"
								required
								type="text"
								className={classes.inputFiled}
							/>
						</Grid>
						<Grid item md={5} xs={11}>
							<label className={classes.inputLabel}>
								Channels
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<FormGroup>
								<FormControlLabel
									className={classes.checkboxPlaceholder}
									control={
										<Checkbox
											className={classes.root}
											checkedIcon={
												<span
													className={clsx(
														classes.icon,
														classes.checkedIcon
													)}
												/>
											}
											icon={
												<span
													className={classes.icon}
												/>
											}
										/>
									}
									label="Whatsapp"
								/>
							</FormGroup>
							<FormGroup>
								<FormControlLabel
									className={classes.checkboxPlaceholder}
									control={
										<Checkbox
											className={classes.root}
											checkedIcon={
												<span
													className={clsx(
														classes.icon,
														classes.checkedIcon
													)}
												/>
											}
											icon={
												<span
													className={classes.icon}
												/>
											}
										/>
									}
									label="Facebook messenger"
								/>
							</FormGroup>

							<FormGroup>
								<FormControlLabel
									className={classes.checkboxPlaceholder}
									control={
										<Checkbox
											className={classes.root}
											checkedIcon={
												<span
													className={clsx(
														classes.icon,
														classes.checkedIcon
													)}
												/>
											}
											icon={
												<span
													className={classes.icon}
												/>
											}
										/>
									}
									label="Website"
								/>
							</FormGroup>

							<FormGroup>
								<FormControlLabel
									className={classes.checkboxPlaceholder}
									control={
										<Checkbox
											className={classes.root}
											checkedIcon={
												<span
													className={clsx(
														classes.icon,
														classes.checkedIcon
													)}
												/>
											}
											icon={
												<span
													className={classes.icon}
												/>
											}
										/>
									}
									label="Mobile app"
								/>
							</FormGroup>
						</Grid>
						<Grid item xs={5}></Grid>
						<Grid item md={5} xs={11}>
							<label className={classes.inputLabel}>
								Message{' '}
								<span className={classes.labelAsterisk}>*</span>
							</label>
							<textarea
								className={classes.textArea}
								placeholder="Message"
							></textarea>
						</Grid>
						<Grid item xs={5}></Grid>
						<Grid item md={5} xs={11}>
							<Button
								onClick={handleSubmit}
								className={classes.buttonStyle}
								variant="contained"
							>
								SEND
							</Button>
						</Grid>
						<Grid item xs={5}></Grid>
					</Grid>
				</form>
			</Container>
		</Paper>
	);
};
export default ContactForm;
