/* eslint-disable radix */
/* eslint-disable no-restricted-syntax */

// 💡💡✨✨ why 	result[' ' + item[key]]  => because javascript object [keys,values]  always make it sorted ASC
export const mapArrayToObject = (
	arr,
	notNestedAttribute = true,
	// when unsorted=true, the array is sorted  from the BackEnd
	unsorted = false
) => {
	if (!arr) return [];
	return arr.reduce((result, item) => {
		const key = [Object.keys(item)[0]];

		if (unsorted) {
			console.log('sorted from the backend👀👀');
			if (notNestedAttribute) {
				result[' ' + item[key]] = { ...item, ...item?.attributes };
			} else {
				result[' ' + item[key].id] = { ...item, ...item?.attributes };
			}
		} else {
			console.log('Javascript sort 👀👀');
			if (notNestedAttribute) {
				result[item[key]] = { ...item, ...item?.attributes };
			} else {
				result[item[key].id] = { ...item, ...item?.attributes };
			}
		}

		return result;
	}, {});
};

// export const mapFlowDataToDrawItOnCanvas = (currentFlowState) => {
// 	if (!currentFlowState) return [];
// 	return currentFlowState.reduce((result, item, i) => {
// 		const key = [Object.keys(item)[0]];
// 		const Obj = {};
// 		if (item[key]?.path_type === 'main') {
// 			Obj.tube = item?.nodes;
// 		} else {
// 			Obj[`trash_${i}`] = item?.nodes;
// 		}

// 		return [...result, Obj];
// 	}, []);
// };

export const mapFlowDataToSaveItOnBackend = (flowToSave) => {
	const mappedDataToSend = {
		paths: [{ path_type: 'main', nodes: flowToSave?.data }],
	};
	return mappedDataToSend;
};
// export const generateLines = (currentFlowStateData) => {
// 	const linesContainer = [];
// 	currentFlowStateData?.forEach((tube) => {
// 		// eslint-disable-next-line no-unused-vars
// 		for (const [key, value] of Object.entries(tube)) {
// 			const lastIndex = value.length - 1;
// 			// eslint-disable-next-line no-loop-func
// 			value.forEach((node, index) => {
// 				const nextIndex = index + 1;
// 				if (nextIndex <= lastIndex) {
// 					linesContainer.push({
// 						start: `${node.id}`,
// 						end: `${parseInt(value[nextIndex].id)}`,
// 						headSize: 0,
// 						strokeWidth: 2,
// 					});
// 				}
// 			});
// 		}
// 	});
// 	return linesContainer;
// };

/**
 * applicationable_type oneOf BotBuilderApp - ConversationsApp - CrmApp
 * @param state
 * @param applicationable_type PascalCase
 * @param getApplicationId
 * @returns {null|*}
 */

export const getAppId = (
	state,
	applicationable_type,
	getApplicationId = false
) => {
	if (!state || !state.activeTalkybot) return null;
	const installedApplications = state?.activeTalkybot?.installed_applications;
	const app = installedApplications.find(
		({ attributes }) =>
			attributes?.applicationable_type === applicationable_type
	);
	return getApplicationId ? app?.id : app?.attributes?.applicationable_id;
};

/**
 * Fetch Current Talkybot Id
 * @param state
 * @returns {null|*}
 */

export const getActiveTalkybotId = (state) => {
	if (!state || !state.activeTalkybot) return null;
	return state?.activeTalkybot?.id;
};

// export const updateNodeAndReturnNewFlowState = (
// 	data,
// 	columnName,
// 	updatedGeneratedNode
// ) => {
// 	const newTargetFlowState = data.map((flowState) => {
// 		const oldTargetArr = flowState[columnName];
// 		if (oldTargetArr) {
// 			const newTargetArr = [...oldTargetArr];
// 			const indexOfNodeToUpdate = newTargetArr.findIndex(
// 				(node) => node.id === updatedGeneratedNode.id
// 			);
// 			newTargetArr[indexOfNodeToUpdate] = {
// 				...updatedGeneratedNode,
// 			};
// 			return {
// 				[columnName]: [...newTargetArr],
// 			};
// 		}
// 		return flowState;
// 	});

// 	return newTargetFlowState;
// };

/** TODO :: REMOVE OBJECT WHEN LAST NODE DELETED */
// export const deleteNodeAndReturnNewState = (data, nodeId, columnName) => {
// 	const newTargetFlowState = data.map((flowState) => {
// 		const oldTargetArr = flowState[columnName];
// 		if (oldTargetArr) {
// 			const newTargetArr = oldTargetArr.filter(
// 				(node) => node.id !== Number(nodeId)
// 			);
// 			// check if filtered array has no nodes
// 			if (newTargetArr.length === 0 && columnName === TUBE) {
// 				const generatedNode =
// 					generateGuideMessageScheme(GUIDE_MESSAGE_TEXT);
// 				return {
// 					[columnName]: [
// 						{
// 							...generatedNode,
// 							id: 1,
// 						},
// 					],
// 				};
// 			}
// 			// then add guide message to this array
// 			return {
// 				[columnName]: [...newTargetArr],
// 			};
// 		}
// 		return flowState;
// 	});

// 	return newTargetFlowState;
// };
