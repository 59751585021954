import React from 'react';
import {
	Paper,
	MuiThemeProvider,
	createMuiTheme,
	ThemeProvider,
	makeStyles,
	withStyles,
} from '@material-ui/core';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

import { Offline, Online, Detector } from 'react-detect-offline';
import NoInternetConnection from 'components/NoInternetConnection/Component';
import NotFoundPage from 'components/404/Component';
import theme from './theme';
import Footer from './shared/footer/footer';
import Home from './home';
import About from './about';
import Features from './features';
import Pricing from './pricing';
import Contact from './contact';
import Policy from './policy';
import Navbar from './shared/navbar';

const useStyles = makeStyles(() => ({
	content: {},
}));

export default () => {
	const classes = useStyles();
	const { path, url } = useRouteMatch();
	return (
		<ThemeProvider theme={theme}>
			<Paper elevation={0}>
				<Navbar />
				<Online polling={{ interval: 10000 }}>
					<Paper className={classes.content} elevation={0}>
						<Switch>
							<Route path={path} exact component={Home} />
							<Route path={`${path}/about`} component={About} />
							<Route
								path={`${path}/features`}
								component={Features}
							/>
							<Route
								path={`${path}/pricing`}
								component={Pricing}
							/>
							<Route
								path={`${path}/contact`}
								component={Contact}
							/>
							<Route path={`${path}/policy`} component={Policy} />
							<Route
								path="*"
								component={() => <NotFoundPage />}
							/>
						</Switch>
					</Paper>
					<Footer />
				</Online>
				<Offline polling={{ interval: 10000 }}>
					<NoInternetConnection />
				</Offline>
			</Paper>
		</ThemeProvider>
	);
};
