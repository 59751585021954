import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'helpers/axios';
import { makeStyles, Typography, Paper, Divider } from '@material-ui/core';
import DBconversationBox1Img from 'assets/images/DBconversationBox1Img.svg';
import DBconversationBox2Img from 'assets/images/DBconversationBox2Img.svg';
import TopicBox from './FAQRow';

const useStyles = makeStyles((theme) => ({
	mainHeader: {
		fontFamily: 'Poppins !important',
		fontSize: '1.375rem !important',
		fontWeight: 'semi-bold !important',
		color: '#170A38',
		textTransform: 'capitalize',
		marginLeft: '1.875rem',
	},
	frequentQuestionsWrapper: {
		borderRadius: '1.25rem',
		height: '100%',
		paddingTop: '1.875rem',
	},
}));
const MostBotUsed = ({ talkyBotId, mostFrequentQuestions }) => {
	const classes = useStyles();

	const largerThanThree = Object.entries(mostFrequentQuestions).length > 3;

	// eslint-disable-next-line consistent-return
	const renderMostFrequentQuestions = () => {
		if (Object.entries(mostFrequentQuestions).length > 0) {
			return largerThanThree
				? Object.entries(mostFrequentQuestions)
						.slice(0, 3)
						.map(([key, value]) => (
							<>
								<TopicBox
									img={DBconversationBox2Img}
									desc={key}
									numMessage={value}
								/>
								<Divider />
							</>
						))
				: Object.entries(mostFrequentQuestions).map(([key, value]) => (
						<>
							<TopicBox
								img={DBconversationBox2Img}
								desc={key}
								numMessage={value}
							/>
							<Divider />
						</>
				  ));
		}
	};

	return (
		<Paper elevation={0} className={classes.frequentQuestionsWrapper}>
			<Typography className={classes.mainHeader}>
				Most Frequent Questions
			</Typography>
			{Object.entries(mostFrequentQuestions)?.length > 0 ? (
				<>{renderMostFrequentQuestions()}</>
			) : (
				<Typography
					style={{
						marginTop: '35%',
						marginLeft: '45%',
						fontSize: '1.2rem',
						fontFamily: 'Poppins !important',
					}}
				>
					No data
				</Typography>
			)}
		</Paper>
	);
};

const mapStateToProps = (state) => ({
	talkyBotId: state?.activeTalkybot?.id,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MostBotUsed);
