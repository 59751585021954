import {
	LOG_USER_IN_SUCCESS,
	REGISTER_USER_SUCCESS,
	LOG_USER_OUT,
	GET_CURRENT_USER,
	EDIT_CURRENT_USER_PROFILE_SUCCESS,
	EDIT_PASSWORD_SUCCESS,
	CLEAR_IS_AUTHENTICATED,
	UPLOAD_AVATAR_FAIL,
	UPLOAD_AVATAR_SUCCESS,
} from 'store/actions/types/auth';

const initialAuthState = {
	isAuthenticated: false,
	user: null,
};
export default (state = initialAuthState, { type, payload }) => {
	switch (type) {
		case UPLOAD_AVATAR_SUCCESS:
			return {
				...state,
				user: {
					...state.user,
					attachment_url: payload.attachment_url,
				},
			};

		case UPLOAD_AVATAR_FAIL:
			return {
				...state,
				user: {
					...state.user,
					error: 'Upload avatar failed',
				},
			};

		case LOG_USER_IN_SUCCESS: {
			return {
				isAuthenticated: true,
				user: payload,
			};
		}
		case REGISTER_USER_SUCCESS: {
			return {
				...state,
			};
		}

		case LOG_USER_OUT: {
			return initialAuthState;
		}
		case CLEAR_IS_AUTHENTICATED: {
			return {
				...state,
				isAuthenticated: false,
				user: null,
			};
		}

		case GET_CURRENT_USER: {
			return {
				isAuthenticated: true,
				user: payload,
			};
		}
		case EDIT_CURRENT_USER_PROFILE_SUCCESS: {
			return {
				...state,
				user: payload,
				isAuthenticated: true,
			};
		}
		case EDIT_PASSWORD_SUCCESS: {
			return {
				...state,
				user: payload,
				isAuthenticated: true,
			};
		}
		default:
			return state;
	}
};
