/** ================ Bot actions Types =================== */
export const GET_ALL_BOTS_REQUEST = 'GET_ALL_BOTS_REQUEST';
export const GET_ALL_BOTS = '[bot] get all bots success';
export const GET_ALL_BOTS_FAIL = '[bot] get all bots fail';

export const GET_BOT_DETAILS = '[bot] get bot details success';
export const GET_BOT_DETAILS_FAIL = '[bot] get bot details fail';

export const CREATE_BOT = '[bot] create bot success';
export const CREATE_BOT_FAIL = '[bot] create bot fail';

export const EDIT_BOT = '[bot] edit bot success';
export const EDIT_BOT_FAIL = '[bot] edit bot success';

export const DELETE_BOT = '[bot] delete bot success';
export const DELETE_BOT_FAIL = '[bot] delete bot fail';

export const PUBLISH_BOT = 'PUBLISH_BOT';
export const ACTIVATE_BOT = 'ACTIVATE_BOT';
export const DEACTIVATE_BOT = 'DEACTIVATE_BOT';
export const UNINSTALL_APP = 'UNINSTALL_APP';
export const INSTALL_APP = 'INSTALL_APP';
