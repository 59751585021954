import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import DBconversationCardUpImg from 'assets/images/DBconversationCardUpImg.svg';
import DBconversationCardDownImg from 'assets/images/DBconversationCardDownImg.svg';
import FBLogo from 'assets/images/FBLogo.svg';
import TotalCard from 'components/dashboard/shared/totalCard';
import Chart from './chart';

const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		backgroundColor: '#fff',
		border: ' 1px #F2F2F2',
		borderRadius: '1.25rem',
		padding: '0.875rem 1.625rem',
	},
	mainHeader: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.375rem !important',
		color: ' #170A38',
		textTransform: 'capitalize',
		fontWeight: 'semi-bold',
	},
	totalNum: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.5rem',
		fontWeight: 'semi-bold',
		color: ' #170A38',
		marginBottom: '2rem',
	},
	totalDesc: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1rem',
		fontWeight: 400,
		color: ' #202020',
		margin: '0.9375rem 0px',
		textTransform: 'capitalize',
	},
	cardMargin: {
		marginTop: '0.9375rem',
	},
	socialMediaCard: {
		height: '2.5rem',
		borderRadius: '0.5rem',
		border: '1px solid #eee',
		display: 'flex',
		direction: 'row',
	},
}));
const ConversationChart = ({ conversationsRepliesData }) => {
	const classes = useStyles();

	// const getTotalBotRepliesCount = (data) => {
	// 	return data?.summary.reduce((acc, curr) => {
	// 		return acc + curr.bot_replies;
	// 	}, 0);
	// };

	// const getTotalTeamRepliesCount = (data) => {
	// 	return data?.summary.reduce((acc, curr) => {
	// 		return acc + curr.team_replies;
	// 	}, 0);
	// };

	return (
		<Paper elevation={0} className={classes.sectionStyle}>
			<Grid container spacing={2} xs={12}>
				<Grid item xs={6}>
					<Typography className={classes.mainHeader}>
						Conversation Replies
					</Typography>
				</Grid>
				<Grid item xs={6}>
					<Typography className={classes.totalNum}>
						{conversationsRepliesData?.all_replies || 0}
					</Typography>
				</Grid>
			</Grid>
			<Grid container justify="center" alignItems="flex-start">
				<Grid item md={6}>
					{/* <Typography className={classes.totalDesc}>
						Total conversations
					</Typography>
					<TotalCard
						cardHeader="bot builder messages"
						totalElementsImg={DBconversationCardUpImg}
						totalNum="170"
						arrowNum="+ 9.3%"
						arrowColor="#015F3E"
						border="true"
						size="sm"
					/> */}
					{/* <div>
						<br />
					</div>
					<TotalCard
						cardHeader="team members messages"
						totalElementsImg={DBconversationCardDownImg}
						totalNum="76"
						arrowNum="- 6.3%"
						arrowColor="#f41515"
						border="true"
						size="sm"
					/> */}
					<Paper elevation={0} className={classes.socialMediaCard}>
						<img
							src={FBLogo}
							alt="social"
							style={{
								marginRight: '1.625rem',
								width: '1.8rem',
								height: '1.8rem',
								marginLeft: '0.625rem',
								marginTop: '0.15rem',
							}}
						/>
						<div
							style={{
								marginRight: '5rem',
								marginTop: '0.4rem',
								textTransform: 'capitalize',
							}}
						>
							{`${
								conversationsRepliesData?.summary[0]
									?.provider || 'facebook'
							} messages`}
						</div>

						<div
							style={{
								color: 'red',
								marginTop: '0.4rem',
							}}
						>
							{conversationsRepliesData?.summary[0]
								?.all_replies || 0}
						</div>
					</Paper>
				</Grid>
				<Grid item md={6}>
					<Chart
						teamRepliesCount={
							conversationsRepliesData?.team_member_replies || 0
						}
						botRepliesCount={
							conversationsRepliesData?.bot_replies || 0
						}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default ConversationChart;
