/* eslint-disable camelcase */
import {
	CREATE_BOT,
	DELETE_BOT,
	UNINSTALL_APP,
	INSTALL_APP,
	GET_ALL_BOTS,
	GET_ALL_BOTS_REQUEST,
	GET_ALL_BOTS_FAIL,
	CREATE_BOT_FAIL,
} from 'store/actions/types/talkybots';

const initialState = {
	bots: null,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_BOTS: {
			const { talkybots } = payload;
			return {
				...state,
				bots: {
					...talkybots,
				},
			};
		}

		case CREATE_BOT: {
			const { talkybot } = payload;
			return {
				...state,
				bots: {
					...state.bots,
					[talkybot.id]: {
						...talkybot,
					},
				},
			};
		}

		case DELETE_BOT: {
			const { talkybotId } = payload;
			const newState = { ...state };
			delete newState.bots[talkybotId];
			return {
				...newState,
			};
		}

		case UNINSTALL_APP: {
			const { talkybotId, application } = payload;
			const newState = { ...state };

			const newInstalledApps = newState?.bots[
				talkybotId
			].installed_applications.filter(
				(app) => app?.attributes?.applicationable_type !== application
			);
			newState[talkybotId].installed_applications = newInstalledApps;
			return newState;
		}

		case INSTALL_APP: {
			const { talkybotId, application } = payload;
			const newState = { ...state };
			const previousInstalledApps =
				newState.bots[talkybotId].attributes.installed_applications;
			const newInstalledApps = [...previousInstalledApps, application];
			newState.bots[talkybotId].attributes.installed_applications =
				newInstalledApps;
			return newState;
		}

		default:
			return state;
	}
};
