/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import Loading from 'components/common/waitingReqView/loading';
import { Grid, makeStyles, Paper, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Error from 'components/common/waitingReqView/error';
import useSetUiLoadingError from 'hooks/useSetUiLoadingError';
import MeterInfo from './MeterInfo/Component';
import PlanDetails from './PlanDetails';

const Usage = ({
	getCurrentUsage,
	meterInfo,
	planDetails,
	error,
	loading,
	getAllBots,
	talkybots,
	tenantId,
	userID,
}) => {
	const history = useHistory();
	const classes = useStyles();
	const [isLoading, successData, backendError, setUiLoadingError] =
		useSetUiLoadingError();
	useEffect(() => {
		getCurrentUsage(tenantId);
		getAllBots(setUiLoadingError);
	}, []);

	console.log('current plan details', planDetails);

	return (
		<Paper elevation={0} className={classes.root}>
			{loading ? (
				<Loading />
			) : error ? (
				<Error error={error} />
			) : (
				<Grid container justifyContent="space-between" spacing={10}>
					<Grid item xs={9}>
						<Typography className={classes.workSpaceHeader}>
							Organization Usage
						</Typography>
					</Grid>
					<Grid item xs={3}>
						<Paper className={classes.upgradeSection}>
							<div>
								Current Plan :
								<span className={classes.plan}>
									{planDetails?.title}
								</span>
								{/* upgrade section */}
								<Typography className={classes.expired}>
									{`Expired On   ${
										planDetails?.next_date_for_paying?.split(
											' '
										)[0]
									}`}
								</Typography>
							</div>
							<div>
								<Button
									className={classes.upgradeButton}
									onClick={() => {
										history.push(
											`/users/${userID}/subscription?`
										);
									}}
								>
									Upgrade
								</Button>
							</div>
						</Paper>
					</Grid>
					<Grid item xs={12}>
						<Paper elevation={0} className={classes.workspace}>
							{/* <Typography className={classes.workSpaceHeader}>
								Organization Usage
							</Typography> */}
							<MeterInfo
								meterInfo={meterInfo}
								talkybots={talkybots}
							/>
							{/* bots cards */}
						</Paper>
					</Grid>

					{/* plan details */}
					{/* <Grid item md={3} xs={5}>
						<PlanDetails planDetails={planDetails} />
					</Grid> */}
				</Grid>
			)}
		</Paper>
	);
};

export default Usage;

const useStyles = makeStyles(() => ({
	root: {
		backgroundColor: 'transparent',
	},
	workspace: {
		backgroundColor: 'transparent',
	},
	workSpaceHeader: {
		fontSize: '1.125rem',
		fontFamily: 'Poppins',
		fontWeight: 'bold',
		color: '#000000',
		textTransform: 'capitalize',
		padding: '3.625rem 0rem',
	},
	upgradeSection: {
		marginTop: '2.6rem',
		border: '#6318c3 solid 1px',
		height: '4rem',
		paddingTop: '0.7rem',
		paddingLeft: '1rem',
		paddingBottom: '0.5rem',
		display: 'flex',
		direction: 'row',
		backgroundColor: 'transparent',
		position: 'relative',
		width: '22rem',
	},
	plan: {
		color: '#6318c3',
		marginLeft: '0.25rem',
		textTransform: 'capitalize',
	},
	expired: {
		color: 'red',
		fontSize: '0.8rem',
	},
	upgradeButton: {
		color: 'white',
		backgroundColor: '#6318c3',
		'&:hover': {
			backgroundColor: '#6318c3',
		},
		position: 'absolute',
		right: '0.6rem',
	},
}));
