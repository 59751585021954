export const STARTING_MESSAGE = 'starting_message';
export const TEXT_MESSAGE = 'text_message';
export const SENDING_MESSAGE_WITH_OPTIONS = 'sending_message_with_options';
export const END_WITH_MESSAGE = 'end_with_message';
export const NOTIFY_TEAM_MEMEBER_TO_TAKE_OVER =
	'notify_team_member_to_take_over';
export const TRIGGER_ANOTHER_TOPIC_FLOW = 'trigger_another_topic_flow';

export const SET_USER_ATTRIBUTE = 'set_user_attribute';
export const SAVE_USER_ATTRIBUTE = 'save_user_attribute';
export const GUIDE_MESSAGE = 'guide_message';
export const ASSIGN_TO_TEAM_MEMBER = 'assign_to_team_member';
