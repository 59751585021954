import { Paper, Typography, makeStyles, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Doughnut } from 'react-chartjs-2';

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: true,
			maxWidth: 100,
			position: 'bottom',
			labels: {
				boxWidth: 15,
				boxHeight: 15,
				padding: 20,
				color: '#464255',
			},
		},
		tooltip: {
			usePointStyle: true,
			bodyFont: {
				// size: 20,
			},
			callbacks: {
				labelPointStyle: function (context) {
					return {
						pointStyle: 'triangle',
						rotation: 0,
					};
				},
			},
		},
	},
};
const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		padding: '2.0625rem 1.875rem',
		borderRadius: '1.25rem',
		backgroundColor: '#FDFDFD',
		border: '1px #F2F2F2',
		minHeight: '43.825rem',
		maxHeight: '43.825rem',
	},
	header: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.375rem !important',
		color: ' #170A38',
		textTransform: 'capitalize',
		marginBottom: '3.125rem',
	},
	chartStyle: {
		height: '25rem',
	},
	footerDesc: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.375rem !important',
		fontWeight: 'medium',
		color: '#464255',
		textTransform: 'capitalize',
	},
	footerNum: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.375rem !important',
		fontWeight: 'bold',
		color: '#464255',
	},
	defaultGreyChart: {
		width: '15rem',
		height: '15rem',
		borderRadius: '50%',
		border: '3rem solid #808080',
		marginTop: '7rem',
	},
}));
const CrmContacts = ({ talkyBotId, clientSources }) => {
	const classes = useStyles();

	const sourcesLabels = Object.keys(clientSources?.sources);
	const sourcesValues = Object.values(clientSources?.sources);
	const sources = sourcesLabels?.map((source, index) => {
		return `${sourcesValues[index] || 0} ${source?.toUpperCase()}`;
	});

	useEffect(() => {}, []);

	const data = {
		labels: [...sources],
		datasets: [
			{
				label: '# of Votes',
				data: [...sourcesValues],
				backgroundColor: ['#00ADA3', '#374C98'],
				rotation: 360,
				borderWidth: 5,
			},
		],
	};

	return (
		<Paper elevation={0} className={classes.sectionStyle}>
			<Typography className={classes.header}>Customers Source</Typography>
			<Grid item xs={3}>
				{' '}
			</Grid>
			<Grid item xs={9}>
				<Typography className={classes.footerDesc}>
					total contacts
				</Typography>
				<Typography className={classes.footerNum}>
					{clientSources?.people_count || 0}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				{!clientSources?.sources?.facebook &&
				!clientSources?.sources?.self_imported ? (
					<div className={classes.defaultGreyChart}>{/*  */}</div>
				) : (
					<div className={classes.chartStyle}>
						<Doughnut
							height={1000}
							width={1000}
							data={data}
							options={options}
						/>
					</div>
				)}
			</Grid>
		</Paper>
	);
};

const mapStateToProps = (state) => ({
	talkyBotId: state?.activeTalkybot?.id,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CrmContacts);
