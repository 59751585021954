import axios from 'helpers/axios';
import {
	ADD_NEW_ACCOUNT,
	GET_ALL_ACCOUNTS,
	REMOVE_ACCOUNT,
	LIST_ACCOUNT_CHANNELS,
} from 'store/actions/types/accounts';

/**
 * Add new facebook account to tenant
 * @param code
 * @param history
 * @returns {(function(*): Promise<void>)|*}
 */
export const addNewAccountAction = ({ code, history }) => {
	return async (dispatch) => {
		try {
			const response = await axios.post('/api/accounts', { code });
			const { data } = response.data;
			dispatch({
				type: ADD_NEW_ACCOUNT,
				payload: {
					id: data.id,
					name: data.attributes.name,
					avatar: data.attributes.avatar,
					tenant: data.attributes.tenant,
					channels: data.attributes.channels,
				},
			});
			history.push('/accounts');
		} catch (e) {
			// handle error
		}
	};
};

/**
 * Fetch all tenant facebook accounts
 * @returns {(function(*): Promise<void>)|*}
 */
export const getAllAccountsAction = () => {
	return async (dispatch) => {
		try {
			const response = await axios.get('/api/accounts');
			const { data } = response.data;
			const accounts = data.map((account) => ({
				id: account.id,
				name: account.attributes.name,
				avatar: account.attributes.avatar,
				channels: account.attributes.channels,
				tenant: account.attributes.tenant,
				provider: account.attributes.provider,
			}));
			dispatch({
				type: GET_ALL_ACCOUNTS,
				payload: {
					accounts: accounts,
				},
			});
		} catch (e) {
			// handle error
		}
	};
};

export const removeAccountAction = (accountId, enqueueSnackbar) => {
	return async (dispatch) => {
		try {
			const res = await axios.delete(`/api/accounts/${accountId}`);
			console.log('res from remove account ', res);
			enqueueSnackbar(`Disconnect Acount successfully`, {
				variant: 'success',
				autoHideDuration: 2000,
			});
			dispatch(getAllAccountsAction());
			dispatch({
				type: 'REMOVE_ACCOUNT',
				payload: {
					accountId,
				},
			});
		} catch (e) {
			// handle error
			enqueueSnackbar(`Disconnect Acount failed`, {
				variant: 'error',
				autoHideDuration: 2000,
			});
		}
	};
};

export const listAccountChannels = (accountId) => {
	return async (dispatch) => {
		try {
			const res = await axios.get(`/api/accounts/${accountId}/channels`);
			console.log('res from list account channels', res.data);
			dispatch({
				type: LIST_ACCOUNT_CHANNELS,
			});
			dispatch(getAllAccountsAction());
		} catch (e) {
			// handle error
		}
	};
};
