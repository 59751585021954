import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	headerSection: {
		marginTop: '9.375rem',
		padding: '4.375rem',
		textAlign: 'center',
		backgroundColor: theme.palette.background.primaryOpacity,
	},
	PolicyTitle: {
		...theme.typography.title,
		color: theme.palette.text.primary,
	},
	PolicySubTitle: {
		...theme.typography.subtitle7,
		color: theme.palette.text.primary,
	},
	content: {
		padding: '3.75rem 0px',
	},
	PolicyImg: {
		width: '25.8125rem',
		height: '25.6875rem',
	},
	contentHeader: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		margin: '15px 0px',
	},
	contentDesc: {
		...theme.typography.body2,
		color: theme.palette.text.primary,
		marginBottom: theme.spacing(10),
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(60),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
		marginBottom: theme.spacing(30),
	},
}));
const PolicyHeader = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0}>
			<Paper className={classes.headerSection} elevation={0}>
				<Typography className={classes.PolicyTitle}>
					PRIVACY POLICY
				</Typography>
				{/* <Typography className={classes.PolicySubTitle}>
					home / Policy
				</Typography> */}
			</Paper>

			<Paper className={classes.content} elevation={0}>
				<Container className={classes.containerStyle} maxWidth={false}>
					<Typography className={classes.contentHeader}>
						Talkybot Saas Chatbot Platform
					</Typography>
					<Typography className={classes.contentDesc}>
						Talkybot is committed to protecting the privacy of our
						Users. We have prepared the Privacy to describe to you
						our practices regarding the Personal Data we collect
						from Users of our Sites and Services, including but not
						limited to mobile apps, chatbots/chat applications. At
						Talkybot we consider the privacy of the visitors to our
						website and the security of their personal information,
						to be extremely important. This Privacy Policy document
						describes, in detail, the types of personal information
						we collect and record on the Site, and how we use and
						disclose personal information.
					</Typography>

					<Typography className={classes.contentHeader}>
						What Information Does Talkybot Collect?
					</Typography>
					<Typography className={classes.contentDesc}>
						During registering on our site, you may be asked to
						enter your name, email address or other details to help
						you with your experience. Other peripheral information
						like website, time-zone, IP address , country, other
						details might be collected. . Our servers may
						automatically record certain information about how you
						use our Site (we refer to this information as “Log
						Data“), including both Customers and casual
						visitors.Talkybot use various technologies to collect
						and store information when you use our Services, and
						this may include using cookies and similar tracking
						technologies on our Site.
					</Typography>

					<Typography className={classes.contentHeader}>
						Do Talkybot Share Your Information ?
					</Typography>
					<Typography className={classes.contentDesc}>
						We do not share or sell the personal information that
						you provide us with other organizations without your
						express consent except for Talkybot business ,
						development needs ,{' '}
						<b>Affiliated Businesses or business transfer needs.</b>
					</Typography>

					<Typography className={classes.contentHeader}>
						Cookie Usage
					</Typography>
					<Typography className={classes.contentDesc}>
						We use cookies to understand and save user's preferences
						for future visits. You can choose to turn off all
						cookies usage through the browser’s specified settings.
						If you disable cookies, some features might not function
						as expected. It won't affect the user's experience that
						make your site experience more efficient.
					</Typography>

					<Typography className={classes.contentHeader}>
						How Is Data Protected On Your Systems
					</Typography>
					<Typography className={classes.contentDesc}>
						We take a number of steps to ensure customer’s data is
						protected. Your account is protected by a password for
						your privacy and security. If you access your account
						via Facebook or another third party site or service, you
						may have additional or different sign-on protections via
						that third party site or service.
					</Typography>

					<Typography className={classes.contentHeader}>
						Request For Data Deletion
					</Typography>
					<Typography className={classes.contentDesc}>
						To raise a request for data deletion, customers can send
						an email to support@talkybot.com . Once the request is
						raised, all customer data will be deleted within 14
						working days of the receipt of request.
					</Typography>

					<Typography className={classes.contentHeader}>
						How Long Is Data Kept On The Systems
					</Typography>
					<Typography className={classes.contentDesc}>
						We will store personal information for as long as
						necessary to fulfil the purposes for which we collect
						the personal information, in accordance with our legal
						obligations and legitimate business interests.
					</Typography>

					<Typography className={classes.contentHeader}>
						Privacy Policy Updates
					</Typography>
					<Typography className={classes.contentDesc}>
						Changes to this Privacy Policy will be made when
						required in response to changing legal, technical or
						business developments. This Privacy Policy is subject to
						occasional revision, and if we make any material changes
						in the way we use your Personal Data, we will notify you
						by sending you an e-mail and/or by prominently posting
						notice of the changes on our Site.
					</Typography>

					<Typography className={classes.contentHeader}>
						Have Questions About The Policy ?
					</Typography>
					<Typography className={classes.contentDesc}>
						For more details, email us at <b>info@talkbot.com</b>
					</Typography>
				</Container>
			</Paper>
		</Paper>
	);
};

export default PolicyHeader;
