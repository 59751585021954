import axios from 'axios';

const talkybotAxios = axios.create({
	baseURL:
		process.env.NODE_ENV !== 'development'
			? process.env.REACT_APP_SERVER_PRODUCTION_URL
			: process.env.REACT_APP_SERVER_DEVELOPMENT_URL,
	// validateStatus: () => true,
});

export default talkybotAxios;
