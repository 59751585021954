// TODO: is to make an api call to fetch theme object dynamically

import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	spacing: (factor) => `${0.5 * factor}rem`,
	palette: {
		primary: {
			main: '#6418C3',
		},
		secondary: {
			main: '#A157FF',
		},
		neutral: {
			main: '#5C6AC4',
		},
	},
	typography: {
		fontFamily: 'Cairo-Bold,Poppins',
		body1: {
			// ...
		},
		body2: {
			fontFamily: 'Cairo-Regular',
			fontSize: '20px',
		},
	},
});
export const getContainerSize = () => {
	return 'xl';
};
export default theme;
