import Typography from '@material-ui/core/Typography';
import { Box, Container, Grid, Paper, Slider } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import calcImage from 'assets/images/landingImgs/calcImg.png';
import { useState } from 'react';

const useStyles = makeStyles((theme) => ({
	headerFrame: {
		textAlign: 'center',
		color: theme.palette.text.primary,
		margin: '0px auto 15.625rem auto',
		// maxWidth: "40%",
	},
	headerText: {
		...theme.typography.subtitle3,
		marginBottom: '0.625rem',
	},
	headerDesc: {
		...theme.typography.body4,
		color: theme.palette.text.dark,
	},
	calcContentFrame: {},
	calcBox: {
		boxShadow: '0px 3px 6px #00000040',
		border: ' 0.5px solid',
		borderRadius: '1.625rem 0px 1.5625rem 0px',
		padding: '0.9375rem',
		position: 'relative',
		minHeight: '7.5rem',
		// minWidth:"100%",
	},
	calcBoxCaption: {
		...theme.typography.subtitle7,
		textTransform: 'capitalize',
	},
	calcBoxCaptionNumFrame: {
		borderBottom: '1px solid #B7B7B7',
	},
	calcBoxCaptionNum: {
		...theme.typography.subtitle3,
	},
	calcBoxCaptionNumLabel: {
		...theme.typography.subtitle6,
		color: '#B7B7B7',
	},
	calcImage: {
		width: '12.9375rem',
		height: '13.1875rem',
		position: 'absolute',
		top: 0,
		transform: 'translate(-50% ,-100% )',
	},
	sliderStyle: {
		marginTop: '3.125rem',
	},
	sliderMark: {
		...theme.typography.subtitle3,
		color: theme.palette.text.dark,
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(5),
	},
}));

const CustomSlider = withStyles({
	root: {
		color: '#52af77',
		height: '1.875rem',
	},
	thumb: {
		height: 40,
		width: 40,
		backgroundColor: '#43348B ',
		//   border: '2px solid currentColor',
		transform: 'translateY(-30%)',
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	mark: {},
	markLabel: {
		marginTop: '1.25rem',
		color: '#B7B7B7',
	},
	valueLabel: {
		left: 'calc(-50% + 4px)',
	},
	markLabelActive: {
		// color:'#ECECEC',
	},
	track: {
		height: '12px',
		borderRadius: 4,
		backgroundColor: '#ececec',
	},
	rail: {
		height: '12px',
		borderRadius: 4,
		backgroundColor: '#ECECEC',
	},
})(Slider);

const PricingCalc = () => {
	const classes = useStyles();
	const [sliderValue, setSliderValue] = useState(100);
	const sliderChange = (undefined, value) => {
		setSliderValue(value);
	};
	const marks = [
		{ value: 100, label: '100k' },
		{ value: 200, label: '200k' },
		{ value: 300, label: '300k' },
		{ value: 400, label: '400k' },
		{ value: 500, label: '500k' },
	];
	return (
		<Paper elevation={0}>
			<Container className={classes.containerStyle} maxWidth={false}>
				<Paper className={classes.headerFrame} elevation={0}>
					<Typography className={classes.headerText}>
						Calculate Your Monthly Plan
					</Typography>
					<Typography className={classes.headerDesc}>
						Lorem ipsum dolor sit amet, consectetuer adipiscing
						elit,
						<br /> sed diam nonummy nibh euismod tincidunt ut
						laoreet dolore
					</Typography>
				</Paper>
				<Paper elevation={0}>
					<Grid
						container
						className={classes.calcContentFrame}
						justify="center"
						alignItems="center"
						align="left"
					>
						<Grid item xs={5} md={3}>
							<Box className={classes.calcBox}>
								<Typography className={classes.calcBoxCaption}>
									How many Contacts do you expect to engage ?
								</Typography>

								<Grid
									container
									justify="space-between"
									alignItems="center"
									className={classes.calcBoxCaptionNumFrame}
								>
									<Typography
										align="left"
										className={classes.calcBoxCaptionNum}
									>
										{sliderValue}
									</Typography>
									<Typography
										align="right"
										className={
											classes.calcBoxCaptionNumLabel
										}
									>
										contacts
										{/* Max */}
									</Typography>
								</Grid>
							</Box>
						</Grid>
						<Grid align="center" item xs={1}>
							=
						</Grid>
						<Grid align="center" item xs={5} md={3}>
							<Box className={classes.calcBox}>
								<Typography
									align="center"
									className={classes.calcBoxCaptionNum}
								>
									$ 180
								</Typography>
								<Typography align="center">pro</Typography>
								<img
									align="center"
									className={classes.calcImage}
									src={calcImage}
									alt=""
								/>
							</Box>
						</Grid>
						<Grid md={12}></Grid>
						<Grid
							className={classes.sliderStyle}
							item
							xs={11}
							md={7}
						>
							<CustomSlider
								marks={marks}
								step={10}
								max={500}
								min={100}
								onChange={sliderChange}
							/>
						</Grid>
					</Grid>
				</Paper>
			</Container>
		</Paper>
	);
};

export default PricingCalc;
