import { useState } from 'react';

// ui loading
const useSetUiLoadingError = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [successData, setSuccessData] = useState(null);
	const [backendError, setBackendError] = useState(null);
	const setUiLoadingError = ({ loading, success, error, code }) => {
		// reset State
		if (backendError) setBackendError(null);
		setIsLoading(loading);
		setSuccessData(success);
		if (!error) return;
		setBackendError({ message: error, code });
	};
	return [
		isLoading,
		successData,
		backendError,
		setUiLoadingError,
		setBackendError,
	];
};
export default useSetUiLoadingError;
