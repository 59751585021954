import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import aboutImg from 'assets/images/landingImgs/aboutImg.png';

const useStyles = makeStyles((theme) => ({
	headerSection: {
		marginTop: '9.375rem',
		padding: '4.375rem',
		textAlign: 'center',
		backgroundColor: theme.palette.background.primaryOpacity,
	},
	aboutTitle: {
		...theme.typography.title,
		color: theme.palette.text.primary,
	},
	aboutSubTitle: {
		...theme.typography.subtitle7,
		color: theme.palette.text.primary,
	},
	content: {
		padding: '3.75rem 0px',
	},
	aboutImg: {
		width: '25.8125rem',
		height: '25.6875rem',
	},
	contentHeader: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		margin: '15px 0px',
	},
	contentDesc: {
		...theme.typography.body3,
		color: theme.palette.text.primary,
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));
const AboutHeader = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0}>
			<Paper className={classes.headerSection} elevation={0}>
				<Typography className={classes.aboutTitle}>about</Typography>
				<Typography className={classes.aboutSubTitle}>
					home / about
				</Typography>
			</Paper>

			<Paper className={classes.content} elevation={0}>
				<Container className={classes.containerStyle} maxWidth={false}>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="center"
						spacing={2}
					>
						<Grid item md={6}>
							<Typography className={classes.contentHeader}>
								What we do ?
							</Typography>
							<Typography className={classes.contentDesc}>
								Chatbot applications streamline interactions
								between people and services, enhancing customer
								experience. At the same time, they offer
								companies new opportunities to improve the
								customers engagement process and operational
								efficiency by reducing the typical cost of
								customer service
							</Typography>
						</Grid>
						<Grid item md={6} align="end">
							<img
								className={classes.aboutImg}
								src={aboutImg}
								alt=""
							/>
						</Grid>
					</Grid>
				</Container>
			</Paper>
		</Paper>
	);
};

export default AboutHeader;
