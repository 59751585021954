import {
	Container,
	Grid,
	Paper,
	Divider,
	Typography,
	Menu,
	MenuItem,
	Button,
	Radio,
	RadioGroup,
	FormControl,
	FormControlLabel,
	Popover,
	makeStyles,
	CircularProgress,
} from '@material-ui/core';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import axios from 'helpers/axios';
import { ArrowDropDown } from '@material-ui/icons';
import { ReactComponent as Calender } from 'assets/icons/calender.svg';
import {
	checkEmptyConversationsReplies,
	checkEmptyClientSources,
	checkMostFrequentQuestions,
	checkEmptyAssignedClients,
	checkEmptyCrmStatus,
} from 'helpers/dashboard';

import DashboardHeader from './header/dashboardHeader';
import ConversationChart from './conversationChart/conversationsStatistics';
import Conversation from './conversationChart';
import CrmChart from './crmChart';

import BotBuilder from './botBuilder';
import NoData from './noDashboardData/Component';

const Dashboard = ({ talkyBotId }) => {
	const classes = useStyles();
	const [choice, setChoice] = useState('This Month');
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [loading, setLoading] = useState(true);

	const [conversationsRepliesState, setConversationsRepliesState] = useState({
		summary: [
			{
				provider: 'facebook',
				bot_replies: 0,
				team_replies: 0,
				all_replies: 0,
			},
		],
		bot_replies: 0,
		team_member_replies: 0,
		all_replies: 0,
	});

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	const [clientSources, setClientSources] = useState({
		sources: {
			facebook: 0,
			whatsapp: 0,
			self_imported: 0,
		},
		people_count: 0,
	});

	const [assignedClientsState, setAssignedClientsState] = useState({
		assigned: 0,
		not_assigned: 0,
	});

	const [crmStatus, setCrmStatus] = useState({
		number_of_people_have_not_status: 0,
		number_of_people_have_status: 0,
		summary: [],
	});

	const [mostFrequentQuestions, setMostFrequentQuestions] = useState({});

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const getMostFrequentQuestions = async (talkyBotID, filter) => {
		const res = await axios.get(
			`/api//statistics/${talkyBotID}/most_frequent_questions?filter=${filter?.toUpperCase()}`
		);
		setMostFrequentQuestions(res?.data?.data);
		console.log('most frequent questions', res?.data?.data);
		setLoading(false);
		return res?.data?.data;
	};

	const getConversationsReplies = async (talkyBotID, filter) => {
		const res = await axios.get(
			`/api//statistics/${talkyBotID}/conversation_replies?filter=${filter?.toUpperCase()}`
		);
		console.log('conversations replies', res?.data?.data);
		setConversationsRepliesState(res?.data?.data);
		return res?.data?.data;
	};

	const getClientSources = async (talkyBotID, filter) => {
		const res = await axios.get(
			`/api//statistics/${talkyBotID}/clients_source_summary?filter=${filter?.toUpperCase()}`
		);
		setClientSources(res?.data?.data);
		console.log('client sources: ', res?.data?.data);
		return res?.data?.data;
	};

	const getAssignedClients = async (talkyBotID, filter) => {
		const res = await axios.get(
			`/api//statistics/${talkyBotID}/assigned_clients_summary?filter=${filter?.toUpperCase()}`
		);
		console.log('assigned clients', res.data);
		setAssignedClientsState(res?.data?.data);
		return res;
	};

	const getCRMStatus = async (talkyBotID, filter) => {
		const res = await axios.get(
			`/api//statistics/${talkyBotID}/crm_status_summary?filter=${filter?.toUpperCase()}`
		);

		console.log('crm status: ', res.data);
		setCrmStatus(res?.data?.data);
		return res?.data?.data;
	};

	useEffect(() => {
		getConversationsReplies(talkyBotId, choice);
		getClientSources(talkyBotId, choice);
		getAssignedClients(talkyBotId, choice);
		getCRMStatus(talkyBotId, choice);
		getMostFrequentQuestions(talkyBotId, choice);
	}, []);

	return (
		<>
			{loading ? (
				<CircularProgress
					style={{ marginTop: '30%', marginLeft: '40%' }}
				/>
			) : checkEmptyConversationsReplies(conversationsRepliesState) &&
			  checkEmptyClientSources(clientSources) &&
			  checkMostFrequentQuestions(mostFrequentQuestions) &&
			  checkEmptyAssignedClients(assignedClientsState) &&
			  checkEmptyCrmStatus(crmStatus) ? (
				<NoData />
			) : (
				<Paper elevation={0} style={{ backgroundColor: 'transparent' }}>
					<DashboardHeader />
					<Divider
						style={{
							marginTop: '2rem',
							height: '0.12rem',
							marginBottom: '2rem',
						}}
					/>
					<Grid
						container
						xs={12}
						style={{
							marginBottom: '2rem',
							marginTop: '1rem',
						}}
					>
						<Grid item xs={10}>
							<Typography
								style={{
									font: 'normal normal bold 16px/26px Poppins',
									textTransform: 'capitalize',
								}}
							>
								Overview
							</Typography>
						</Grid>
						<Grid item xs={2}>
							<Paper
								style={{
									paddingTop: '0.5rem',
									paddingBottom: '0.5rem',
									borderRadius: '1.5rem',
								}}
							>
								<Button
									startIcon={
										<Calender
											style={{
												width: '1rem',
												height: '1rem',
											}}
										/>
									}
									style={{
										marginRight: '2.5rem',
										fontSize: '1rem',
										width: '100%',
									}}
									endIcon={
										<ArrowDropDown
											style={{
												width: '2.2rem',
												height: '2.2rem',
											}}
										/>
									}
									onClick={handleClick}
									disableRipple
								>
									{choice}
								</Button>
							</Paper>
						</Grid>
					</Grid>

					<Popover
						id={id}
						open={open}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						classes={{
							paper: classes.paper,
						}}
					>
						<RadioGroup
							aria-label="quiz"
							name="quiz"
							onChange={(e) => {
								setChoice(
									e.target.value?.split('_')?.join(' ')
								);
								getConversationsReplies(
									talkyBotId,
									e.target.value
								);
								getClientSources(talkyBotId, e.target.value);
								getAssignedClients(talkyBotId, e.target.value);
								getCRMStatus(talkyBotId, e.target.value);
								getMostFrequentQuestions(
									talkyBotId,
									e.target.value
								);
							}}
						>
							<FormControlLabel
								value="This_Week"
								control={<Radio color="primary" />}
								label="This Week"
							/>
							<FormControlLabel
								value="Last_Week"
								control={<Radio color="primary" />}
								label="Last Week"
							/>
							<FormControlLabel
								value="This_Month"
								control={<Radio color="primary" />}
								label="This Month"
							/>
							<FormControlLabel
								value="Last_Month"
								control={<Radio color="primary" />}
								label="Last Month"
							/>
							<FormControlLabel
								value="Overall"
								control={<Radio color="primary" />}
								label="Overall"
							/>
						</RadioGroup>
					</Popover>

					<Conversation
						conversationsRepliesData={conversationsRepliesState}
						mostFrequentQuestions={mostFrequentQuestions}
					/>
					<CrmChart
						clientSources={clientSources}
						assignedClientsState={assignedClientsState}
						crmStatus={crmStatus}
					/>
					{/* <BotBuilder /> */}
				</Paper>
			)}
		</>
	);
};

const mapStateToProps = (state) => ({
	talkyBotId: state?.activeTalkybot?.id,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

const useStyles = makeStyles((theme) => ({
	paper: {
		borderRadius: '1.5rem',
		width: '15rem',
		paddingLeft: '1rem',
		paddingTop: '1rem',
		paddingBottom: '1rem',
		marginTop: '7px',
	},
}));
