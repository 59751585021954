import {
	CLEAR_CURRENT_MEMBER,
	CLEAR_ERROR,
	EDIT_MEMBER_PERMISIONS_FAIL,
	EDIT_MEMBER_PERMISIONS_REQUEST,
	EDIT_MEMBER_PERMISIONS_SUCCESS,
	EDIT_MEMBER_STATUS_FAIL,
	EDIT_MEMBER_STATUS_REQUEST,
	EDIT_MEMBER_STATUS_SUCCESS,
	GET_MEMBERS_FAIL,
	GET_MEMBERS_REQUEST,
	GET_MEMBERS_SUCCESS,
	GET_MEMBER_FAIL,
	GET_MEMBER_REQUEST,
	GET_MEMBER_SUCCESS,
	INVITE_MEMBER_FAIL,
	INVITE_MEMBER_REQUEST,
	INVITE_MEMBER_SUCCESS,
} from 'store/actions/types/members';

export default (
	state = {
		members: null,
		loading: false,
		error: false,
		currentMember: null,
	},
	{ type, payload }
) => {
	switch (type) {
		case GET_MEMBERS_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}
		case GET_MEMBER_REQUEST: {
			return {
				...state,
				loading: true,
			};
		}
		case INVITE_MEMBER_REQUEST: {
			return {
				loading: true,
				success: false,
			};
		}

		case EDIT_MEMBER_STATUS_REQUEST: {
			return {
				...state,
				loading: true,
				success: false,
			};
		}

		case EDIT_MEMBER_PERMISIONS_REQUEST: {
			return { ...state, loading: true, success: false };
		}
		case INVITE_MEMBER_SUCCESS: {
			return {
				loading: false,
				members: null,
				error: false,
				success: true,
			};
		}

		case EDIT_MEMBER_STATUS_SUCCESS: {
			const { status } = payload;
			const newMember = { ...state.currentMember };

			newMember.attributes.status = status;

			return {
				loading: false,
				members: null,
				error: false,
				success: true,
				currentMember: newMember,
			};
		}

		case EDIT_MEMBER_PERMISIONS_SUCCESS: {
			return {
				loading: false,
				members: null,
				error: false,
				success: true,
				currentMember: null,
			};
		}
		case GET_MEMBERS_SUCCESS: {
			const { members } = payload;
			return {
				members,
				loading: false,
				error: false,
			};
		}
		case GET_MEMBER_SUCCESS: {
			const { member } = payload;
			return {
				...state,
				loading: false,
				error: false,
				currentMember: member,
			};
		}
		case CLEAR_CURRENT_MEMBER: {
			return {
				...state,
				currentMember: null,
				members: null,
			};
		}

		case CLEAR_ERROR: {
			return {
				...state,
				error: null,
			};
		}
		case GET_MEMBERS_FAIL: {
			return {
				...state,
				loading: false,
				error: payload,
			};
		}
		case GET_MEMBER_FAIL: {
			return {
				...state,
				loading: false,
				error: true,
				currentMember: null,
			};
		}
		case INVITE_MEMBER_FAIL: {
			return {
				members: null,
				loading: false,
				error: payload,
			};
		}
		case EDIT_MEMBER_PERMISIONS_FAIL: {
			return {
				members: null,
				loading: false,
				error: true,
				success: false,
			};
		}

		case EDIT_MEMBER_STATUS_FAIL: {
			return {
				members: null,
				loading: false,
				error: true,
				success: false,
			};
		}

		// case ADD_NEW_CONVERSATION: {
		// 	const {
		// 		data: { conversation },
		// 	} = payload;
		// 	// eslint-disable-next-line no-debugger
		// 	return {
		// 		...state,
		// 		[conversation.id]: { ...conversation },
		// 	};
		// }

		// case ADD_NEW_MESSAGE_TO_CONVERSATION: {
		// 	const {
		// 		data: { message },
		// 	} = payload;
		// 	const newState = { ...state };
		// 	const oldConversation = newState[message.conversation_id];
		// 	newState[message.conversation_id] = {
		// 		...oldConversation,
		// 		messages: [...oldConversation.messages, message],
		// 	};

		// 	return { ...newState };
		// }

		default:
			return state;
	}
};
