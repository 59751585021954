import React from 'react';
import TalkybotProvidersComponent from 'components/BotBuilder/TopicFlows/Providers';
import useTitle from 'hooks/useTitle';

const TalkybotConnection = () => {
	useTitle('publishing');
	return (
		<div>
			<TalkybotProvidersComponent />
		</div>
	);
};

export default TalkybotConnection;
