export const GET_MEMBERS_REQUEST = 'GET_MEMBERS_REQUEST';
export const GET_MEMBERS_SUCCESS = 'GET_MEMBERS_SUCCESS';
export const GET_MEMBERS_FAIL = 'GET_MEMBERS_FAIL';

export const GET_MEMBER_REQUEST = 'GET_MEMBER_REQUEST';
export const GET_MEMBER_SUCCESS = 'GET_MEMBER_SUCCESS';
export const GET_MEMBER_FAIL = 'GET_MEMBER_FAIL';
export const CLEAR_CURRENT_MEMBER = 'CLEAR_CURRENT_MEMBER';

export const INVITE_MEMBER_REQUEST = 'INVITE_MEMBER_REQUEST';
export const INVITE_MEMBER_SUCCESS = 'INVITE_MEMBER_SUCCESS';
export const INVITE_MEMBER_FAIL = 'INVITE_MEMBER_FAIL';

export const EDIT_MEMBER_PERMISIONS_REQUEST = 'EDIT_MEMBER_PERMISIONS_REQUEST';
export const EDIT_MEMBER_PERMISIONS_SUCCESS = 'EDIT_MEMBER_PERMISIONS_SUCCESS';
export const EDIT_MEMBER_PERMISIONS_FAIL = 'EDIT_MEMBER_PERMISIONS_FAIL';

export const EDIT_MEMBER_STATUS_REQUEST = 'EDIT_MEMBER_STATUS_REQUEST';
export const EDIT_MEMBER_STATUS_SUCCESS = 'EDIT_MEMBER_STATUS_SUCCESS';
export const EDIT_MEMBER_STATUS_FAIL = 'EDIT_MEMBER_STATUS_FAIL';

export const CLEAR_ERROR = 'CLEAR_ERROR';
