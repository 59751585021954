import { Paper } from '@material-ui/core';
import FeaturesHeader from './featuresHeader';
import BotBuilder from './botBuilder';
import Relationship from './relationship';
import LiveSupport from './liveSupport';
const Features = () => {
	return (
		<Paper elevation={0}>
			<FeaturesHeader />
			<Relationship />
			<BotBuilder />
			<LiveSupport />
		</Paper>
	);
};

export default Features;
