import { Paper, Typography, makeStyles, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import axios from 'helpers/axios';
import { connect } from 'react-redux';
import { Doughnut } from 'react-chartjs-2';

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: true,
			position: 'bottom',
			labels: {
				boxWidth: 15,
				boxHeight: 15,
				padding: 15,
				color: '#464255',
			},
			tooltip: {
				usePointStyle: true,
				bodyFont: {
					size: 20,
				},
				callbacks: {
					labelPointStyle: function (context) {
						return {
							pointStyle: 'triangle',
							rotation: 0,
						};
					},
				},
			},
		},
	},
};
const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		padding: '2.0625rem 1.875rem',
		borderRadius: '1.25rem',
		backgroundColor: '#FDFDFD',
		border: '1px #F2F2F2',
		minHeight: '43.825rem',
	},

	header: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.375rem !important',
		// fontWeight: 500,
		color: ' #170A38',
		textTransform: 'capitalize',
		paddingBottom: '5rem',
	},
	chartStyle: {
		margin: '0px auto  1.875rem auto',
	},
	footerDesc: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '0.875rem !important',
		fontWeight: 'medium',
		color: '#464255',
		textTransform: 'capitalize',
	},
	footerNum: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.875rem !important',
		fontWeight: 'bold',
		color: '#464255',
	},
	defaultGreyChart: {
		width: '15rem',
		height: '15rem',
		borderRadius: '50%',
		border: '3rem solid #808080',
		marginTop: '9rem',
	},
}));
const CrmStatus = ({ talkyBotId, crmStatus }) => {
	const classes = useStyles();

	// const [crmStatus, setCrmStatus] = useState({
	// 	number_of_people_have_not_status: 0,
	// 	number_of_people_have_status: 0,
	// 	summary: [],
	// });

	const data = {
		labels: [...crmStatus?.summary.map((item) => item?.name)],
		datasets: [
			{
				label: '# of Votes',
				data: [
					...crmStatus?.summary.map((item) => item?.number_of_people),
				],
				backgroundColor: [
					...crmStatus?.summary.map((item) => item?.color),
				],
				rotation: 20,
				borderWidth: 0,
				// borderAlign:"center",
				borderRadius: 100,
				cutout: '80%',
				// offset:50,
			},
		],
	};

	// const getCRMStatus = async (talkyBotID) => {
	// 	const res = await axios.get(
	// 		`/api//statistics/${talkyBotID}/crm_status_summary`
	// 	);

	// 	console.log('crm status: ', res.data);
	// 	setCrmStatus(res.data);
	// 	return res.data;
	// };

	useEffect(() => {
		// getCRMStatus(talkyBotId);
	}, []);

	return (
		<Paper elevation={0} className={classes.sectionStyle}>
			<Typography className={classes.header}>
				CRM Status Analysis Summary
			</Typography>

			{!crmStatus?.summary?.length ? (
				// && !crmStatus?.number_of_people_have_not_status
				// && !crmStatus?.number_of_people_have_status
				<div className={classes.defaultGreyChart}>{/*  */}</div>
			) : (
				<div className={classes.chartStyle}>
					<Doughnut
						height={window.innerWidth > 1500 ? 300 : 250}
						data={data}
						options={options}
					/>
				</div>
			)}

			<Grid
				container
				justify="space-between"
				style={{ marginTop: '2rem' }}
			>
				<Grid>
					<Typography className={classes.footerDesc}>
						persons have status
					</Typography>
					<Typography className={classes.footerNum}>
						{crmStatus?.number_of_people_have_status}
					</Typography>
				</Grid>
				<Grid>
					<Typography className={classes.footerDesc}>
						persons haven’t status
					</Typography>
					<Typography className={classes.footerNum}>
						{crmStatus?.number_of_people_have_not_status}
					</Typography>
				</Grid>
			</Grid>
		</Paper>
	);
};

const mapStateToProps = (state) => ({
	talkyBotId: state?.activeTalkybot?.id,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CrmStatus);
