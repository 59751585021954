import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CustomLoading from 'components/common/CustomSpinner/Component';
import GridContainer from 'components/common/Grid/GridContainer';
import styles from './Component.module.css';

const CustomButton = ({
	name,
	color,
	onClick,
	style,
	children,
	withLink,
	disabled,
	linkTo,
	icon,
	isLoading,
	...rest
}) => {
	const renderWhichButton = () => {
		let className = styles.primaryBoldButton;
		switch (color) {
			case 'success':
				className = styles.successButton;
				break;
			case 'small-success':
				className = styles.smallSuccessButton;
				break;
			case 'primary-dark':
				className = styles.primaryDarkBoldButton;
				break;
			case 'small-primary':
				className = styles.smallPrimaryBoldButton;
				break;
			case 'secondary':
				className = styles.secondaryBoldButton;
				break;
			case 'outlined':
				className = styles.outlinedBoldButton;
				break;
			case 'black':
				className = styles.blackButton;
				break;
			case 'gradient':
				className = styles.gradientButton;
				break;
			case 'link':
				className = styles.linkButton;
				break;
			case 'red':
				className = styles.redButton;
				break;
			case 'nonoutlined':
				className = styles.nonoutlinedButton;
				break;
			case 'grey':
				className = styles.greyButton;
				break;

			case 'disabled':
				className = styles.disabledButton;
				break;
			default:
				break;
		}
		return className;
	};

	const renderButtonContent = () => {
		if (isLoading)
			return (
				<GridContainer justifyContent="center">
					<CustomLoading size="small" />
				</GridContainer>
			);
		return name;
	};

	return (
		<>
			{color !== 'nonoutlined' && !withLink ? (
				<button
					aria-controls="simple-menu"
					aria-haspopup="true"
					type="button"
					disabled={!!disabled || isLoading}
					className={`${
						styles.commonStyledButton
					} ${renderWhichButton()}`}
					onClick={onClick}
					style={{ ...style }}
					{...rest}
				>
					{icon && icon} {renderButtonContent()}
				</button>
			) : withLink ? (
				<Link
					to={linkTo}
					className={`${
						styles.commonStyledButton
					} ${renderWhichButton()}`}
					style={{
						...style,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					{renderButtonContent()}
				</Link>
			) : (
				<button
					aria-controls="simple-menu"
					aria-haspopup="true"
					type="button"
					disabled={!!disabled}
					className={`${renderWhichButton()}`}
					onClick={onClick}
					style={{ ...style }}
				>
					{renderButtonContent()}
				</button>
			)}
			{children}
		</>
	);
};

CustomButton.propTypes = {
	name: PropTypes.object || PropTypes.string,
	color: PropTypes.oneOf([
		'secondary',
		'primary',
		'outlined',
		'black',
		'gradient',
		'nonoutlinedButton',
		'primary-dark',
		'small-primary',
		'grey',
		'nonoutlined',
		'success',
		'small-success',
		'red',
	]),
	onClick: PropTypes.func,
};
export default CustomButton;
