/* eslint-disable camelcase */
import {
	GET_SOME_CONVERSATIONS,
	ACTIVATE_CONVERSATION,
	ADD_NEW_CONVERSATION,
	GET_CONVERSATION_MESSAGES,
	MARK_CONVERSATIONS_AS_DONE,
	ADD_UPCOMING_MESSAGE_TO_CONVERSATION,
	TAKE_OVER,
	ADD_NOTE_TO_PERSON_FROM_CONVERSATION,
	MARK_LAST_MESSAGE_AS_OLD,
	GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_SUCCESS,
	DEACTIVATE_CONVERSATION,
	TAKE_OVER_FAILED,
	UN_TAKE_OVER,
	MARK_AS_DONE,
	MARK_AS_OPEN,
	FETCH_MORE_MESSAGES,
} from 'store/actions/types/conversations';
import { produce, setAutoFreeze } from 'immer';

const initialState = {
	facebook: {
		users: null,
		liveChat: null,
	},
	whatsapp: {
		users: null,
		liveChat: null,
	},
	web: {
		users: null,
		liveChat: null,
	},
	members: null,
	meta: {
		error: null,
		action: null,
	},
};

export default (state = initialState, { type, payload, error }) =>
	// eslint-disable-next-line consistent-return
	produce(state, (draft) => {
		switch (type) {
			case GET_SOME_CONVERSATIONS: {
				const { conversations, provider } = payload;
				if (draft[provider]) draft[provider].users = conversations;
				draft.meta = {
					action: GET_SOME_CONVERSATIONS,
					error: null,
				};
				break;
			}

			case ADD_NEW_CONVERSATION: {
				const { conversation, provider } = payload;
				draft[provider].users[conversation.attributes.id] =
					conversation;
				draft.meta = { action: ADD_NEW_CONVERSATION, error: null };
				break;
			}

			case MARK_CONVERSATIONS_AS_DONE: {
				const { conversationsList, provider } = payload;

				// users
				conversationsList.forEach((id) => {
					draft[provider].users[id].statue = 'done';
					draft[provider].users[id].attributes.statue = 'done';
				});
				// live chat
				draft[provider].liveChat.attributes.status = 'done';
				draft.meta = {
					action: MARK_CONVERSATIONS_AS_DONE,
					error: null,
				};
				break;
			}
			case MARK_AS_DONE: {
				const { conversationId, provider } = payload;

				// users
				draft[provider].users[conversationId].statue = 'done';
				draft[provider].users[conversationId].attributes.statue =
					'done';
				// live chat
				draft[provider].liveChat.attributes.statue = 'done';
				draft.meta = { action: MARK_AS_DONE, error: null };
				break;
			}
			case MARK_AS_OPEN: {
				const { conversationId, provider } = payload;
				// users
				draft[provider].users[conversationId].statue = 'open';
				draft[provider].users[conversationId].attributes.statue =
					'open';
				// live chat
				draft[provider].liveChat.attributes.statue = 'open';
				draft.meta = { action: MARK_AS_OPEN, error: null };
				break;
			}
			case FETCH_MORE_MESSAGES: {
				const { conversation, provider } = payload;

				// live chat
				draft[provider].liveChat?.attributes?.messages?.push(
					...conversation
				);
				draft.meta = { action: FETCH_MORE_MESSAGES, error: null };
				break;
			}

			case ACTIVATE_CONVERSATION: {
				const { conversation, provider } = payload;

				// live chat
				draft[provider].liveChat = conversation;
				draft.meta = { action: ACTIVATE_CONVERSATION, error: null };
				break;
			}

			case DEACTIVATE_CONVERSATION: {
				const { provider } = payload;
				// live chat
				if (draft[provider]) draft[provider].liveChat = null;
				break;
			}

			case ADD_NOTE_TO_PERSON_FROM_CONVERSATION: {
				const { personId, data, provider } = payload;

				if (
					Number(personId) !==
					state[provider].liveChat?.attributes?.user_info?.data
						?.attributes?.id
				) {
					return state;
				}

				draft[provider].liveChat.attributes.last_note.data = data;
				draft.meta = {
					action: ADD_NOTE_TO_PERSON_FROM_CONVERSATION,
					error: null,
				};
				break;
			}

			case ADD_UPCOMING_MESSAGE_TO_CONVERSATION: {
				const { provider, conversation_id, message } = payload;

				const isConversationSameWithLiveChat =
					draft[provider].liveChat?.attributes?.id ===
					conversation_id;
				if (isConversationSameWithLiveChat) {
					// livechat
					draft[provider].liveChat.attributes.messages.unshift(
						message
					);
					draft[provider].liveChat.attributes.last_message.data = {
						...message,
						isFresh: false,
					};
					// users
					draft[provider].users[
						conversation_id
					].attributes.last_message.data = {
						...message,
						isFresh: false,
						unReadMessages: 0,
					};
				} else {
					// users && not in the live_chat
					draft[provider].users[
						conversation_id
					].attributes.last_message.data = {
						...message,
						isFresh: true,
						unReadMessages:
							//  draft[provider].users[conversation_id]
							// 	.attributes.last_message.data.attributes.by_talkybot
							// 	? draft[provider].users[conversation_id].attributes
							// 			.last_message.data.unReadMessages
							// 	:
							draft[provider].users[conversation_id].attributes
								.last_message.data.unReadMessages
								? draft[provider].users[conversation_id]
										.attributes.last_message.data
										.unReadMessages + 1
								: 1,
					};
				}
				draft.meta = {
					action: ADD_UPCOMING_MESSAGE_TO_CONVERSATION,
					error: null,
				};
				break;
			}

			case TAKE_OVER: {
				const { conversationId, provider, userId } = payload;

				// userId
				draft[provider].users[conversationId].user_id = userId;

				draft[provider].users[conversationId].attributes.user_id =
					userId;
				// live chat
				if (conversationId === draft[provider].liveChat.attributes.id)
					draft[provider].liveChat.attributes.user_id = userId;
				// meta
				draft.meta = {
					action: TAKE_OVER,
					error: null,
				};
				break;
			}

			case UN_TAKE_OVER: {
				const { conversationId, provider } = payload;

				// users
				draft[provider].users[conversationId].user_id = null;
				draft[provider].users[conversationId].attributes.user_id = null;
				// live chat
				draft[provider].liveChat.user_id = null;
				draft[provider].liveChat.attributes.user_id = null;
				draft.meta = {
					action: UN_TAKE_OVER,
					error: null,
				};
				break;
			}

			case TAKE_OVER_FAILED: {
				draft.meta = {
					action: TAKE_OVER_FAILED,
					error,
				};
				break;
			}

			case MARK_LAST_MESSAGE_AS_OLD: {
				const { provider, conversation_id } = payload;

				// users
				draft[provider].users[
					conversation_id
				].attributes.last_message.data.isFresh = false;
				draft[provider].users[
					conversation_id
				].attributes.last_message.data.unReadMessages = 0;
				draft.meta = {
					action: MARK_LAST_MESSAGE_AS_OLD,
					error: null,
				};
				break;
			}

			case GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_SUCCESS: {
				const { members } = payload;

				// users
				draft.members = members;
				draft.meta = {
					action: GET_MEMBERS_THAT_CAN_ACCESS_CONVERSATION_SUCCESS,
					error: null,
				};
				break;
			}
			default:
				// by default immer return  original state
				break;
		}
	});
