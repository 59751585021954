import React from 'react';
import { Paper } from '@material-ui/core';
import Services from './services';
import AboutHeader from './aboutHeader';

const About = () => {
	return (
		<Paper elevation={0}>
			<AboutHeader />
			<Services />
		</Paper>
	);
};

export default About;
