import {
	ACTIVATE_BOT,
	DEACTIVATE_BOT,
	INSTALL_APP,
	UNINSTALL_APP,
} from 'store/actions/types/talkybots';

export default (state = null, { type, payload }) => {
	switch (type) {
		case ACTIVATE_BOT: {
			const { data } = payload;
			return {
				...data,
				...data?.talkybot,
			};
		}

		case UNINSTALL_APP: {
			const { application } = payload;
			const newState = { ...state };

			newState.attributes.installed_applications =
				newState.attributes.installed_applications.filter(
					(app) => app.applicationable_type !== application
				);
			return { ...newState };
		}

		case INSTALL_APP: {
			const { application } = payload;
			const newState = { ...state };
			const newInstalledApps = [
				...newState?.attributes?.installed_applications.filter(
					(app) => app.attributes.id !== application.attributes.id
				),
				application,
			];
			newState.attributes.installed_applications = newInstalledApps;
			return newState;
		}

		case DEACTIVATE_BOT: {
			return null;
		}
		default:
			return state;
	}
};
