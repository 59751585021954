import { handleActionError } from 'helpers/reducerError';
import { completeUserProfile, currentUsage } from 'constants/apis';
import talkybotAxios from 'helpers/axios';
import * as actionType from 'store/actions/types/usage';

const getCurrentUsageAction = (tenantId) => async (dispatch) => {
	try {
		dispatch({ type: actionType.GET_CURRENT_USAGE_REQUEST });
		const { data } = await talkybotAxios.get(currentUsage(tenantId));
		dispatch({
			type: actionType.GET_CURRENT_USAGE_SUCCESS,
			payload: data,
		});
	} catch (error) {
		dispatch({
			type: actionType.GET_CURRENT_USAGE_FAIL,
			payload: handleActionError(error, 'Tray Again'),
		});
	}
};
export default getCurrentUsageAction;
