import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Paper, Typography, makeStyles } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';

const options = {
	responsive: true,
	maintainAspectRatio: false,
	plugins: {
		legend: {
			display: true,
			position: 'bottom',
			labels: {
				boxWidth: 15,
				boxHeight: 15,
				padding: 30,
				color: '#464255',
			},
		},
		tooltip: {
			usePointStyle: true,
			bodyFont: {},
			callbacks: {
				labelPointStyle: function (context) {
					return {
						pointStyle: 'triangle',
						rotation: 0,
					};
				},
			},
		},
	},
};

const CrmGender = ({ talkyBotId, assignedClientsState }) => {
	const classes = useStyles();

	useEffect(() => {}, []);

	const data = {
		labels: ['Assigned', 'Not Assigned'],
		datasets: [
			{
				label: '# of Votes',
				data: [
					assignedClientsState?.assigned,
					assignedClientsState?.not_assigned,
				],
				backgroundColor: ['#6418C3', '#FFBB54'],
				rotation: 360,
				cutout: '80%',
				offset: 5,
			},
		],
	};
	return (
		<Paper elevation={0} className={classes.sectionStyle}>
			<Typography className={classes.header}>
				Assigned Customers
			</Typography>
			{!assignedClientsState?.assigned &&
			!assignedClientsState?.not_assigned ? (
				<div className={classes.defaultGreyChart}>
					{/*
					 */}
				</div>
			) : (
				<div className={classes.chartStyle}>
					<Doughnut
						height={850}
						width={850}
						data={data}
						options={options}
					/>
				</div>
			)}
		</Paper>
	);
};

const mapStateToProps = (state) => ({
	talkyBotId: state?.activeTalkybot?.id,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CrmGender);

const useStyles = makeStyles((theme) => ({
	sectionStyle: {
		padding: '2.0625rem 1.875rem',
		borderRadius: '1.25rem',
		backgroundColor: '#FDFDFD',
		border: '1px #F2F2F2',
		minHeight: '43.625rem',
	},
	header: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.375rem !important',
		color: ' #170A38',
		textTransform: 'capitalize',
		marginBottom: '2.8125rem',
	},
	chartStyle: {
		margin: 'auto',
		position: 'relative',
		top: '2rem',
		height: '25rem',
	},
	chartImg: {
		position: 'absolute',
		top: '30%',
		left: '30%',
		borderRadius: '50%',
		boxShadow: '0px  0.9375rem 1rem #2F042B1C ',
	},
	defaultGreyChart: {
		width: '15rem',
		height: '15rem',
		borderRadius: '50%',
		border: '3rem solid #808080',
		marginTop: '15rem',
	},
}));
