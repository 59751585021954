import React, { useEffect } from 'react';
// Modal Trial
import {
	Dialog,
	DialogTitle,
	DialogContent,
	IconButton,
	Grow,
	Box,
	DialogActions,
	Button,
	Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import Close from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ReactComponent as ExitIcon } from 'assets/icons/Exit-Modal-Icon.svg';
// import Button from 'components/common/CustomButton/Component';

const useStyles = makeStyles((theme) => ({
	modalPaper: {
		borderRadius: theme.borderRadius.borderRadius27px,
		boxShadow: '10px 10px 20px #30303029',
		paddingBottom: theme.spacing(20),
	},
	modalHeader: {
		borderBottom: 'none',
		paddingTop: '24px',
		paddingRight: '24px',
		paddingBottom: '0',
		paddingLeft: '24px',
		minHeight: '16.43px',
	},
	modalTitle: {
		...theme.typography.subtitle2,
		color: theme.palette.common.black,
		marginTop: theme.spacing(16),
		// paddingLeft: theme.spacing(45),
	},
	modalCloseButton: {
		color: '#999999',
		WebkitAppearance: 'none',
		padding: '0',
		cursor: 'pointer',
		background: '0 0',
		border: '0',
		fontSize: 'inherit',
		opacity: '.9',
		textShadow: 'none',
		fontWeight: '700',
		lineHeight: '1',
		float: 'right',
		marginTop: '15px',
	},
	modalClose: {
		width: '25px',
		height: '25px',
	},
	labelContainer: {
		fontSize: '1.5rem',
		display: 'flex',
		color: '#6418C3',
		flexWrap: 'wrap',
		alignItems: 'center',
		fontFamily: 'Cairo-Bold',
	},
	modalBody: {
		position: 'relative',
		'&::-webkit-scrollbar': {
			width: '0.3em',
			height: '0.1rem',
		},
		'&::-webkit-scrollbar-track': {
			boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
			webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
			webkitBorderRadius: '10px',
		},
		'&::-webkit-scrollbar-thumb': {
			backgroundColor: 'blue',
		},
	},
	paperWidthSm: {
		// width: 1000,
		// padding: 20,
		// maxWidth: 1000,
		'max-width': '45%',
		'max-height': '79%',
	},
	paperWidthMd: {
		// width: 800,
		// padding: 20,
		// maxWidth: 800,
		'max-width': '80%',
		'max-height': '69%',
	},
	DialogTitle: {
		padding: ' 1rem 2rem 0rem 2rem',
		marginTop: '12px',
	},
	dialogPaper: { maxHeight: '60vh' },
}));

const Transition = React.forwardRef((props, ref) => (
	<Grow ref={ref} {...props} />
));

Transition.displayName = 'Transition';

const Modal = ({
	open,
	onProceed,
	onUnmount,
	onClose,
	title,
	component,
	width,
	mainActionName,
	secondaryActionName,
	type,
	...rest
}) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
	const classes = useStyles();

	return (
		<Dialog
			open={open}
			// onBackdropClick={onClose}
			onEscapeKeyDown={onClose}
			TransitionComponent={Transition}
			className={classes.modalPaper}
			fullWidth
			classes={{ paper: classes.dialogPaper }}
			// maxHeight="80%"
			// classes={{
			// 	paperWidthSm:
			// 		type === 'vt'
			// 			? classes.paperWidthSm
			// 			: type === 'md'
			// 			? classes.paperWidthMd
			// 			: '',
			// 	paper: classes.modalPaper,
			// }}
			// fullScreen={fullScreen}
			{...rest}
		>
			<DialogTitle
				classes={{ root: classes.DialogTitle }}
				id="classic-modal-slide-title"
				disableTypography
			>
				<IconButton
					className={classes.modalCloseButton}
					onClick={onClose}
					key="close"
					aria-label="Close"
					color="inherit"
				>
					<ExitIcon
						width={theme.spacing(32)}
						height={theme.spacing(32)}
					/>
				</IconButton>
				{typeof title === 'object' ? (
					<Box className={classes.labelContainer}>
						{title.icon}
						<h5
							className={classes.customModalTitle}
							style={{ marginLeft: '10px' }}
						>
							{title.content}
						</h5>
					</Box>
				) : (
					<Typography className={classes.modalTitle}>
						{title}
					</Typography>
				)}
			</DialogTitle>
			<DialogContent className={classes.modalBody} style={{ width }}>
				{component}
			</DialogContent>
		</Dialog>
	);
};

Modal.propTypes = {
	// title: PropTypes.isRequired,
	component: PropTypes.node.isRequired,
	open: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
	width: PropTypes.string,
	onProceed: PropTypes.func,
	mainActionName: PropTypes.string,
	secondaryActionName: PropTypes.string,
};

export default Modal;
