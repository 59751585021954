import React from 'react';
import Usage from 'components/Usage';
import useTitle from 'hooks/useTitle';

const UsagePage = () => {
	useTitle('usage');
	return (
		// <div
		// 	style={{
		// 		background: '#F4F7F9',
		// 	}}
		// >
		<Usage />
		// </div>
	);
};

export default UsagePage;
