import React, { useState, useEffect } from 'react';
import { makeStyles, Paper, Typography, Box } from '@material-ui/core';
import noDashboardData from 'assets/images/noDashboardData.png';

const Component = (props) => {
	const classes = useStyles();

	return (
		<>
			<Box className={classes.dashboardHeaderWrapper}>
				<Typography className={classes.header}>Dashboard</Typography>
			</Box>
			<Paper className={classes.root} elevation={0}>
				<Box className={classes.noDataContainer}>
					<img
						src={noDashboardData}
						alt="No Data"
						className={classes.image}
					/>
					<Typography className={classes.text}>
						There Is Not Enough Data To Show Analytics
					</Typography>
					<Typography className={classes.engageMore}>
						Engage More With Your Chatbot
					</Typography>
				</Box>
			</Paper>
		</>
	);
};

export default Component;

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		height: '82vh',
		position: 'relative',
	},
	noDataContainer: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		top: '8rem',
	},
	text: {
		font: 'normal normal normal 14px/25px Poppins',
	},
	engageMore: {
		font: 'normal normal bold 14px/25px Poppins',
	},
	header: {
		font: 'normal normal bold 16px/26px Poppins',
		textTransform: 'capitalize',
	},
	dashboardHeaderWrapper: {
		marginTop: '2rem',
		marginBottom: '2rem',
	},
	image: {
		marginBottom: '2rem',
		width: '26rem',
		height: '16rem',
		objectFit: 'cover',
	},
}));
