import Typography from '@material-ui/core/Typography';
import { Container, Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FeaturesImg from 'assets/images/landingImgs/FeaturesImg.png';
import FeaturesHeaderFactors from 'assets/images/landingImgs/FeaturesHeaderFactors.png';

const useStyles = makeStyles((theme) => ({
	headerSection: {
		marginTop: '9.375rem',
		padding: '4.375rem',
		textAlign: 'center',
		backgroundColor: theme.palette.background.primaryOpacity,
		backgroundImage: `url(${FeaturesHeaderFactors})`,
		backgroundSize: '80% 70%',
		backgroundPosition: 'center center',
		backgroundRepeat: 'no-repeat',
	},
	FeaturesTitle: {
		...theme.typography.title,
		color: theme.palette.text.primary,
	},
	FeaturesSubTitle: {
		...theme.typography.subtitle7,
		color: theme.palette.text.primary,
	},
	content: {
		padding: '3.75rem 0px',
	},
	FeaturesImg: {
		width: '20.5625rem',
		height: '20.4375rem',
	},
	contentHeader: {
		...theme.typography.subtitle3,
		color: theme.palette.text.primary,
		margin: '0.9375rem 0px',
	},
	contentDesc: {
		...theme.typography.body3,
		color: theme.palette.text.primary,
	},
	containerStyle: {
		[theme.breakpoints.up('md')]: {
			paddingRight: theme.spacing(15),
			paddingLeft: theme.spacing(15),
		},
		paddingRight: theme.spacing(4),
	},
}));
const FeaturesHeader = () => {
	const classes = useStyles();
	return (
		<Paper elevation={0}>
			<Paper className={classes.headerSection} elevation={0}>
				<Typography className={classes.FeaturesTitle}>
					Features
				</Typography>
				<Typography className={classes.FeaturesSubTitle}>
					home / Features
				</Typography>
			</Paper>

			<Paper className={classes.content} elevation={0}>
				<Container className={classes.containerStyle} maxWidth={false}>
					<Grid
						container
						direction="row"
						justify="space-between"
						alignItems="center"
						spacing={2}
					>
						<Grid item md={6}>
							<Typography className={classes.contentHeader}>
								1- CRM application
							</Typography>
							<Typography className={classes.contentDesc}>
								when describing the benefits of a CRM software,
								very often “CRM evangelists” tend to focus on
								how the system can help you increase revenue,
								manage your sales pipeline, catch and nurture
								leads, send better marketing campaigns, manage
								your team, run customer service reports, and
								even analyze data. And they are 100% correct – a
								good CRM system helps you do all that!
							</Typography>
						</Grid>
						<Grid item md={6} align="end">
							<img
								className={classes.FeaturesImg}
								src={FeaturesImg}
								alt=""
							/>
						</Grid>
					</Grid>
				</Container>
			</Paper>
		</Paper>
	);
};

export default FeaturesHeader;
