import React from 'react';
import styles from './Component.module.css';

const CustomLoader = ({ size }) => {
	const className = () => {
		if (size === 'small') return styles.CustomizedLoader__SM;
		if (size === 'large') return styles.CustomizedLoader__LG;
		if (size === 'very-large') return styles.CustomizedLoader__VG;
		return null;
	};
	return <div className={className()} />;
};

export default CustomLoader;
