import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CAN_EDIT_PERMISSION } from 'constants/permissions';
import userHasPermission from 'helpers/user-has-permission';

const UserHasPermission = ({
	children,
	action,
	otherMember,
	app,
	FallbackComponent = null,
}) => {
	const activeTalkybot = useSelector((state) => state.activeTalkybot);
	const memberToLook =
		useSelector((state) => state.currentMembers?.currentMember) ||
		otherMember;
	const user = useSelector((state) => state.auth.user);
	const [userAllowed, setUserAllowed] = useState();
	useEffect(() => {
		const userAllowedStatus = userHasPermission(
			action,
			user,
			activeTalkybot,
			app,
			action === CAN_EDIT_PERMISSION ? memberToLook : null
		);
		// ;
		setUserAllowed(userAllowedStatus);
	}, [action, user, activeTalkybot, app]);
	return userAllowed ? (
		children
	) : FallbackComponent ? (
		<FallbackComponent />
	) : (
		<span />
	);
};

export default UserHasPermission;
