import { v1 } from 'uuid';
import {
	TEXT_MESSAGE,
	TRIGGER_ANOTHER_TOPIC_FLOW,
	END_WITH_MESSAGE,
	SENDING_MESSAGE_WITH_OPTIONS,
	GUIDE_MESSAGE,
	SET_USER_ATTRIBUTE,
	NOTIFY_TEAM_MEMEBER_TO_TAKE_OVER,
	ASSIGN_TO_TEAM_MEMBER,
	STARTING_MESSAGE,
	SAVE_USER_ATTRIBUTE,
} from 'constants/nodesTypes';
import * as yup from 'yup';

export const generateStartMessageScheme = () => {
	return {
		node_type: STARTING_MESSAGE,
		data: {
			text: 'Hi There! I’ll Be Helping You Today ',
			mentions: [],
		},
	};
};
export const generateStartMessageValidationScheme = () => {
	return yup.object().shape({
		text: yup.string().required('This field is required'),
	});
};
export const getStartMessageInputNamesSchema = () => {
	return {
		text: 'text',
		mentions: 'mentions',
	};
};
/**  end */

/**
 * Generate text message backend node scheme
 *
 * @returns {{custom_node_id: *, node_type: string, data: {text}, next_node_id: string}}
 */

export const generateGuideMessageScheme = () => {
	return {
		node_type: GUIDE_MESSAGE,
		data: {
			text: 'Click To Add Button',
			// mentions: [],
		},
	};
};

/**
 * Generate text message backend node scheme
 *
 * @returns {{node_type: string, data: {text}}}
 */

export const generateTextMessageScheme = () => {
	return {
		node_type: TEXT_MESSAGE,
		data: {
			text: '',
			mentions: [],
		},
	};
};
export const generateTextMessageValidationScheme = () => {
	return yup.object().shape({
		text: yup.string().required('This field is required'),
	});
};
export const getInputNamesSchema = () => {
	return {
		text: 'text',
		mentions: 'mentions',
	};
};
/** ****** end text message */

/**
 * Generate sending message with options backend node scheme
 * @returns {{node_type: string, data: {buttons: *[], text: string}}}
 */

export const generateSendingMessageWithOptionsScheme = () => {
	return {
		node_type: SENDING_MESSAGE_WITH_OPTIONS,
		data: {
			text: '',
			buttons: [
				{
					type: 'postback',
					title: '',
					payload: '',
				},
			],
			mentions: [],
		},
	};
};
export const generateDefaultOptionValueSchema = () => {
	return {
		type: 'postback',
		title: '',
		payload: '',
	};
};
const buttonsNamesSchema = {
	title: yup.string().required('This field is required '),
	payload: yup.object().required('This field is required').nullable(),
};
export const generateSendingMessageWithOptionsValidationScheme = () => {
	return yup.object().shape({
		text: yup.string().required('This field is required'),
		buttons: yup
			.array()
			.of(yup.object().shape(buttonsNamesSchema))
			.required('Must have fields')
			.min(1, 'Minimum of 1 field'),
	});
};
export const getSendingMessageWithOptionsInputNamesSchema = () => {
	return {
		text: 'text',
		mentions: 'mentions',
		buttons: 'buttons',
		buttonsNamesSchema: {
			type: 'type',
			title: 'title',
			payload: 'payload',
		},
	};
};
/** ***** end */

/**
 * Generate Trigger another topic flow back end node scheme
 * @param flowId
 * @returns {{node_type: string, data: {topic_flow_id}}}
 */

export const generateTriggerAnotherTopicFlowScheme = () => ({
	node_type: TRIGGER_ANOTHER_TOPIC_FLOW,
	data: {
		topic_flow_id: '',
	},
});
export const generateTriggerAnotherFlowValidationScheme = () => {
	return yup.object().shape({
		topic_flow_id: yup
			.object()
			.required('This field is required')
			.nullable(),
	});
};
export const getTriggerAnotherFlowInputNamesSchema = () => {
	return {
		topic_flow_id: 'topic_flow_id',
	};
};
//** ******  end ***********/
/**
 * Generate End with message node scheme
 * @returns {{node_type: string, data: {text}}}
 */
export const generateEndWithMessageScheme = () => ({
	node_type: END_WITH_MESSAGE,
	data: {
		text: '',
		mentions: [],
	},
});
export const generateEndWithMessageValidationScheme = () => {
	return yup.object().shape({
		text: yup.string().required('This field is required'),
	});
};
export const getEndWithMessageInputNamesSchema = () => {
	return {
		text: 'text',
		mentions: 'mentions',
	};
};
/** *************** end  ***************/

/**
 *
 * @returns {{node_type: string, data: {text: string}}}
 */

export const generateSetUserAttributeScheme = () => {
	return {
		node_type: SET_USER_ATTRIBUTE,
		data: {
			person_custom_field_id: '',
			value: '',
		},
	};
};
export const generateSetUserAttributeValidationScheme = () => {
	return yup.object().shape({
		person_custom_field_id: yup
			.object()
			.required('This field is required')
			.nullable(),
		value: yup.string().required('This field is required'),
	});
};
export const getSetUserAttributeInputNamesSchema = () => {
	return {
		person_custom_field_id: 'person_custom_field_id',
		value: 'value',
	};
};

/**
 *
 * @param {*} teamMemberIDs
 * @returns   {{conversation_members: array}}}
 */

export const generateNotifyTeamMemberScheme = () => {
	return {
		node_type: NOTIFY_TEAM_MEMEBER_TO_TAKE_OVER,
		data: {
			text: '',
			users_to_assign: [],
		},
	};
};
export const generateNotifyToTakeOverValidationScheme = () => {
	return yup.object().shape({
		text: yup.string().required('This field is required'),
		users_to_assign: yup
			.array()
			.min(1, 'Please select at least one team member'),
	});
};
export const getNotifyToTakeOverInputNamesSchema = () => {
	return {
		text: 'text',
		users_to_assign: 'users_to_assign',
	};
};
/** *** end ***** */

export const generateAssignPersonToTeamMemberScheme = () => {
	return {
		node_type: ASSIGN_TO_TEAM_MEMBER,
		data: {
			text: '',
			team_member: '',
		},
	};
};
export const generateAssignPersonToTeamMemberValidationScheme = () => {
	return yup.object().shape({
		text: yup.string().required('This field is required'),
		team_member: yup.object().required('This field is required').nullable(),
	});
};
export const getAssignPersonToTeamMemberInputNamesSchema = () => {
	return {
		text: 'text',
		teamMember: 'team_member',
	};
};

/**
 * Generate sending save user attr backend node scheme
 *
 */

export const generateSaveUserAttributeSchema = (email_or_phone) => {
	return {
		node_type: SAVE_USER_ATTRIBUTE,
		is_waiting_for_response: true,
		data: {
			is_email_or_phone: email_or_phone ? email_or_phone : '',
			can_skip: true,
			question: '',
			fallback_messages: [''],
			variation: '',
			trigger_topic_flow: { fallback_counter: null, topic_flow: null },
			notify_team_member: { fallback_counter: null, users: [] },
		},
	};
};

export const generateSaveUserAttributeValidationScheme = () => {
	return yup.object().shape({
		is_email_or_phone: yup.string().nullable(),
		question: yup.string().required('This field is required'),
		variation: yup
			.object()
			.nullable()
			.when('is_email_or_phone', {
				is: (value) => value === '' || value === null,
				then: yup
					.object()
					.required('This field is required')
					.nullable(),
			}),
		notify_team_member: yup.object().shape({
			users: yup.array().min(1, 'Please select at least one team member'),
			fallback_counter: yup
				.number()
				.transform((value) => (isNaN(value) ? -1 : value))
				.integer()
				.required('This field is required')
				.max(10, 'must be less than or equal to 10')
				.min(0, 'must be greater than or equal to 0')
				.nullable()
				.typeError('This field is required'),
		}),
		trigger_topic_flow: yup.object().shape({
			topic_flow: yup
				.object()
				.required('This field is required')
				.nullable(),
			fallback_counter: yup
				.number()
				.transform((value) => (isNaN(value) ? -1 : value))

				.integer()
				.required('This field is required')
				.max(10, 'must be less than or equal to 10')
				// .test(
				// 	'notify_team_member.fallback_counter',
				// 	'The data is not correct',
				// 	function (value, context) {
				// 		console.log(
				// 			'parent',
				// 			this.parent,
				// 			'value',
				// 			context,
				// 			'77777777777777777777777777777777777777777'
				// 		);
				// 		// return username && password && email;
				// 	}
				// )
				// .moreThan(
				// 	yup.ref('notify_team_member').fallback_counter,
				// 	'Number must be greater than team member'
				// )
				.nullable()
				.typeError('This field is required'),
		}),
		fallback_messages: yup
			.array()
			.of(
				yup.object().shape({
					text: yup.string().required('This field is required '),
				})
			)
			.required('Must have fields')
			.min(1, 'Minimum of 1 field'),
	});
};
export const getSaveUserAttributeInputNamesSchema = () => {
	return {
		can_skip: 'can_skip',
		is_email_or_phone: 'is_email_or_phone',
		question: 'question',
		variation: 'variation',
		trigger_topic_flow: {
			trigger_topic_flow: 'trigger_topic_flow',
			fallback_counter: 'fallback_counter',
			topic_flow: 'topic_flow',
		},
		notify_team_member: {
			notify_team_member: 'notify_team_member',
			fallback_counter: 'fallback_counter',
			users: 'users',
		},
		fallback_messages: 'fallback_messages',
	};
};
/** ***** end */
