import {
	Container,
	Grid,
	makeStyles,
	Paper,
	Box,
	Typography,
} from '@material-ui/core';
import React from 'react';
import ConversationChart from './conversationsStatistics';
import MostBotUsed from './mostFAQ';

const useStyles = makeStyles((theme) => ({
	sectionstyle: {
		marginBottom: '1.875rem',
		backgroundColor: 'transparent',
		height: '367px',
	},
	mainHeader: {
		fontFamily: 'Poppins, sans-serif !important',
		fontSize: '1.375rem',
		fontWeight: 'medium',
		color: '#170A38',
		textTransform: 'capitalize',
	},
	ConversationChartWrapper: {
		marginRight: theme.spacing(30),
	},
}));
const Conversation = ({ conversationsRepliesData, mostFrequentQuestions }) => {
	const classes = useStyles();
	return (
		<Paper elevation={0} className={classes.sectionstyle}>
			<Grid container justify="space-between" alignItems="stretch">
				<Grid item md={8}>
					<Box className={classes.ConversationChartWrapper}>
						<ConversationChart
							conversationsRepliesData={conversationsRepliesData}
						/>
					</Box>
				</Grid>
				<Grid item md={4}>
					<MostBotUsed
						mostFrequentQuestions={mostFrequentQuestions}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};
export default Conversation;
