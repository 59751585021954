import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Bar } from 'react-chartjs-2';

const useStyles = makeStyles((theme) => ({}));

const Chart = ({ teamRepliesCount, botRepliesCount }) => {
	const classes = useStyles();

	const options = {
		scales: {
			y: {
				min: 0,
				max: Math.max(teamRepliesCount, botRepliesCount) + 10,
				beginAtZero: false,
				ticks: {
					stepSize: 10,
				},
			},
		},
		responsive: true,
		plugins: {
			legend: {
				display: false,
				labels: {
					color: 'rgb(255, 99, 132)',
				},
			},
		},
	};

	const data = {
		labels: ['Bot Replies', 'Team Replies'],
		datasets: [
			{
				label: '',
				data: [botRepliesCount, teamRepliesCount],
				backgroundColor: ['#C5C1FF', '#726AFF '],
				borderRadius: 10,
				borderColor: ['transparent', 'transparent'],
				borderSkipped: false,
				barThickness: 40,
				base: 0,
			},
		],
	};

	return (
		<Bar
			height={300}
			data={data}
			options={{ maintainAspectRatio: false, ...options }}
		/>
	);
};

export default Chart;
