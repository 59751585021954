import {
	ADD_NEW_ACCOUNT,
	GET_ALL_ACCOUNTS,
	REMOVE_ACCOUNT,
	LIST_ACCOUNT_CHANNELS,
} from 'store/actions/types/accounts';
import AccountModel from 'models/AccountModel';
import {
	DISCONNECT_PAGE_CHANNEL,
	REMOVE_FACEBOOK_CHANNEL,
} from 'store/actions/types/tenants';
import {
	getConnectedChannels,
	getConnectedChannelsToAnotherOrganization,
} from 'helpers/settingsHelpers';

const initialState = [];

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_ACCOUNTS: {
			const accounts = [];
			payload.accounts.forEach((account) => {
				const accountModel = new AccountModel({
					id: account.id,
					name: account.name,
					avatar: account.avatar,
					provider: account.provider,
					channels: account.channels,
					tenant: account.tenant,
				});
				accounts.push(accountModel);
			});

			const connectedChannels = getConnectedChannels(accounts);
			localStorage.setItem(
				'connectedChannels',
				JSON.stringify(connectedChannels)
			);

			const connectedChannelsToAnotherOrganization =
				getConnectedChannelsToAnotherOrganization(accounts);
			localStorage.setItem(
				'connectedChannelsToAnotherOrganization',
				JSON.stringify(connectedChannelsToAnotherOrganization)
			);

			return accounts;
		}

		case DISCONNECT_PAGE_CHANNEL: {
			console.log('payload from disconnect page channel 🍔🍔 ', payload);
			// Recieve unique id [accountId,channelId,talkbotId]
			// Recieve the changed data  [isconnected_before,theSametenant]
			const { accountId, channelId, talkybotId, accounts } = payload;
			console.log('sametenant', state);
			const newAccounts = state;
			// loop in omash to get specific account to fuck it
			const accountIndex = newAccounts.findIndex(
				(account) => account.id === accountId
			);
			const targetAccount = accounts[accountIndex];
			const targetAccountChannels = targetAccount.channels;
			const channelIndex = targetAccountChannels.findIndex(
				(channel) => channel.id === channelId
			);
			const targetChannel = targetAccountChannels[channelIndex];
			console.log('fucking target channel', targetChannel);
			const targetChannelConnectionState =
				!targetChannel.attributes.is_connected.is_connected_before;

			console.log(
				'state .................',
				targetChannelConnectionState
			);

			targetChannel.attributes.is_connected.is_connected_before =
				targetChannelConnectionState;

			targetAccount.channels[channelIndex] = targetChannel;
			newAccounts[accountIndex] = targetAccount;

			console.log(
				'new accounts state: ',
				accountIndex,
				targetAccount,
				targetAccountChannels,
				payload
			);

			return newAccounts;
		}
		case ADD_NEW_ACCOUNT: {
			const account = new AccountModel({
				id: payload.id,
				name: payload.name,
				avatar: payload.avatar,
				provider: payload.provider,
				channels: payload.channels,
				tenant: payload.tenant,
			});
			console.log('account: ', account);
			return [...state, account];
		}
		case REMOVE_ACCOUNT: {
			console.log('payload: ', payload);

			const accounts = state.filter(
				(account) => account.id !== payload.id
			);

			const connectedChannels = getConnectedChannels(accounts);
			localStorage.setItem(
				'connectedChannels',
				JSON.stringify(connectedChannels)
			);

			const connectedChannelsToAnotherOrganization =
				getConnectedChannelsToAnotherOrganization(accounts);

			localStorage.setItem(
				'connectedChannelsToAnotherOrganization',
				JSON.stringify(connectedChannelsToAnotherOrganization)
			);

			return accounts;
		}
		case LIST_ACCOUNT_CHANNELS: {
			console.log(state, 'state from list account channels');
			return state;
		}
		case REMOVE_FACEBOOK_CHANNEL: {
			console.log(
				'payload from remove facebook channel accounts',
				payload
			);
			const connectedChannels = getConnectedChannels(payload);
			localStorage.setItem(
				'connectedChannels',
				JSON.stringify(connectedChannels)
			);
			const connectedChannelsToAnotherOrganization =
				getConnectedChannelsToAnotherOrganization(payload);
			localStorage.setItem(
				'connectedChannelsToAnotherOrganization',
				JSON.stringify(connectedChannelsToAnotherOrganization)
			);
			return payload;
		}
		default:
			return state;
	}
};
