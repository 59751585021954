const checkEmptyObj = (obj) => {
	return Object.keys(obj)?.length === 0 && obj?.constructor === Object;
};

const checkEmptyConversationsReplies = (conversationsReplies) => {
	return (
		checkEmptyObj(conversationsReplies) ||
		(conversationsReplies?.summary?.length === 0 &&
			conversationsReplies?.bot_replies === 0 &&
			conversationsReplies?.all_replies === 0 &&
			conversationsReplies?.team_member_replies === 0)
	);
};

const checkEmptyClientSources = (clientSources) => {
	return (
		checkEmptyObj(clientSources) ||
		(checkEmptyObj(clientSources.sources) &&
			clientSources?.people_count === 0) ||
		(clientSources?.sources?.facebook === 0 &&
			clientSources?.sources?.whatsapp === 0 &&
			clientSources?.sources?.self_imported === 0)
	);
};

const checkMostFrequentQuestions = (questions) => {
	return checkEmptyObj(questions);
};

const checkEmptyAssignedClients = (assignedClients) => {
	return (
		checkEmptyObj(assignedClients) ||
		(assignedClients?.assigned === 0 && assignedClients?.not_assigned === 0)
	);
};

const checkEmptyCrmStatus = (crmStatus) => {
	return (
		checkEmptyObj(crmStatus) ||
		(crmStatus?.number_of_people_have_not_status === 0 &&
			crmStatus?.number_of_people_have_status === 0 &&
			crmStatus?.summary?.length === 0)
	);
};

export {
	checkEmptyConversationsReplies,
	checkEmptyObj,
	checkEmptyClientSources,
	checkMostFrequentQuestions,
	checkEmptyAssignedClients,
	checkEmptyCrmStatus,
};
