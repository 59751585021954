import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	Typography,
	Box,
	Grid,
	Card,
	Popover,
	Paper,
	Divider,
} from '@material-ui/core';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';

const classNames = require('classnames');

const useStyles = makeStyles((theme) => ({
	totalBoxLg: {
		padding: '1.25rem',
		borderRadius: '0.625rem',
	},
	totalBoxSm: {
		padding: '0.625rem',
		borderRadius: '0.875rem',
	},
	totalBoxHeader: {
		font: 'normal normal medium 16px/26px Poppins',
		color: '#101010',
		textTransform: 'capitalize',
		marginBottom: '0.9375rem',
		marginLeft: '1.5rem',
	},
	totalBoxSubHeader: {
		width: '100%',
	},
	totalBoxNum: {
		display: 'inline',
		marginLeft: '1.5rem',
		fontSize: '1.2rem !important',
	},
	arowBG: {
		display: 'inline-block',
		backgroundColor: '#1AD49212',
		borderRadius: '0.625rem',
	},
	arrowImg: {
		display: 'inline-block',
		margin: '0px 0.625rem 0px 0.625rem',
		transform: 'translateY(15%)',
	},
	arrowNum: {
		display: 'inline-block',
		lineHeight: '2rem',
		fontSize: '1rem !important',
		fontWeight: 500,
		// color: ' #015F3E',
		paddingRight: '0.9375rem',
	},
	arrowRotate: {
		transform: 'rotate(110deg)',
	},
	totalBoxBorder: {
		border: '1px dashed #F2F2F2',
	},
}));
/**
 *
 * @param {*} param0 card header string
 * @param {*} param1 card img
 * @param {*} param2 card Num
 * @param {*} param3 arrow "up" or "down"
 * @param {*} param4 arrow num
 * @param {*} param5 color of arrow caption
 * @param {*} param6 card border style true or false
 * @param {*} param7 card size "lg" or "sm"
 *
 * @returns
 */
const TotalCard = ({
	cardHeader,
	totalElementsImg,
	totalNum,
	arrowImg,
	arrowNum,
	arrowColor,
	border = false,
	size = 'lg',
	todayNumber,
	yesterdayNumber,
}) => {
	const classes = useStyles();
	const borderStyle = classNames(' ', {
		[classes.totalBoxBorder]: border,
	});
	const sizeStyle = classNames('  ', {
		[classes.totalBoxSm]: size === 'sm',
		[classes.totalBoxLg]: size === 'lg',
	});

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handlePopoverOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handlePopoverClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<Card
			elevation={0}
			className={sizeStyle + borderStyle}
			style={{
				backgroundColor: '#FBF8FF',
			}}
		>
			<Grid
				container
				justify="space-between"
				alignItems="center"
				spacing={4}
			>
				<Grid item xs={2}>
					<img src={totalElementsImg} alt="" />
				</Grid>
				<Grid
					container
					justify="space-between"
					alignItems="flex-start"
					item
					xs={9}
					// md={10}
				>
					<Grid item xs={12}>
						<Typography className={classes.totalBoxHeader}>
							{cardHeader}
						</Typography>
					</Grid>
					<Grid item xs={2}>
						<Typography className={classes.totalBoxNum}>
							{totalNum}
						</Typography>
					</Grid>
					<Grid
						className={classes.arowBG}
						item
						style={{
							backgroundColor:
								arrowNum <= 0 ? '#F4151512' : '#1AD49212',
							cursor: 'pointer',
						}}
					>
						<Box
							color={arrowColor}
							aria-owns={open ? 'mouse-over-popover' : undefined}
							aria-haspopup="true"
							onClick={handlePopoverOpen}
						>
							<Typography className={classes.arrowImg}>
								{arrowImg === 'down' && (
									<TrendingDownIcon
										className={classes.arrowRotate}
									/>
								)}
								{arrowImg === 'up' && <TrendingUpIcon />}
							</Typography>
							<Typography
								align="center"
								className={classes.arrowNum}
							>
								{arrowNum} %
							</Typography>
						</Box>
						<Popover
							id="mouse-over-popover"
							sx={{
								pointerEvents: 'none',
							}}
							open={open}
							anchorEl={anchorEl}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right',
							}}
							onClose={handlePopoverClose}
							disableRestoreFocus
						>
							<Paper
								style={{
									width: '10rem',
									height: '3.5rem',
								}}
							>
								<Grid container>
									<Grid
										item
										xs={9}
										style={{
											paddingLeft: '0.5rem',
										}}
									>
										<Typography>Today</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography
											style={{
												color: '#726AFF',
											}}
										>
											{todayNumber}
										</Typography>
									</Grid>
								</Grid>
								<Divider />
								<Grid container>
									<Grid
										item
										xs={9}
										style={{
											paddingLeft: '0.5rem',
										}}
									>
										<Typography>Yesterday</Typography>
									</Grid>
									<Grid item xs={3}>
										<Typography
											style={{
												color: '#726AFF',
											}}
										>
											{yesterdayNumber}
										</Typography>
									</Grid>
								</Grid>
							</Paper>
						</Popover>
					</Grid>
				</Grid>
			</Grid>
		</Card>
	);
};

export default TotalCard;
