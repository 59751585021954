import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import reduxThunk from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import handleRequestMiddleware from './middleWare/handleRequestMiddleware';

const reduxMiddlewares = [reduxThunk];

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['activeTalkybot', 'talkybots', 'auth'],
};

if (process.env.DEBUG === '*' || process.env.NODE_ENV !== 'production') {
	const reduxLogger = createLogger({
		duration: true,
		timestamp: true,
		level: 'log',
		logErrors: true,
	});
	reduxMiddlewares.push(reduxLogger);
	// reduxMiddlewares.push(handleRequestMiddleware);
}

const composeEnhancers =
	(typeof window !== 'undefined' &&
		window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
	compose;

const enhancers = composeEnhancers(applyMiddleware(...reduxMiddlewares));

const pReducer = persistReducer(persistConfig, reducers);

export default () => {
	const store = createStore(pReducer, enhancers);
	const persistor = persistStore(store);
	return { store, persistor };
};
